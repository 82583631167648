import * as types from "../constants/action-types";

import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

const initialState = {
  activeMenuBar: "",
};

const stylesReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.MENU_ACTIVE:
      return { ...state, activeMenuBar: action.payload };
    default:
      return state;
  }
};
const persistConfig = {
  keyPrefix: "molla-",
  key: "styles",
  storage,
};

export default persistReducer(persistConfig, stylesReducer);
