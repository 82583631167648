import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { products, flexMatrix, solutions, sales, contact, about } from "./navData";
import { sliceIntoChunks } from "../../../reusable-functions/array";
import { connect } from "react-redux";
import { getProductsMenu } from "../../../actions/products";
import { setActiveMenu } from "../../../actions/styles";
import "./styles.scss";

function MainMenu(props) {
  const [path, setPath] = useState("");

  // const PUBLIC_URL = "/react/molla";

  const { active, setActiveMenu } = props;

  useEffect(() => {
    setPath(props.location.pathname);
  }, [props.location.pathname]);

  useEffect(() => {
    props.productsMenu.length === 0 && props.getProductsMenu();
  }, []);

  // function showAllDemos(e) {
  //   let demoItems = document.querySelectorAll(".demo-item.hidden");

  //   for (let i = 0; i < demoItems.length; i++) {
  //     demoItems[i].classList.toggle("show");
  //   }

  //   document.querySelector(".view-all-demos").classList.toggle("disabled-hidden");
  //   e.preventDefault();
  // }

  const activeMenu = (arr) => {
    let found = false;
    arr.forEach((element) => {
      if (element.path === path) {
        found = true;
      }
      element.subcategory &&
        element.subcategory.forEach((ele) => {
          if (ele.path === path) {
            found = true;
          }
        });
    });

    return found;
  };

  const renderProducts = () => {
    return sliceIntoChunks(props.productsMenu, 3).map((subArray, index) => (
      <div key={index} className="col-md-4">
        {subArray.map((category, index2) => (
          <div className="menu-margin-bottom" key={index2}>
            <Link to={category.path}>
              <div className="menu-title">{category.category}</div>
            </Link>
            <ul>
              {category.subcategory.map((subCategory, index3) => (
                <li key={index3}>
                  <Link to={`${process.env.PUBLIC_URL}${subCategory.path}`}>{subCategory.name}</Link>
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    ));
  };

  const renderFlexMatrix = () => {
    return flexMatrix[0].subcategory.map((item) => (
      <li className="menu-secondary" key={item.name}>
        <Link to={`${process.env.PUBLIC_URL}${item.path}`}>{item.name}</Link>
      </li>
    ));
  };

  const renderSolutions = () => {
    return solutions.map((item) => (
      <li className="menu-secondary" key={item.name}>
        <Link to={`${process.env.PUBLIC_URL}${item.path}`}>{item.name}</Link>
      </li>
    ));
  };

  const renderSales = () => {
    return sales.map((item) => (
      <li className="menu-secondary" key={item.name}>
        <Link to={`${process.env.PUBLIC_URL}${item.path}`}>{item.name}</Link>
      </li>
    ));
  };

  const renderContact = () => {
    return contact.map((item) => (
      <li className="menu-secondary" key={item.name}>
        <Link to={`${process.env.PUBLIC_URL}${item.path}`}>{item.name}</Link>
      </li>
    ));
  };

  const renderAbout = () => {
    return about.map((item) => (
      <li className="menu-secondary" key={item.name}>
        <Link to={`${process.env.PUBLIC_URL}${item.path}`}>{item.name}</Link>
      </li>
    ));
  };

  return (
    <nav className="main-nav">
      <ul className="menu sf-arrows">
        <li className={activeMenu(flexMatrix) ? "active" : ""} id="menu-home">
          <Link to={`${process.env.PUBLIC_URL}/flexmatrix`} className="sf-with-ul">
            Flex Matrix
          </Link>

          <ul>{renderFlexMatrix()}</ul>
        </li>

        <li className={activeMenu(props.productsMenu) ? "active" : ""} id="menu-home">
          <Link to={`${process.env.PUBLIC_URL}/cables`} className="sf-with-ul">
            Products
          </Link>
          <div className="megamenu megamenu-md">
            <div className="row no-gutters">
              <div className="col-md-12">
                <div className="menu-col">
                  <div className="row">{renderProducts()}</div>
                </div>
              </div>
            </div>
          </div>
        </li>

        <li className={activeMenu(solutions) ? "active" : ""}>
          <Link to={`${process.env.PUBLIC_URL}/applications/classroom`} className="sf-with-ul">
            Solutions
          </Link>

          <ul>{renderSolutions()}</ul>
        </li>

        <li className={activeMenu(sales) ? "active" : ""}>
          <Link to={`${process.env.PUBLIC_URL}/sales`} className="sf-with-ul">
            Sales
          </Link>

          <ul>{renderSales()}</ul>
        </li>

        <li className={activeMenu(contact) ? "active" : ""}>
          <Link to={`${process.env.PUBLIC_URL}/contact`} className="sf-with-ul">
            Contact
          </Link>

          <ul>{renderContact()}</ul>
        </li>

        <li className={activeMenu(about) ? "active" : ""}>
          <Link to={`${process.env.PUBLIC_URL}/company`} className="sf-with-ul">
            About
          </Link>

          <ul>{renderAbout()}</ul>
        </li>
        {/* 
        <li>
          <Link to={`${process.env.PUBLIC_URL}/blog`} className="">
            Blog
          </Link>
        </li> */}
      </ul>
    </nav>
  );
}

const mapStateToProps = (state) => {
  return {
    productsMenu: state.data.productsMenu ? state.data.productsMenu : [],
    loading: state.data.loading,
    active: state.styles.activeMenuBar,
  };
};

export default connect(mapStateToProps, { getProductsMenu, setActiveMenu })(MainMenu);
