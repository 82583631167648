import React from "react";

const FormTextarea = ({
  title,
  placeholder,
  value,
  name,
  onChange,
  onBlur,
  required,
  error,
  rows = 5, // Default number of rows
}) => {
  return (
    <div className="flex-column d-flex justify-content-center relative">
      <div className="flex">
        <p
          className="text-lg flex relative font-bold"
          style={{ textTransform: "uppercase" }}
        >
          {title}
          {required && (
            <p className="text-red-600 text-4xl absolute -right-5">*</p>
          )}
        </p>
      </div>
      <textarea
        className={`p-3 bg-gray-50 border-2 rounded-md resize-none ${error ? "border-red-500" : "border-gray-300"
          } focus:outline-none focus:ring-2 focus:ring-blue-500`}
        placeholder={placeholder}
        required={required}
        value={value}
        name={name}
        onChange={onChange}
        onBlur={onBlur}
        rows={rows}
      ></textarea>
      {error && <p className="invalid-data mt-1 text-red-500">{error}</p>}
    </div>
  );
};

export default FormTextarea;
