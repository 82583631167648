import React from "react";
import { Helmet } from "react-helmet";
import CaProp from "./CaProp";
import SidebarSecondary from "../common/sidebar/SidebarSecondary";
import "./CaProp.scss";
import { resource, sales } from "../common/partials/navData";
import banner from "../resources/images/banner.jpg";

export default function CAprop({ location }) {
  return (
    <>
      <Helmet>
        <title>CA Proposition 65</title>
        <meta name="description" content="Professional Audio Video components"></meta>
        <meta name="keywords" content="kanexpro"></meta>
      </Helmet>
      <div className="d-flex">
        <div className="d-none d-lg-block d-xl-block">
          <SidebarSecondary data={resource} title="Resources" path={location.pathname} />
        </div>

        <div className="caprop-container">
          {/* <div className="banner">
            <img src={banner} alt="banner" />
            <div className="bg-dark" />
            <h1 className="display-4">California Propostion 65</h1>
          </div> */}

          <div className="caprop">
            <h1 className="display-4">California Propostion 65</h1>
            <CaProp />
          </div>
        </div>
      </div>
    </>
  );
}
