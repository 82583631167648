import React from "react";

const ButtonQuantity = ({ onDecrease, onIncrease, onChange, onBlur, qty, customClass, customStyle }) => {
  return (
    <div className={`btn-quantity d-flex ${customClass}`} style={customStyle}>
      <div className="btn-small" onClick={onDecrease}>
        -
      </div>
      <input type="text" className="input-quantity" value={qty} onChange={onChange} onBlur={onBlur} />
      <div className="btn-small" onClick={onIncrease}>
        +
      </div>
    </div>
  );
};

export default ButtonQuantity;
