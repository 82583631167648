import React from "react";

const Panel = ({ data }) => {
  const pdfUrl = data[0].filelink.includes(".pdf") ? true : false;
  return !pdfUrl ? (
    <div className="container fade-in">
      {data.map((panel, index) => (
        <img
          key={index}
          className="list-primary"
          src={"https://www.kanexpro.com/uploads/" + panel.filelink}
          alt={panel.filetype}
          crossOrigin="anonymous"
        />
      ))}
    </div>
  ) : (
    <div className="container fade-in">
      <iframe
        title="pdf"
        className="list-primary iframe"
        src={"https://www.kanexpro.com/uploads/" + data[0].filelink}
        type="application/pdf"
      />
    </div>
  );
};

export default Panel;
