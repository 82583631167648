import React from "react";
import { Helmet } from "react-helmet";
import CardTertiary from "../../../ui/card-tertiary/CardTertiary";
import { resource, sales } from "../../common/partials/navData";
import SidebarSecondary from "../../common/sidebar/SidebarSecondary";
import "./case-studies.scss";
import ButtonPrimary from "../../../ui/button/ButtonPrimary";
import cs1 from "../images/cs1.png";
import cs2 from "../images/cs2.png";
import cs3 from "../images/cs3.png";
import cs4 from "../images/cs4.png";
import cs5 from "../images/cs5.png";
import banner from "../images/banner.jpg";

const CaseStudies = ({ location }) => {
  return (
    <>
      <Helmet>
        <title>Case Studies</title>
        <meta name="description" content="Professional Audio Video components"></meta>
        <meta name="keywords" content="kanexpro"></meta>
      </Helmet>
      <div className="d-flex">
        <div className="d-none d-lg-block d-xl-block">
          <SidebarSecondary path={location.pathname} data={resource} title="Resource" />
        </div>
        <div className="case-studies-container">
          {/* <div className="img-container">
            <img src={banner} alt="banner" />
            <div className="bg-dark" />
            <h1 className="display-4 mb-3">Case Studies</h1>
          </div> */}
          <div className="case-studies">
            <h1 className="display-4 mb-3">Case Studies</h1>
            <div className="text-center">
              <p className="display-center-5">Digital Signage</p>
              <div className="row mb-4">
                <div className="col-md-4 col-lg-4 col-sm-6 col-xs-12 d-flex justify-content-center justify-content-lg-center">
                  <CardTertiary
                    src={cs1}
                    text="BBVA selects KanexPro for digital signage in banks"
                    onClick={() => window.open("https://www.kanexpro.com/casestudy/BBVA.pdf", "_blank")}
                  />
                </div>
                <div className="col-md-4 col-lg-4 col-sm-6 col-xs-12 d-flex justify-content-center justify-content-lg-center">
                  <CardTertiary
                    src={cs2}
                    text="Largest Coffee Chain Drive-Thru Case Study"
                    onClick={() => window.open("https://www.kanexpro.com/casestudy/Starbuck_cs.pdf", "_blank")}
                  />
                </div>
                <div className="col-md-4 col-lg-4 col-sm-6 col-xs-12 d-flex justify-content-center justify-content-lg-center">
                  <CardTertiary
                    src={cs3}
                    text="Tempest Technologies Revolutionizes Medical Interaction with KanexPro"
                    onClick={() => window.open("https://www.kanexpro.com/casestudy/TEMPEST.pdf", "_blank")}
                  />
                </div>
              </div>

              <p className="display-center-5">Collaboration & Training</p>
              <div className="row mb-4">
                <div className="col-md-4 col-lg-4 col-sm-6 col-xs-12 d-flex justify-content-center justify-content-lg-center">
                  <CardTertiary
                    src={cs4}
                    text="Fort Davis National Park Case Study"
                    onClick={() => window.open("https://www.kanexpro.com/casestudy/NPS_2.pdf", "_blank")}
                  />
                </div>
              </div>

              <p className="display-center-5">Education</p>
              <div className="row">
                <div className="col-md-4 col-lg-4 col-sm-6 col-xs-12 d-flex justify-content-center justify-content-lg-center">
                  <CardTertiary
                    src={cs5}
                    text="DigiTech-Integration Case Study"
                    onClick={() =>
                      window.open("https://www.kanexpro.com/casestudy/DigiTech-Integration_B.pdf", "_blank")
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CaseStudies;
