import React from "react";
import { connect } from "react-redux";
import ButtonPrimary from "../../ui/button/ButtonPrimary";
import "./imp-user.scss";
import { userImpLogin } from "../../actions/auth";
import ListFreePrimary from "../../ui/list/ListFreePrimary";

const ImpersonateUser = ({ history, match, userImpLogin }) => {
  const { userName } = match.params;

  function onClickLogin() {
    userImpLogin(userName, history);
  }

  return (
    <div className="imp-user">
      <ListFreePrimary>
        <h3>Hi {userName}.</h3>
        <p>You're logging in as an impersonate user</p>
        <ButtonPrimary text="Log In" onClick={onClickLogin} />
      </ListFreePrimary>
    </div>
  );
};

export default connect(null, { userImpLogin })(ImpersonateUser);
