import {
  RECEIVE_PRODUCTS,
  SHOW_QUICKVIEW,
  CLOSE_QUICKVIEW,
  GET_BANNER_PRODUCTS,
  GET_FEATURED_PRODUCTS,
  GET_SUB_CATEGORIES,
  GET_PRODUCTS,
  PRODUCT_DETAILS,
  PRODUCTS_MENU,
  RESET_LOADING,
  PRODUCT_ERROR,
  SOLUTIONS,
  NEW_PRODUCTS,
  SEARCH_PRODUCTS,
  GET_PARTNER,
  PARTNER_ERROR,
  GET_NEWS,  
  NEWS_ERROR,
  NEWS_DETAILS,
  NEWS_LOADING_TRUE,
  GET_BLOGS,
  BLOGS_ERROR,
  BLOGS_DETAILS,
  BLOGS_LOADING_TRUE,
  FORM_STATUS,
} from "../constants/action-types";
import { findIndex } from "../utils";

const initialState = {
  products: [],
  productDetail: [],
  quickView: false,
  bannerProducts: [],
  featuredProducts: [],
  subcategories: [],
  subCategoryProducts: [],
  productDetails: {},
  productsMenu: [],
  loading: true,
  error: null,
  solutions: {},
  newProducts: [],
  searchProducts: {},
  partners: {},
  news: {},
  newsdetails: {},
  newsloading: true,
  blogs: {},
  blogdetails: {},
  blogloading: true,


  formStatus: null,
};

const productReducer = (state = initialState, action) => {
  switch (action.type) {
    case RECEIVE_PRODUCTS:
      return {
        ...state,
        products: action.products,
      };

    case SHOW_QUICKVIEW:
      let index = findIndex(state.products, (product) => product.id === action.productId);
      if (-1 !== index) {
        const item = state.products[index];
        return {
          ...state,
          quickView: true,
          productDetail: item,
        };
      }
      break;

    case CLOSE_QUICKVIEW:
      return { ...state, quickView: false };

    case GET_BANNER_PRODUCTS:
      return { ...state, bannerProducts: action.payload };
    case GET_FEATURED_PRODUCTS:
      return { ...state, featuredProducts: action.payload };
    case GET_SUB_CATEGORIES:
      return { ...state, subcategories: action.payload, loading: false };
    case GET_PRODUCTS:
      return { ...state, subCategoryProducts: action.payload, loading: false };
    case PRODUCT_DETAILS:
      return { ...state, productDetails: action.payload, loading: false };
    case PRODUCTS_MENU:
      return { ...state, productsMenu: action.payload, loading: false };

    case RESET_LOADING:
      return { ...state, loading: true };
    case PRODUCT_ERROR:
      return { ...state, error: action.payload };
    case SOLUTIONS:
      return { ...state, solutions: action.payload, loading: false };
    case NEW_PRODUCTS:
      return { ...state, newProducts: action.payload, loading: false };
    case SEARCH_PRODUCTS:
      return { ...state, searchProducts: action.payload, loading: false };
    case GET_PARTNER:
      return { ...state, partners: action.payload };
    case PARTNER_ERROR:
      return { ...state, error: action.payload };
    case GET_NEWS:
      return { ...state, news: action.payload };
    case NEWS_ERROR:
      return { ...state, error: action.payload };
    case NEWS_DETAILS:
      return { ...state, newsdetails: action.payload, newsloading: false };
    case NEWS_LOADING_TRUE:
      return { ...state, newsloading: true };

      case GET_BLOGS:
      return { ...state, blogs: action.payload };
    case BLOGS_ERROR:
      return { ...state, error: action.payload };
    case BLOGS_DETAILS:
      return { ...state, blogdetails: action.payload, blogloading: false };
    case BLOGS_LOADING_TRUE:
      return { ...state, blogloading: true };

    case FORM_STATUS:
      return { ...state, formStatus: action.payload };

    default:
      return state;
  }
};

export default productReducer;
