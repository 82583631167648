import React from 'react'
import Work from './Work';


export default function WorkFromHome() {
    return (
        <div>
            <Work/>
        </div>
    )
}
