import React from "react";
import { Helmet } from "react-helmet";
import SidebarSecondary from "../common/sidebar/SidebarSecondary";
import Terms from "./Terms";
import "./Terms.scss";
import { about } from "../common/partials/navData";
import banner from "./images/banner.svg";

export default function Warranty({ location }) {
  return (
    <>
      <Helmet>
        <title>Terms</title>
        <meta name="description" content="Professional Audio Video components"></meta>
        <meta name="keywords" content="kanexpro"></meta>
      </Helmet>
      <div className="d-flex">
        <div className="d-none d-lg-block d-xl-block">
          <SidebarSecondary path={location.pathname} data={about} title="About" />
        </div>
        <div>
          {/* <img className="terms-banner" src={banner} alt="banner" /> */}
          <div className="terms">
            <h1 className="display-4">Terms</h1>
            <Terms />
          </div>
        </div>
      </div>
    </>
  );
}
