import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import classroomImg from "./images/Classrooms_715px.jpg";
import "./solutions.scss";
import ButtonPrimary from "../../ui/button/ButtonPrimary";
import ButtonSecondary from "../../ui/button/ButtonSecondary";
import CardSecondary from "../../ui/card-secondary";
import CardPrimary from "../../ui/CardPrimary";
import { solutions } from "../common/partials/navData";
import SidebarSecondary from "../common/sidebar/SidebarSecondary";
import { solutionsId } from "./solutionsData";
import { connect } from "react-redux";
import { getSolutions, setLoadingTrue } from "../../actions/products";
import SuggestedProducts from "./suggested-products";
import LoaderPrimary from "../../ui/loader/LoaderPrimary";

const Solutions = (props) => {
  const { location, match } = props;
  const { solutionsData, getSolutions, loading, setLoadingTrue, converters, extenders, splitters, switchers } = props;

  useEffect(() => {
    const traceId = solutionsId[match.params.solutions];
    getSolutions(traceId);

    return () => {
      setLoadingTrue();
    };
  }, [location.pathname]);

  if (solutionsData.length === 0 || loading) {
    return <LoaderPrimary />;
  }
  return (
    <>
      <Helmet>
        <title>{solutionsData[0].apptext}</title>
        <meta name="description" content="Professional Audio Video components"></meta>
        <meta name="keywords" content="kanexpro"></meta>
      </Helmet>
      <div className="applications">
        <div className="d-flex">
          <div className="d-none d-lg-block d-xl-block">
            <SidebarSecondary path={location.pathname} data={solutions} title="Solutions" />
          </div>
          <div className="applications-content">
            <h1 className="display-4">{solutionsData[0].appname}</h1>
            <div className="row mb-3">
              <div className="col-lg-6 d-flex flex-column">
                <img src={solutionsData[0].banner} alt="classroom-banner" />
              </div>
              <div className="col-lg-6">
                <p className="lead">{solutionsData[0].apptext}</p>

                <div className="d-flex justify-content-center">
                  <ButtonPrimary link={`/item/${solutionsData[0].sku}`} text="Explore Now" customClass="mr-3" />
                  <ButtonSecondary link="/contact" text="Register as a Dealer" customClass="ml-3" />
                </div>
              </div>
            </div>
            {/* <div className="row mb-6">
            <CardSecondary icon="fas fa-tachometer-alt" text="High performance." customClass="col-md-4" />

            <CardSecondary icon="fas fa-truck" text="Fast pace delivery." customClass="col-md-4" />

            <CardSecondary icon="fas fa-user-clock" text="Reliable customer support." customClass="col-md-4" />
          </div> */}
            <h1 className="display-5 mb-3">Featured product</h1>
            <CardPrimary
              image={solutionsData[0].thumbnail}
              title={solutionsData[0].title}
              subtitle={solutionsData[0].subtitle}
              mpn={solutionsData[0].sku}
              link={`/item/${solutionsData[0].sku}`}
            />
            <SuggestedProducts
              converters={converters}
              extenders={extenders}
              splitters={splitters}
              switchers={switchers}
            />
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    solutionsData: state.data.solutions.refAppsInfo ? state.data.solutions.refAppsInfo : [],
    converters: state.data.solutions.Converters ? state.data.solutions.Converters : [],
    extenders: state.data.solutions.Extenders ? state.data.solutions.Extenders : [],
    splitters: state.data.solutions.Splitters ? state.data.solutions.Splitters : [],
    switchers: state.data.solutions.Switchers ? state.data.solutions.Switchers : [],
    loading: state.data.loading,
  };
};

export default connect(mapStateToProps, { getSolutions, setLoadingTrue })(Solutions);
