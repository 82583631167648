import React, { useEffect, useLayoutEffect } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';


function ComingSoon() {
    useLayoutEffect( () => {
        document.querySelector( "body" ).classList.remove( "loaded" );
    }, [] )

    useEffect( () => {
        setTimeout( () => {
            document.querySelector( "body" ).classList.add( "loaded" );
        }, 200 );
    }, [] )


    return (
        <div className="soon">
            <Helmet>
                <title>Coming Soon Page</title>
            </Helmet>

            <h1 className="d-none">Coming Soon Page</h1>

            <div className="container">
                <div className="row">
                    <div className="col-md-9 col-lg-8">
                        <div className="soon-content text-center">
                            <div className="soon-content-wrapper">
                              
                                <h1 className="soon-title">Coming Soon</h1>

                         

                                <div className="social-icons justify-content-center mb-0">
                                    <Link to="#" className="social-icon" target="_blank" title="Facebook"><i className="icon-facebook-f"></i></Link>
                                    <Link to="#" className="social-icon" target="_blank" title="Twitter"><i className="icon-twitter"></i></Link>
                                    <Link to="#" className="social-icon" target="_blank" title="Instagram"><i className="icon-instagram"></i></Link>
                                    <Link to="#" className="social-icon" target="_blank" title="Youtube"><i className="icon-youtube"></i></Link>
                                    <Link to="#" className="social-icon" target="_blank" title="Pinterest"><i className="icon-pinterest"></i></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="soon-bg bg-image" style={ { backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/backgrounds/soon-bg.jpg)` } }></div>
        </div>
    )
}

export default React.memo( ComingSoon );