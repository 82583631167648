// Get Products
export const RECEIVE_PRODUCTS = "RECEIVE_PRODUCTS";

// Refresh Store
export const REFRESH_STORE = "REFRESH_STORE";

//Quick
export const SHOW_QUICKVIEW = "SHOW_QUICKVIEW";
export const CLOSE_QUICKVIEW = "CLOSE_QUICKVIEW";

// Cart
export const ADD_TO_CART = "ADD_TO_CART";
export const REMOVE_FROM_CART = "REMOVE_FROM_CART";
export const EMPTY_CART = "EMPTY_CART";
export const CHANGE_QTY = "CHANGE_QTY";
export const CHANGE_SHIPPING = "CHANGE_SHIPPING";

// WishList
export const TOGGLE_WISHLIST = "TOGGLE_WISHLIST";
export const REMOVE_FROM_WISHLIST = "REMOVE_FROM_WISHLIST";

// Compare
export const ADD_TO_COMPARE = "ADD_TO_COMPARE";
export const REMOVE_FROM_COMPARE = "REMOVE_FROM_COMPARE";
export const RESET_COMPARE = "RESET_COMPARE";

//Product Filters
export const SORT_BY = "SORT_BY";
export const CATEGORY_FILTER = "CATEGORY_FILTER";
export const RESET_FILTER = "RESET_FILTER";
export const SIZE_FILTER = "SIZE_FILTER";
export const COLOR_FILTER = "COLOR_FILTER";
export const BRAND_FILTER = "BRAND_FILTER";
export const PRICE_FILTER = "PRICE_FILTER";
export const RATING_FILTER = "RATING_FILTER";

//Newsletter
export const HIDE_NEWSLETTER_MODAL = "HIDE_NEWSLETTER_MODAL";

// Modal
export const SHOW_MODAL = "SHOW_MODAL";
export const CLOSE_MODAL = "CLOSE_MODAL";
export const REMOVE_NEWSLETTER = "REMOVE_NESLETTER";

// Products
export const GET_BANNER_PRODUCTS = "GET_BANNER_PRODUCTS";
export const GET_FEATURED_PRODUCTS = "GET_FEATURED_PRODUCTS";
export const GET_SUB_CATEGORIES = "GET_SUB_CATEGORIES";
export const GET_PRODUCTS = "GET_PRODUCTS";
export const PRODUCT_DETAILS = "PRODUCT_DETAILS";
export const RESET_LOADING = "RESET_LOADING";
export const PRODUCT_ERROR = "PRODUCT_ERROR";
export const PRODUCTS_MENU = "PRODUCTS_MENU";
export const ADD_CART = "ADD_CART";
export const REMOVE_CART = "REMOVE_CART";
export const CHANGE_PRODUCT_QTY = "CHANGE_PRODUCT_QTY";
export const NEW_PRODUCTS = "NEW_PRODUCTS";
export const SEARCH_PRODUCTS = "SEARCH_PRODUCTS";

// Auth
export const LOGIN = "LOGIN";
export const USER_ERROR = "USER_ERROR";
export const USER_LOADED = "USER_LOADED";
export const USER_INFO = "USER_INFO";
export const CLEAR_ERROR = "CLEAR_ERROR";
export const LOGOUT = "LOGOUT";

// Styles

export const MENU_ACTIVE = "MENU_ACTIVE";

// Solutions
export const SOLUTIONS = "SOLUTIONS";

//  PARTNER

export const GET_PARTNER = "GET_PARTNER";
export const PARTNER_ERROR = "PARTNER_ERROR";

// NEWS

export const GET_NEWS = "GET_NEWS";
export const NEWS_ERROR = "NEWS_ERROR";
export const NEWS_DETAILS = "NEWS_DETAILS";
export const NEWS_LOADING_TRUE = "NEWS_LOADING_TRUE";

// Blogs

export const GET_BLOGS = "GET_BLOGS";
export const BLOGS_ERROR = "BLOGS_ERROR";
export const BLOGS_DETAILS = "BLOGS_DETAILS";
export const BLOGS_LOADING_TRUE = "BLOGS_LOADING_TRUE";

// Contact
export const FORM_STATUS = "FORM_STATUS";

// Account
export const PRODUCT_LIST = "PRODUCT_LIST";
export const SKUHISTORY_LIST = "SKUHISTORY_LIST";
export const ORDER_HISTORY_OPEN = "ORDER_HISTORY_OPEN";
export const ORDER_HISTORY_CLOSE = "ORDER_HISTORY_CLOSE";
export const SHIPMENTS_LIST = "SHIPMENTS_LIST";
export const SHIPMENT_DETAILS = "SHIPMENT_DETAILS";
export const ACCOUNT_TRUE = "ACCOUNT_TRUE";
export const OPEN_ITEMS = "OPEN_ITEMS";
export const OPEN_ITEMS_COMMENT = "OPEN_ITEMS_COMMENT";
