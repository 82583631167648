import React from "react";

const Diagram = ({ data }) => {
  return (
    <div className="container fade-in">
      {data.map((diagram, index) => (
        <img
          key={index}
          className="list-primary"
          src={"https://www.kanexpro.com/uploads/" + diagram.filelink}
          alt={diagram.filetype}
        />
      ))}
    </div>
  );
};

export default Diagram;
