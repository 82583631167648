import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { products } from "../common/partials/navData";
import "./products.scss";
import Sidebar from "./Sidebar";
import BreadCrumbPrimary from "../../ui/breadcrumbs/BreadCrumbPrimary";
import CardPrimary from "../../ui/CardPrimary";
import { connect } from "react-redux";
import { getProductsMenu, getSubCategories, setLoadingTrue } from "../../actions/products";
import { productsData } from "./productsData";
import PaginationSecondary from "../../ui/pagination/PaginationSecondary";
import LoaderPrimary from "../../ui/loader/LoaderPrimary";

const Category = (props) => {
  const { path } = props.match; // value -> /category

  const menuFor = path.substring(1); // value -> category
  const menu = menuFor.charAt(0).toUpperCase() + menuFor.slice(1); // value -> Category

  const { getProductsMenu, productsMenu, subCategories, getSubCategories, loading, setLoadingTrue } = props;

  // Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [productsPerPage, setProductsPerPage] = useState(10);

  const indexOfLastProduct = currentPage * productsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
  const currentProducts = subCategories.slice(indexOfFirstProduct, indexOfLastProduct);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  useEffect(() => {
    productsMenu.length === 0 && getProductsMenu();
    getSubCategories(menuFor);

    return () => {
      setLoadingTrue();
    };
  }, []);

  if (loading) {
    return <LoaderPrimary />;
  }

  return (
    <>
      <Helmet>
        <title>{productsData[menuFor].title}</title>
        <meta name="description" content={productsData[menuFor].description}></meta>
        <meta name="keywords" content={productsData[menuFor].keywords}></meta>
      </Helmet>
      <div>
        <div className="category">
          <div className="d-none d-lg-block d-xl-block">
            <Sidebar path={path} data={products} />
          </div>
          <div className="products-container">
            <BreadCrumbPrimary home="Home" linkHome="/" category={menuFor} />
            <h1 className="display-4">{menu}</h1>

            <div className="row">
              {currentProducts.map((category) => (
                <CardPrimary
                  key={category.pkgroupid}
                  image={category.kproimg}
                  title={category.kprochild}
                  subtitle={category.kprodesc}
                  link={`/${menuFor}/${category.kprochild2}`}
                />
              ))}
            </div>
            <PaginationSecondary
              productsPerPage={productsPerPage}
              totalProducts={subCategories.length}
              paginate={paginate}
              currentPage={currentPage}
            />
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    subCategories: state.data.subcategories ? state.data.subcategories : [],
    productsMenu: state.data.productsMenu ? state.data.productsMenu : [],
    loading: state.data.loading,
  };
};

export default connect(mapStateToProps, { getProductsMenu, getSubCategories, setLoadingTrue })(Category);
