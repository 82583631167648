import React from "react";
import FaqForm from "./FaqForm";

const Faq = ({ data }) => {
  return (
    <div className="container fade-in">


      <FaqForm data={data} />

    </div>
  );
};

export default Faq;
