import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { setLoadingTrue } from "../../../actions/products";
import {
  emailValidation,
  noSpaceOnChange,
  notEmptyValidation,
  phoneOnChange,
  phoneValidation,
} from "../../../validation/formValidation";
import {
  formStatusCustom,
  formStatusNull,
  formStatusPending,
  postInquiry,
} from "../../../actions/forms";
import axios from "../../../api";
import FormInput from "../FormInput";
import FormTextarea from "../FormTextarea";

const InquireProduct = (props) => {
  const {
    productDetails,
    formStatusCustom,
    formStatusNull,
    formStatusPending,
    postInquiry,
  } = props;

  const [inquiryData, setInquiryData] = useState({
    firstName: "",
    lastName: "",
    company: "",
    phone: "",
    email: "",
    question: "",
    isNewsletter: false,
  });

  const [inquiryError, setInquiryError] = useState({
    firstNameError: "",
    lastNameError: "",
    companyError: "",
    phoneError: "",
    emailError: "",
    questionError: "",
  });

  const onChangeData = (e) => {
    const { name, value } = e.target;
    if (name === "phone") {
      phoneOnChange(value) &&
        setInquiryData({ ...inquiryData, [name]: value });
    } else if (name === "email") {
      noSpaceOnChange(value) &&
        setInquiryData({ ...inquiryData, [name]: value });
    } else {
      setInquiryData({ ...inquiryData, [name]: value });
    }
  };

  const onBlurData = (e) => {
    const { name } = e.target;

    switch (name) {
      case "phone":
        if (!phoneValidation(inquiryData.phone)) {
          setInquiryError({ ...inquiryError, phoneError: "Number must have 10 digits" });
        } else {
          setInquiryError({ ...inquiryError, phoneError: "" });
        }
        break;

      case "email":
        if (!emailValidation(inquiryData.email)) {
          setInquiryError({ ...inquiryError, emailError: "Please enter a valid email" });
        } else {
          setInquiryError({ ...inquiryError, emailError: "" });
        }
        break;

      case "question":
        if (!notEmptyValidation(inquiryData.question)) {
          setInquiryError({ ...inquiryError, questionError: "Please enter your question" });
        } else {
          setInquiryError({ ...inquiryError, questionError: "" });
        }
        break;

      case "firstName":
        if (!notEmptyValidation(inquiryData.firstName)) {
          setInquiryError({ ...inquiryError, firstNameError: "First name is required" });
        } else {
          setInquiryError({ ...inquiryError, firstNameError: "" });
        }
        break;

      case "lastName":
        if (!notEmptyValidation(inquiryData.lastName)) {
          setInquiryError({ ...inquiryError, lastNameError: "Last name is required" });
        } else {
          setInquiryError({ ...inquiryError, lastNameError: "" });
        }
        break;

      case "company":
        if (!notEmptyValidation(inquiryData.company)) {
          setInquiryError({ ...inquiryError, companyError: "Company is required" });
        } else {
          setInquiryError({ ...inquiryError, companyError: "" });
        }
        break;

      default:
        break;
    }
  };


  const onSubmitValidation = () => {
    return (
      notEmptyValidation(inquiryData.firstName) &&
      notEmptyValidation(inquiryData.lastName) &&
      notEmptyValidation(inquiryData.company) &&
      notEmptyValidation(inquiryData.question) &&
      emailValidation(inquiryData.email) &&
      phoneValidation(inquiryData.phone)
    );
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    if (!onSubmitValidation()) return;

    formStatusPending();
    const body = {
      sku: productDetails.mpn,
      name: productDetails.name,
      company: inquiryData.company,
      phone: inquiryData.phone,
      reftype: null,
      email: inquiryData.email,
      inquiry: inquiryData.question,
    };

    try {
      window.grecaptcha.ready(async () => {
        const token = await window.grecaptcha.execute(process.env.REACT_APP_SITE_KEY, {
          action: "submit",
        });

        const res = await axios.get("/api/Home/ValidateCaptcha", {
          params: { captcha: token },
        });

        if (res.data) {
          await postInquiry(body);
          setTimeout(() => formStatusNull(), 3000);
        } else {
          formStatusCustom(404);
          setTimeout(() => formStatusNull(), 3000);
        }
      });
    } catch (error) {
      formStatusCustom(404);
      setTimeout(() => formStatusNull(), 3000);
    }
  };

  return (
    <>
      <Helmet>
        <title>{productDetails ? productDetails.name : ""} Product Inquiry</title>
      </Helmet>

      <div className="container">
        <div className="row">
          <div className="col-lg-12 col-xl-12 mx-auto text-center">
            <h1 className="display-center-5 fs-s text-center">Ask your question</h1>
            <form
              className="flex flex-col items-center gap-10"
              style={{ marginTop: "20px" }}
              onSubmit={onSubmit}
            >
              <div className="flex flex-col gap-10 p-20 w-full max-w-screen-lg bg-white rounded-md hover:shadow-xl transition-all ease-in-out">
                <FormInput
                  title="First Name"
                  type="text"
                  name="firstName"
                  value={inquiryData.firstName}
                  onChange={onChangeData}
                  onBlur={onBlurData}
                  placeholder="Enter your first name"
                  required
                  error={inquiryError.firstNameError}
                />
                <FormInput
                  title="Last Name"
                  type="text"
                  name="lastName"
                  value={inquiryData.lastName}
                  onChange={onChangeData}
                  onBlur={onBlurData}
                  placeholder="Enter your last name"
                  required
                  error={inquiryError.lastNameError}
                />
                <FormInput
                  title="Company"
                  type="text"
                  name="company"
                  value={inquiryData.company}
                  onChange={onChangeData}
                  onBlur={onBlurData}
                  placeholder="Enter your company name"
                  required
                  error={inquiryError.companyError}
                />
                <FormInput
                  title="Phone"
                  type="text"
                  name="phone"
                  value={inquiryData.phone}
                  onChange={onChangeData}
                  onBlur={onBlurData}
                  placeholder="Enter your phone number"
                  required
                  error={inquiryError.phoneError}
                />
                <FormInput
                  title="Email"
                  type="text"
                  name="email"
                  value={inquiryData.email}
                  onChange={onChangeData}
                  onBlur={onBlurData}
                  placeholder="Enter your email"
                  required
                  error={inquiryError.emailError}
                />
                <FormTextarea
                  title="Inquiry"
                  placeholder="If Any Inquiry Ask Here..."
                  name="question"
                  value={inquiryData.question}
                  onChange={onChangeData}
                  onBlur={onBlurData}
                  required={true}
                  error={inquiryError.questionError}
                />
                <div className="form-check text-left">
                  <input
                    className="form-check-input"
                    name="isNewsletter"
                    checked={inquiryData.isNewsletter}
                    onChange={(e) =>
                      setInquiryData({
                        ...inquiryData,
                        isNewsletter: e.target.checked,
                      })
                    }
                    type="checkbox"
                    id="inputCheckbox"
                  />
                  <label className="form-check-label ml-4" htmlFor="inputCheckbox">
                    Yes, please send me latest information on KanexPro
                  </label>
                </div>
                <button className="btn btn-primary float-xs-right" type="submit">
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default connect(null, {
  setLoadingTrue,
  formStatusPending,
  formStatusCustom,
  formStatusNull,
  postInquiry,
})(InquireProduct);
