import React from "react";
import { Helmet } from "react-helmet";
import { about } from "../common/partials/navData";
import SidebarSecondary from "../common/sidebar/SidebarSecondary";
// import "./Partner.scss";
import CataLog from "./catalog";
export default function KanexCatalog({ location }) {
  return (
    <>
      <Helmet>
        <title>KanexPro Catalog</title>
        <meta name="description" content="Professional Audio Video components"></meta>
        <meta name="keywords" content="kanexpro"></meta>
      </Helmet>
      <div className="d-flex">
        <div className="d-none d-lg-block d-xl-block">
          {/* <SidebarSecondary
           path={location.pathname} 
           data={about} title="About"
            /> */}
        </div>

        <div className="" style={{width:'100%', margin: "0px" }}>
          <CataLog />
        </div>
      </div>
    </>
  );
}
