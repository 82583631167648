import React from "react";
import { Link } from "react-router-dom";

import { safeContent } from "../../utils";

export default function IntroSlide(props) {
  const { filename, username, title, url, btnText = "DISCOVER MORE" } = props.data;

  const endpoint = props.data.url.includes("?id=")
    ? props.data.url.replace("https://www.kanexpro.com/item/?id=", "/item/")
    : props.data.url.replace("https://www.kanexpro.com", "");

  return (
    <Link to={endpoint} className="intro-slide">
      {/* <div className="bg-dark" /> */}
      <img src={`https://www.kanexpro.com/images/banners/${filename}`} alt={title} />
      {/* <div className="container intro-content text-center">
        <h3 className="intro-subtitle text-white">{title}</h3>

        <Link to={endpoint} className="btn btn-outline-white-4">
          <span>{btnText}</span>
        </Link>
      </div> */}
    </Link>
  );
}
