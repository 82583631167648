import React from "react";
import { Link } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "./post-one.scss";

function PostOne(props) {
  const {
    post,
    bodyClass = "text-center",
    btnText = "Continue Reading",
    adClass = "entry-display",
    isIsotope = false,
  } = props;

  if (post) {
    let date = new Date(post.date);
    let options = {
      year: "numeric",
      month: "short",
      day: "2-digit",
      timeZone: "UTC",
    };

    return (
      <Link to={`${process.env.PUBLIC_URL}/item/${post.sku}`}>
        <article className={`entry ${adClass} blog-card`}>
          <figure>
            {isIsotope ? (
              <img src={post.kproimage} alt="post_image" className="featured-products-img" />
            ) : (
              <>
                <div className="lazy-overlay bg-3"></div>
                <div className="img-container">
                  <LazyLoadImage
                    alt="post_image"
                    src={post.kproimage}
                    threshold={500}

                    width={100}
                    className="featured-products-img"
                  />
                </div>
              </>
            )}
            {/* </Link> */}
          </figure>

          <div className={`entry-body ${bodyClass}`}>
            <h3 className="entry-title">{post.title}</h3>

            <div className="entry-content">
              <p className="read-more">{post.sku}</p>
            </div>
          </div>
        </article>
      </Link>
    );
  } else {
    return "";
  }
}

export default PostOne;
