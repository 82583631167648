export const itemData = {
  //Cables
  // AOC
  CBLHDMIAOC20M: {
    title: "KanexPro Active Fiber High Speed HDMI Cable - 20M Length",
    description:
      "Plug and Play 4K/HDR @ 18Gbps w/ YUV 4:4:4 Sampling Up to 50 meters,KanexPro Active Fiber (AOC) High Speed HDMI Cables let you connect an HDMI source, distribution amplifier, extender or computer to an HDMI enabled display. These plenum (CMP) rated cables are available in the right lengths ideal for running through walls, between floors, and in plenum air spaces and top performance is a must.",
    keywords:
      "CBL-HDMIAOC20M,Cabling,AOC,kanexpro,813982022164,20M, 20 meters, Fiber, AOC, HDMI, Slim, SuperSlim, Thin, Cable, High Speed, UltraHD, Premium, Ethernet, Cable, Certified, HD, High Definition, Video, Audio video, ",
  },
  CBLHDMIAOC30M: {
    title: "KanexPro Active Fiber High Speed HDMI Cable - 30M Length",
    description:
      "Plug and Play 4K/HDR @ 18Gbps w/ YUV 4:4:4 Sampling Up to 50 meters,KanexPro Active Fiber (AOC) High Speed HDMI Cables let you connect an HDMI source, distribution amplifier, extender or computer to an HDMI enabled display. These plenum (CMP) rated cables are available in the right lengths ideal for running through walls, between floors, and in plenum air spaces and top performance is a must.",
    keywords:
      "CBL-HDMIAOC30M,Cabling,AOC,kanexpro,813982022171,HDMI AOC Fiber Cable Black In wall in-wall Plenum CMP CMR CL2 CL3 Premium High Speed 4K HDR UHD Dolby Vision optical durable 30m 30 Meters 60Hz 120Hz",
  },
  CBLHDMIAOC50M: {
    title: "KanexPro Active Fiber High Speed HDMI Cable - 50M Length",
    description:
      "Plug and Play 4K/HDR @ 18Gbps w/ YUV 4:4:4 Sampling Up to 50 meters,KanexPro Active Fiber (AOC) High Speed HDMI Cables let you connect an HDMI source, distribution amplifier, extender or computer to an HDMI enabled display. These plenum (CMP) rated cables are available in the right lengths ideal for running through walls, between floors, and in plenum air spaces and top performance is a must.",
    keywords:
      "CBL-HDMIAOC50M,Cabling,AOC,kanexpro,813982022188,HDMI AOC Fiber Cable Black In wall inwall Plenum CMP CMR CL2 CL3 Premium High Speed 4K HDR UHD Dolby Vision optical durable 50m 50 Meters ",
  },
  CBLDP14AOC20M: {
    title: "KanexPro Active Fiber High Speed DisplayPort 1.4 Cable - 20M Length",
    description:
      "8K DisplayPort to DisplayPort Cable (DisplayPort 1.4 Cable) with 8K 60Hz Video Resolution and HDR Support ,",
    keywords: "CBL-DP14AOC20M,Cabling,AOC,kanexpro,813982022195,",
  },
  CBLDP14AOC30M: {
    title: "KanexPro Active Fiber High Speed DisplayPort 1.4 Cable - 30M Length",
    description:
      "8K DisplayPort to DisplayPort Cable (DisplayPort 1.4 Cable) with 8K 60Hz Video Resolution and HDR Support ,8K DisplayPort to DisplayPort Cable (DisplayPort 1.4 Cable) with 8K 60Hz Video Resolution and HDR Support",
    keywords:
      "CBL-DP14AOC30M,Cabling,AOC,kanexpro,813982022201,Displayport 1.4 1.2 32GBps Fiber Optic Cable AOC 8K 60Hz 144Hz HDR 50M 4k Freesync MST gaming high frame rate smooth 30m",
  },
  CBLDP14AOC50M: {
    title: "KanexPro Active Fiber High Speed DisplayPort 1.4 Cable - 50M Length",
    description:
      "8K DisplayPort to DisplayPort Cable (DisplayPort 1.4 Cable) with 8K 60Hz Video Resolution and HDR Support ,8K DisplayPort to DisplayPort Cable (DisplayPort 1.4 Cable) with 8K 60Hz Video Resolution and HDR Support",
    keywords:
      "CBL-DP14AOC50M,Cabling,AOC,kanexpro,813982022218,Displayport 1.4 1.2 32GBps Fiber Optic Cable AOC 8K 60Hz 144Hz HDR 50M 4k Freesync MST gaming high frame rate smooth",
  },
  CBLAOC100M4K: {
    title: "18G HDMI® Active Optical Cable with 4K/60Hz - 100m",
    description: "True 4K/HDR @ 18Gbps w/ YUV 4:4:4 Sampling Up to 100 meters,",
    keywords: "CBL-AOC100M4K,Cabling,AOC,kanexpro,813982021129 ,",
  },
  CBLAOC30M4K: {
    title: "18G HDMI® Active Optical Cable with 4K/60Hz - 30m",
    description: "True 4K/HDR @ 18Gbps w/ YUV 4:4:4 Sampling Up to 30 meters,",
    keywords: "CBL-AOC30M4K,Cabling,AOC,kanexpro,813982021105,",
  },
  CBLAOC50M4K: {
    title: "18G HDMI® Active Optical Cable with 4K/60Hz - 50m",
    description: "True 4K/HDR @ 18Gbps w/ YUV 4:4:4 Sampling Up to 50 meters,",
    keywords: "CBL-AOC50M4K,Cabling,AOC,kanexpro,813982021112,",
  },

  // HDMI

  CBLHT8181HDMI25FT: {
    title: "KanexPro Active 18Gbps High Speed HDMI Cable CL3 Rated - 25ft Length",
    description: "18Gbps High Speed and 28 AWG ,",
    keywords:
      "CBL-HT8181HDMI25FT,Cabling,HDMI,kanexpro,813982022256,HDMI, 25ft, HDR, Dolby Vision, HDR+, 4K, 1080p, Media Players, Distribution Amplifiers, Extender, Converters, Gaming Systems, PCs, and HDMI enabled device",
  },
  CBLHT8181HDMI50FT: {
    title: "KanexPro Active 18Gbps High Speed HDMI Cable CL3 Rated - 50ft Length",
    description: "18Gbps High Speed and 28 AWG ,",
    keywords:
      "CBL-HT8181HDMI50FT,Cabling,HDMI,kanexpro,813982022263,HDMI, 50ft, HDR, Dolby Vision, HDR+, 4K, 1080p, Media Players, Distribution Amplifiers, Extender, Converters, Gaming Systems, PCs, and HDMI enabled device",
  },
  CBLHT7180HDMI75FT: {
    title: "KanexPro Active High Speed HDMI Cable CL3 Rated - 75ft Length",
    description: "High Speed Cable and CL3 UL In-Wall Fire Rating,",
    keywords:
      "CBL-HT7180HDMI75FT,Cabling,HDMI,kanexpro,813982022270,HDMI, 75ft, HDR, Dolby Vision, HDR+, 4K, 1080p, Media Players, Distribution Amplifiers, Extender, Converters, Gaming Systems, PCs, and HDMI enabled device",
  },
  CBLHT7180HDMI100FT: {
    title: "KanexPro Active High Speed HDMI Cable CL3 Rated - 100ft Length",
    description: "High Speed Cable and CL3 UL In-Wall Fire Rating,",
    keywords:
      "CBL-HT7180HDMI100FT,Cabling,HDMI,kanexpro,813982022287,HDMI, 100ft, HDR, Dolby Vision, HDR+, 4K, 1080p, Media Players, Distribution Amplifiers, Extender, Converters, Gaming Systems, PCs, and HDMI enabled device",
  },
  HDLK3FT: {
    title: "High Speed HDMI Locking Cable - 3FT",
    description:
      "Ferrite Core & 28 AWG ,The KanexPro High Definition Multimedia Interface Cables provide a locking and reliable interface between a compatible digital A/V source and compatible audio and/or video device. ",
    keywords: "HDLK3FT,Cabling,HDMI,kanexpro,814556015131,HDMI High Speed Cable 3 Feet Three black in Wall ",
  },
  HD25FTCL314: {
    title: "High-resolution HDMI cables - 25ft",
    description: "High Speed HDMI cables with Ethernet plus signal equalization,",
    keywords: "HD25FTCL314,Cabling,HDMI,kanexpro,814556012239,",
  },
  HD50FTCL314: {
    title: "High-resolution HDMI cables with Signal Equalizer - 50ft",
    description: "High Speed HDMI cables with Ethernet plus signal equalization,",
    keywords: "HD50FTCL314,Cabling,HDMI,kanexpro,814556012246,",
  },
  HD75FTCL314: {
    title: "High-resolution HDMI cables with Signal Equalizer - 75ft",
    description: "High Speed HDMI cables with Ethernet plus signal equalization,",
    keywords: "HD75FTCL314,Cabling,HDMI,kanexpro,814556012253,",
  },
  HD100FTCL314: {
    title: "High-resolution HDMI cables with Signal Equalizer- 100ft",
    description: "High Speed HDMI cables with Ethernet plus signal equalization,",
    keywords: "HD100FTCL314,Cabling,HDMI,kanexpro,814556012222,",
  },

  // HDMI Certified

  CBLHDMICERTSS3FT: {
    title: "KanexPro SuperSlim Premium High Speed Certified HDMI Cable - 3ft Length",
    description: "Premium High Speed Certified and 36 AWG ,",
    keywords:
      "CBL-HDMICERTSS3FT,Cabling,HDMI Certified,kanexpro,813982022126,3ft, HDMI, 36AWG, Slim, SuperSlim, Thin, Cable, High Speed, UltraHD, Premium, Ethernet, Cable, Certified, HD, High Definition, Video, Audio video, ",
  },
  CBLHDMICERTSS6FT: {
    title: "KanexPro SuperSlim Premium High Speed Certified HDMI Cable - 6ft Length",
    description: "Premium High Speed Certified and 34 AWG ,",
    keywords: "CBL-HDMICERTSS6FT,Cabling,HDMI Certified,kanexpro,813982022133,",
  },
  CBLHDMICERT15FT: {
    title: "KanexPro Premium High Speed Certified HDMI Cable - 15ft Length",
    description: "Premium High Speed Certified and 28 AWG ,",
    keywords:
      "CBL-HDMICERT15FT,Cabling,HDMI Certified,kanexpro,813982022140,15ft, HDMI, 36AWG, Slim, SuperSlim, Thin, Cable, High Speed, UltraHD, Premium, Ethernet, Cable, Certified, HD, High Definition, Video, Audio video, ",
  },
  CBLHDMICERT25FT: {
    title: "KanexPro Premium High Speed Certified HDMI Cable - 25ft Length",
    description: "Premium High Speed Certified and 28 AWG ,",
    keywords:
      "CBL-HDMICERT25FT,Cabling,HDMI Certified,kanexpro,813982022157,25ft, HDMI, 36AWG, Slim, SuperSlim, Thin, Cable, High Speed, UltraHD, Premium, Ethernet, Cable, Certified, HD, High Definition, Video, Audio video, ",
  },

  //USB

  EXTUSB16FT: {
    title: "SuperSpeed USB 3.0 Active Extension Cable – 16ft. (4.9m)",
    description:
      "Extend USB Cameras & PC peripherals up to 16ft with SuperSpeed 5 Gbps.,Make a USB 3.0 cable longer with this extender",
    keywords:
      "EXT-USB16FT,Cabling,USB,kanexpro,813982021549,USB 3.0 2.0 Extender Gaming Black USB A Female Male 5gbps computer cable 16ft feet 16",
  },

  EXTUSB32FT: {
    title: "SuperSpeed USB 3.0 Active Extension Cable – 32ft. (10m)",
    description:
      "Extend USB Cameras & PC peripherals up to 32ft with SuperSpeed 5 Gbps.,Make a USB 3.0 cable longer with this extender",
    keywords:
      "EXT-USB32FT,Cabling,USB,kanexpro,813982021556,USB 3.0 2.0 Extender Gaming Black USB A Female Male 5gbps computer cable 32ft feet 32",
  },

  // Control
  // Extenders

  WPIPKEYPAD8: {
    title: "Integrated IR/RS-232 and Relay Controller | KanexPro CR-3XCONTROL ",
    description:
      "KanexPro is the leading Integrated IR/RS-232 Manufacturer. We carry Integrated IR/RS-232 and Relay Controllers. Check out the best one-stop-shop AV Manufacturer.",
    keywords: "WP-IPKEYPAD8,Control,Extenders,kanexpro,813982021525,",
  },
  CR3XCONTROL: {
    title: "Integrated IR/RS-232 and Relay Controller | KanexPro CR-3XCONTROL ",
    description:
      "KanexPro is the leading Integrated IR/RS-232 Manufacturer. We carry Integrated IR/RS-232 and Relay Controllers. Check out the best one-stop-shop AV Manufacturer.",
    keywords: "CR-3XCONTROL ,Control,Extenders,kanexpro,813982021532,",
  },
  WPCONTROLB: {
    title: "Programmable Control Panel for IR, RS-232 & RS-485 - Discontinued ",
    description: "Wall plate based Control Panel for AV devices - Black,",
    keywords: "WP-CONTROLB,Control,Extenders,kanexpro,814556017548,",
  },
  WPCONTROLS: {
    title: "Programmable Control Panel for IR, RS-232 & RS-485 - Discontinued",
    description: "Wall plate based Control Panel for AV devices - Silver ,",
    keywords: "WP-CONTROLS,Control,Extenders,kanexpro,812455601755,",
  },

  // Converters
  // Audio
  HAECOAX4: {
    title: "HDMI 2.0 Audio Embedder 18Gbps HDCP 2.2 4K 60Hz ",
    description:
      "Embed or insert audio signals coming from a digital optical or analog stereo source into an HDMI device.,Embed or insert audio signals coming from a digital optical or analog stereo source into an HDMI device.",
    keywords:
      "HAECOAX4 ,Converters,Audio,kanexpro,813982022393,HDMI 2.0, Audio, Inserter, Embedder, 18Gbps, HDCP 2.2, 4K, 60Hz, TOSLINK, Optical Audio, Stereo, Analog, Streaming, Broadcasting, Digital, 2 Channel, 5.1, Stream ",
  },
  HAECOAX3: {
    title: "18Gbps 4K HDMI® Audio Extractor with Dolby Atmos®",
    description:
      "Extract Digital & Stereo Audio from HDMI ,hdmi splitter, hdmi audio extractor, hdmi converter, audio extractor convertor, audio extractor splitter, optical audio splitter, 4k hdmi splitter, digital converter box, hdmi audio converter, hdmi audio extractor 4k, audio extractor ",
    keywords:
      "HAECOAX3,Converters,Audio,kanexpro,813982021136,Sound extractor splitter switcher adapter hdmi 4k optical input output electronic device converter tool gadget speaker system adapter synchronizes audio video cable hub digital stereo box  ",
  },
  AP3DBL: {
    title: "Mini 3-input Audio Amplifier with Mic Mixer",
    description: "40 Watt Power Amp supporting 70V or 100V,",
    keywords: "AP3DBL,Converters,Audio,kanexpro,814556016077,",
  },
  AP2DBL: {
    title: "Mini 2-input Audio Amplifier with Mic Mixer",
    description: "Class D amplifier with EQ control and MIC mixer function,",
    keywords: "AP2DBL,Converters,Audio,kanexpro,814556015087,",
  },
  HAECOAX: {
    title: "Audio De-Embedder with 3D Support ",
    description: "Extract multi-channel audio from HDMI ,",
    keywords:
      "HAECOAX,Converters,Audio,kanexpro,814556014721,Sound extractor splitter switcher adapter hdmi 4k optical input output electronic device converter tool gadget speaker system adapter synchronizes audio video cable hub digital stereo box  ",
  },
  AUA2DCV: {
    title: "Analog to Digital Audio Converter - Discontinued ",
    description: "RCA to Digital S/PDIF or Toslink Adapter,",
    keywords: "AUA2DCV,Converters,Audio,kanexpro,814556013588,",
  },
  AUD2ACV: {
    title: "Digital to Analog Audio Converter",
    description: "Digital S/PDIF to RCA adapter,Convert toslink optical audio to analog stereo RCA audio",
    keywords: "AUD2ACV,Converters,Audio,kanexpro,814556013595,audio converter toslink digital analog RCA stereo PCM ",
  },

  //Component
  HDRGBRL: {
    title: "HDMI to Component with Audio Converter",
    description:
      "Convert HDMI to component video and stereo audio,The KanexPro HDMI to Component video with audio converter is a designed for converting HDMI signals such as a laptop, set up box or a media player to legacy component video signals with audio. ",
    keywords:
      "HDRGBRL,Converters,Component,kanexpro,814556013304,HDMI Component RCA Red White Blue Green Cables Audio Video Digital Analog Converter",
  },

  // Composite
  CONAVHD4K: {
    title: "Composite/ S-video to 4K HDMI® Converter",
    description: "Convert Legacy Composite AV or S-Video signals to 4K UHD ,",
    keywords: "CON-AV-HD4K,Converters,Composite,kanexpro,813982020443,",
  },
  HDRCA: {
    title: "HDMI to Composite with Audio Converter",
    description: "Convert HDMI signal into Composite video and audio ,",
    keywords: "HDRCA,Converters,Composite,kanexpro,814556013298,",
  },
  HDCVRYW: {
    title: "Composite or S-Video with Audio to HDMI - Discontinued",
    description: "Convert Composite or S-Video signals to HDMI ,",
    keywords: "HDCVRYW,Converters,Composite,kanexpro,814556013816,",
  },

  // DVI

  DVIRLHD: {
    title: "DVI with RCA audio to HDMI Converter (Discontinued)",
    description: "Convert DVI with Audio to Full HD 1080p/60,",
    keywords: "DVIRLHD,Converters,DVI,kanexpro,814556013281,",
  },

  // HDMI

  CONGAMECAPL: {
    title: "HDMI 4K USB 2.0 Streaming Capture box w/ HDMI Loop out and cable",
    description: "Capture, Stream, Record from an HDMI source and send it to your PC or to the web,",
    keywords: "CON-GAMECAPL,Converters,HDMI,kanexpro,813982022652,",
  },
  CONGAMECAP: {
    title: "HDMI 4K USB 2.0 Gaming Capture Dongle w/ 3ft HDMI Cable",
    description:
      "Capture, Stream, Record from an HDMI source and send it to your PC or to the web,Capture your next epic gaming session, speed run or sim racing lap record with the KanexPro CON-GAMECAP Gaming Capture dongle. Compatible with PCs, Gaming consoles and HDMI compatible action cameras, Stream, Capture and record content in 4K quality.",
    keywords:
      "CON-GAMECAP,Converters,HDMI,kanexpro,813982022607,Capture, Record, USB 2.0, USB, Gaming, Streaming, HDMI, Education, 1080p, 4K, Console, PC, ",
  },

  // SDI
  EXTSDIVGA: {
    title: "3G/HD-SDI/ SDI to VGA Converter w/ Scaling Technology",
    description:
      "Perfect for Scaling & Broadcasting SDI signals to VGA displays ,Convert SDI signals from a camera or splitter into VGA that can be used with a monitor or display",
    keywords:
      "EXT-SDIVGA,Converters,SDI,kanexpro,813982020085,SDI, VGA, Signal, Converter, Camera, Display, Monitor, Computer, Broadcast, sender, 300m, 200m,1080p, 720p, FHD, HD, High Definition",
  },
  EXTSDHDX: {
    title: "3G-SDI to HDMI Converter ",
    description: "SDI to HDMI converter,",
    keywords: "EXT-SDHDX,Converters,SDI,kanexpro,813982020061,",
  },
  EXTHDSDIX: {
    title: "HDMI to 3G/HD-SDI/ SDI Converter",
    description: "Convert HDMI Signals from a PC or an HD Camera to an SDI Monitor ,",
    keywords: "EXT-HDSDIX,Converters,SDI,kanexpro,813982020078,",
  },

  // VGA
  CONVGAHD4K: {
    title: "VGA to 4K HDMI® Converter",
    description: "Scale & Convert Analog Computer Video signals to 4K UHD ,",
    keywords: "CON-VGA-HD4K,Converters,VGA,kanexpro,813982020450,",
  },
  VGARLHD: {
    title: "VGA to HDMI with Audio Converter ",
    description: "Convert VGA signal + Audio to HDMI ,",
    keywords: "VGARLHD,Converters,VGA,kanexpro,814556013601,",
  },
  HDVGARL: {
    title: "HDMI to VGA with Audio Converter ",
    description:
      "Convert HDMI signal into analog VGA with audio,The KanexPro HDMI to VGA with Audio Converter is a classic fit for VGA based displays and projectors. Connect your HD sources such as Apple TV2, DVD and Blu-ray players or, PC and game consoles to a VGA enabled display with 2- channel stereo audio.",
    keywords: "HDVGARL,Converters,VGA,kanexpro,814556013311,HDMI VGA Converter 1080p audio signal DAC Analog Digital ",
  },

  // Extenders
  // Fiber Optic

  EXT4KFIBERPRO: {
    title: "HDMI Fiber Optic Extender with 4K/60 up to 300 meters",
    description: "Extend 4K Video, Audio & Control over Fiber up to 984ft. (300m) with HDR, Dolby Vision & ARC,",
    keywords: "EXT-4KFIBERPRO,Extenders,Fiber Optic,kanexpro,813982021952,",
  },
  SDIEXTFIBERPRO: {
    title: "Fiber Optic SDI Extender Set - Up to 6.2 miles (10Km)",
    description: "Extend 3G/HD-SDI Signals on a Single Mode Optical Extender,",
    keywords: "SDI-EXTFIBERPRO,SDI,Extenders,kanexpro,813982020948,",
  },

  // HDBaseT

  EXTHDBT70C: {
    title: "4K/30 HDMI® Extender over HDBaseT™",
    description: "Extend pristine 4K/30 with bi-directional PoH, IR & RS-232 up to 40m,",
    keywords: "EXT-HDBT70C,Extenders,HDBaseT,kanexpro,813982021624,",
  },
  EXTHDBT150M: {
    title: "HDMI® 150m Extender w/ loop out",
    description: "Extend high res. 1080p/60 w/ bi-directional IR & RS-232 ,",
    keywords: "EXT-HDBT150M,Extenders,HDBaseT,kanexpro,813982021518,",
  },
  EXT100SL18G: {
    title: "UltraSlim 4K/60 HDMI® 2.0 Extender over HDBaseT™ up to 330ft & PoH",
    description: "Extend pristine 4K/60 with HDR, 4:4:4 with bi-directional IR, RS-232 & LAN,",
    keywords: "EXT-100SL18G,Extenders,HDBaseT,kanexpro,813982021334,",
  },
  EXT70SL18G: {
    title: "UltraSlim 4K/60 HDMI® 2.0 Extender over HDBaseT™ up to 230ft & PoH",
    description: "Extend pristine 4K/60 with HDR, 4:4:4 & bi-directional IR, RS-232 & power,",
    keywords: "EXT-70SL18G,Extenders,HDBaseT,kanexpro,813982021341,",
  },
  EXTHDBT70M: {
    title: "UltraSlim 4K/60 HDMI® Extender over HDBaseT™- 230ft. (70m)",
    description: "Extend pristine 4K/60 with bi-directional PoH, IR & RS-232 ,",
    keywords: "EXT-HDBT70M,Extenders,HDBaseT,kanexpro,813982021358,",
  },
  EXTHDBT70MTX: {
    title: "UltraSlim 4K/30 HDMI® Transmitter over HDBaseT™- 230ft. (70m)",
    description: "Extend 4K/30 up to 40m with bi-directional IR, RS-232 & PoH,",
    keywords: "EXT-HDBT70MTX,Extenders,HDBaseT,kanexpro,813982021365,",
  },
  EXTHDBT70MRX: {
    title: "UltraSlim 4K/60 HDMI® Receiver over HDBaseT™- 230ft. (70m)",
    description: "Extend 4K/60 up to 40m with bi-directional IR, RS-232 & PoH,",
    keywords: "EXT-HDBT70MRX,Extenders,HDBaseT,kanexpro,813982021372,",
  },
  EXTHDBTKVM100: {
    title: "4K HDMI® Extender over Cat6 w/ USB 2.0",
    description: "Extend HDMI, USB 2.0 with RS-232 up to 150 meters (492 feet) ,",
    keywords: "EXT-HDBTKVM100,Extenders,HDBaseT,kanexpro,813982020580,",
  },
  EXTHD100MHBT: {
    title: "4K HDBaseT™100-Meter HDMI® Extender w/ PoE Support ",
    description: "Extend Uncompressed HDMI up to 330 feet with UltraHD 4K, Digital Audio & IR over single CAT6a,",
    keywords: "EXT-HD100MHBT,Extenders,HDBaseT,kanexpro,813982020030,",
  },
  HDBASE100ME: {
    title: "4K HDBaseT™100 Meter HDMI® Extender w/ 2-Port Ethernet Switch",
    description: "Extend HDMI, 2-port LAN switch, IR & RS-232 up to 100 meters ( 330 feet),",
    keywords: "HDBASE100ME,Extenders,HDBaseT,kanexpro,814556016305,",
  },

  // HDMI

  EXTHD50C: {
    title: "HDMI Extender over CAT5/6 up to 164ft. (50m)",
    description:
      "Extremely compact HDMI extension over a single Cat5e/6 with long distance capability ,The KanexPro EXT-HD50C extender is a compact HDMI Video transmitter - receiver set designed to extend HD signals from your HDMI source device to a display remotely located up to 230ft feet away. Featuring an HDMI loop-out, the transmitter unit supports a second local display",
    keywords:
      "EXT-HD50C,Extenders,HDMI,kanexpro,813982022676,HDMI 1.4, HDMI, 164ft, 50 meters, 70 Meters, 1080P, 4K, High Definition, UHD, Dolby Vision, HDR, Extender, Cat5e, Cat5, Cat6, wired, Cable, High Speed, 18Gpbs, 10.2gbps",
  },
  EXT50M18G: {
    title: "50M 4K 18Gbps HDMI Extender",
    description:
      "HDMI extension over a single Cat5e/6 with long 168ft distance capability ,The extender offers the most convenient solution for HDMI extension over a single Cat5e/6 with long distance capability and is the perfect solution for any application.",
    keywords:
      "EXT-50M18G,Extenders,HDMI,kanexpro,813982022065,HDMI 2.0, HDMI 1.4, HDMI, 164ft, 50 meters, 4K, High Definition, UHD, Dolby Vision, HDR, Extender, Cat5e, Cat5, Cat6, wired, Cable, High Speed, 18Gpbs, ",
  },
  EXT18GHDMIWL: {
    title: "Wireless 5G HDMI Extender 1080p Full HD 330ft 100M Dual Antenna Long Range Kit",
    description:
      "Wirelessly connect a BluRay or TV Box to a TV at distances up to 330ft,Wirelessly connect a source to a display or projector.",
    keywords:
      "EXT-18GHDMIWL,Extenders,HDMI,kanexpro,813982022522,HDMI Extender 5G Wireless Receiver Digital Dual Antenna 1080p, 330ft 100m distance BluRay Television Projector Audio Video Stable Media Transmission Long Range",
  },
  EXTHD60M: {
    title: "HDMI Extender over CAT6 up to 196ft. (60m)",
    description:
      "Extend HD 1080p/60 Signals over CAT6/6a up to 196ft.(60m),Extend HD 1080p/60 Signals over CAT6/6a up to 196ft.(60m)",
    keywords: "EXT-HD60M,Extenders,HDMI,kanexpro,813982020962,",
  },
  HDEXT50M: {
    title: "HDMI Extender over CAT5/6 up to 164ft. (50m)",
    description:
      "Extend HD Signals over CAT 5/5e up to 165ft.(50m) w/ local HDMI Loop out ,HDEXT50M extender is a set of transmitter and receiver which sends full HD 1080p/60 HDMI signals over CAT5/5e cable",
    keywords:
      "HDEXT50M,Extenders,HDMI,kanexpro,814556013427,HDMI Cat5 Cat5e cat6 ethernet Extender 1080p HD high definition Video Audio Transmitter receiver TX RX ",
  },
  HDREPEAT10G: {
    title: "HDMI Repeater with 3D Support – Discontinued. ",
    description: "Regenerate, re-clock and equalize HD signals over long distances,",
    keywords: "HDREPEAT10G,Extenders,HDMI,kanexpro,814556013618,",
  },

  // USB

  EXTUSB2100M: {
    title: "USB 2.0 Extender over Cat6 100 Meters",
    description:
      "The extender offers the most convenient solution for USB 2.0 extension over a single Cat5e/6 with long distance capability and is the perfect solution for any application.,The extender offers the most convenient solution for USB 2.0 extension over a single Cat cable with long distance capability and is the perfect solution for any application.",
    keywords:
      "EXT-USB2100M,Extenders,USB,kanexpro,813982022379,USB 2.0, 300ft, 100 meters, SSD, Hard Drive,Storage, Extender, Cat5e, Cat5, Cat6, wired, Cable, High Speed USB, 480mpbs, Micro USB, Storage, Touch Screen, HID, Human interface device, ",
  },
  EXTUSB250M: {
    title: "USB 2.0 Extender over Cat6 50 Meters",
    description:
      "The extender offers the most convenient solution for USB 2.0 extension over a single Cat5e/6 with long distance capability and is the perfect solution for any application.,The extender offers the most convenient solution for USB 2.0 extension over a single Cat cable with long distance capability and is the perfect solution for any application.",
    keywords:
      "EXT-USB250M,Extenders,USB,kanexpro,813982022386,USB 2.0, 164ft, 50 meters, SSD, Hard Drive,Storage, Extender, Cat5e, Cat5, Cat6, wired, Cable, High Speed USB, 480mpbs, Micro USB, Storage, Touch Screen, HID, Human interface device, ",
  },

  // VGA
  VGAEXTX1: {
    title: "VGA 1x1 Extender over CAT5e/6 with Audio up to 1,000ft (300m)",
    description: "Extend high-resolution VGA signals over a single CAT5e/6 cable,",
    keywords: "VGAEXTX1,Extenders,VGA,kanexpro,814556013465,",
  },
  VGAEXTX2: {
    title: "VGA 1x2 Extender over CAT5e/6",
    description: "Extend up to two high-resolution VGA displays over CAT5e/6 cable,",
    keywords: "VGAEXTX2,Extenders,VGA,kanexpro,814556013458,",
  },
  VGAEXTX4: {
    title: "VGA 1x4 Extender over CAT5e/6 - Discontinued",
    description: "Extend and distribute up to four high-resolution VGA signals over CAT5e/6 cable,",
    keywords: "VGAEXTX4,Extenders,VGA,kanexpro,814556013441,",
  },

  // Wall Plates
  WPEDUKIT4K: {
    title: "Premium Extender Kit for K-12 Educational Applications",
    description: "Connect and Control a Projector, Smart Board, Microphone, Speakers, and Laptops in a K-12 Classroom,",
    keywords: "WP-EDUKIT4K,Extenders,Wall Plates,kanexpro,813982022072,",
  },
  WP2X1HDUSBC: {
    title: "Double Gang 4K HDMI 2.0 & USB-C Wallplate Switcher over HDBaseT w/ IR & PoH (Discontinued)",
    description: "Switch & Extend Two HDMI devices and a USB-C up to 230ft. (70m) with control,",
    keywords: "WP-2X1HDUSBC,Extenders,Wall Plates,kanexpro,813982021938,",
  },
  WPHDBTKVM3: {
    title: "Double Gang 4K HDMI® & USB 2.0 Wall Plate Switcher over HDBaseT w/ IR & PoH",
    description: "Switch & Extend Two HDMI sources from a Wall Plate up to 100 meters with USB 2.0 KVM,",
    keywords: "WP-HDBTKVM3,Extenders,Wall Plates,kanexpro,813982021945,",
  },
  WPEXTHDBTKIT: {
    title: "Single HDMI 2.0 Wallplate over HDBaseT 70M w/ IR & POC Receiver Set",
    description: "Extend Single HDMI source from a Wall Plate up to 70 meters with control,",
    keywords: "WP-EXTHDBTKIT,Extenders,Wall Plates,kanexpro,813982021976,",
  },
  WPHDBASETX: {
    title: "4K HDMI & VGA Wall Plate Transmitter  Over HDBaseT™ ",
    description: "Auto Switch, Extend & Control HDMI & Analog video signals up to 230 feet (70m) ,",
    keywords: "WP-HDBASETX,Extenders,Wall Plates,kanexpro,814556017562,",
  },

  // NetworkAV
  // H.264

  EXTNETAVTX: {
    title: "NetworkAV™over IP Encoder w/ POE & RS-232",
    description: "Unicast, Multicast with easy to control, video wall & multi-view over IP ,",
    keywords: "EXT-NETAVTX,NetworkAV,H.264,kanexpro,813982021891,",
  },
  EXTNETAVRX: {
    title: "NetworkAV™over IP Decoder w/ POE & RS-232",
    description: "Unicast, Multicast with easy to control, video wall & multi-view over IP,",
    keywords: "EXT-NETAVRX,NetworkAV,H.264,kanexpro,813982021907,",
  },
  EXTAVIPH264TX: {
    title: "NetworkAV™ H.264 HDMI® Transmitter over IP w/ POE & RS-232",
    description: "Send HDMI® Audio & Video over an Ethernet network to multiple screens,",
    keywords: "EXT-AVIPH264TX,NetworkAV,H.264,kanexpro,813982021198,",
  },
  EXTAVIPH264RX: {
    title: "NetworkAV™ H.264 HDMI® Receiver over IP w/ POE & RS-232",
    description: "Send HDMI® Audio & Video over an Ethernet network to multiple screens,",
    keywords: "EXT-AVIPH264RX,NetworkAV,H.264,kanexpro,813982021204,",
  },
  EXTAVIP120M: {
    title: "NetworkAV™ 120M Extender Set ",
    description: "HDMI® Video Over IP Encoder - Decoder Kit up to 120m ,",
    keywords: "EXT-AVIP120M,NetworkAV,H.264,kanexpro,813982021174,",
  },
  EXTAVIP120MRX: {
    title: "NetworkAV™ 120M Receiver ",
    description: "HDMI® Video Over IP reciever (Rx) up to 120m,",
    keywords: "EXT-AVIP120MRX,NetworkAV,H.264,kanexpro,813982021181,",
  },

  // SDVoE

  EXTPROMATRIXUSB: {
    title: "Flexible Matrix System transceiver powered by SDVoE w/ USB, POE & RS-232",
    description: "Unicast, Multicast with easy to control, video wall, PiP, & multi-view over IP ,",
    keywords: "EXT-PROMATRIXUSB,NetworkAV,SDVoE,kanexpro,813982022423,",
  },
  EXTPROCTRL: {
    title: "Flexible Matrix System Controller powered by SDVoE w/ POE & RS-232",
    description: "Unicast, Multicast with easy to control, video wall & multi-view over IP ,",
    keywords: "EXT-PROCTRL,NetworkAV,SDVoE,kanexpro,813982022447,",
  },
  EXTPRORACK: {
    title: "Modular Rack for Flexible Matrix System",
    description: "Organize and Declutter with this 6U Rack for EXT-PROMATRIXUSB/EXT-PROCTRL,",
    keywords: "EXT-PRORACK,NetworkAV,SDVoE,kanexpro,813982022454,",
  },

  // SDI
  // Converters
  SDISDHDXPRO: {
    title: "SDI to HDMI Converter with Signal EQ & Re-Clocking",
    description:
      "Next Gen. Solid SDI to HDMI Converter with Built-in Re-clocking ,Ideal for connecting your professional SDI video equipment to any HDMI display or consumer based TV’s",
    keywords:
      "SDI-SDHDXPRO,SDI,Converters,kanexpro,813982020917,HDMI SDI Reclocking Extender Converter Signal EQ 1080p 60Hz 1080i 720p Coax ",
  },
  SDIHDSDXPRO: {
    title: "HDMI to SDI Converter with Signal EQ & Re-Clocking",
    description: "Next Gen. Solid HDMI to SDI Converter with Built-in Re-clocking ,",
    keywords: "SDI-HDSDXPRO,SDI,Converters,kanexpro,813982020924,",
  },
  SDISDI2MULTIPRO: {
    title: "SDI/HDMI with Audio Embedder to Multiple Video - Discontinued ",
    description: "Convert 3G/HD/SD-SDI & HDMI to DVI-D, VGA, Component, S-video or Composite,",
    keywords: "SDI-SDI2MULTIPRO,SDI,Converters,kanexpro,813982021020,",
  },
  SDIMULTI2SDIPRO: {
    title: "Multiple Video Cross-Converter to SDI/HDMI - Discontinued ",
    description: "Convert DVI, VGA, Component, S-video or Composite to 3G/HD/SD-SDI & HDMI ,",
    keywords: "SDI-MULTI2SDIPRO,SDI,Converters,kanexpro,813982021037,",
  },

  // Extenders

  // SDIEXTFIBERPRO: {
  //   title: "",
  //   description: "",
  //   keywords: ""
  // }

  SDIHDRPTPRO: {
    title: "HD-SDI Repeater with Signal Equalization & Re-clocking ",
    description: "Extend SDI or Daisy chain multiple SDI repeaters to create longer runs,",
    keywords: "SDI-HDRPTPRO,SDI,Extenders,kanexpro,813982020931,",
  },

  // Splitters

  SPSDIX2: {
    title: "3G /HD-SDI 1x2 Discontinued",
    description: "Distribute 1 SDI Signal to 2 SDI Monitors ,",
    keywords: "SP-SDIX2,SDI,Splitters,kanexpro,813982020474,",
  },
  SPSDIX4: {
    title: "3G /HD-SDI 1x4 Discontinued",
    description: "Distribute 1 SDI Signal to 4 SDI Monitors ,",
    keywords: "SP-SDIX4,SDI,Splitters,kanexpro,813982020481,",
  },

  // Splitters

  // HDMI

  SPHDPOC1X4: {
    title: "HDMI® 1x4 Distribution Amplifier over CAT5e/6 Outputs and PoC 60M",
    description:
      "**Coming Soon** Distribute & Extend HDMI over Single CAT5e/6 to four HD displays,The KanexPro HDMI amplifier offers an ideal, cost effective means for extending and distributing digital video, multichannel audio, and control signals using IR from HDMI equipped devices. Each receiver is specifically calibrated to work with the splitter as a full functional unit enabling full 4K resolution.",
    keywords: "SP-HDPOC1X4,Splitters,HDMI,kanexpro,813982022683,",
  },
  SPHDPOC1X8: {
    title: "HDMI® 1x8 Distribution Amplifier over CAT5e/6 Outputs and PoC 60M",
    description:
      "Distribute & Extend HDMI over Single CAT5e/6 to eight HD displays,The KanexPro HDMI amplifier offers an ideal, cost effective means for extending and distributing digital video, multichannel audio, and control signals using IR from HDMI equipped devices. Each receiver is specifically calibrated to work with the splitter as a full functional unit enabling full 4K resolution.",
    keywords: "SP-HDPOC1X8,Splitters,HDMI,kanexpro,813982022690,",
  },

  SPHDBT1X4KIT: {
    title: "4K HDBaseT™ 1x4 Distribution Amplifier up to 230 feet (70m) w/4 Receivers",
    description: "Broadcast 4 Identical Outputs over 230 feet (70 meters) with UltraHD 4K ,",
    keywords: "SP-HDBT1X4KIT,Splitters,HDMI,kanexpro,815387022909,",
  },

  SPHDCAT1X8: {
    title: "HDMI® 1x8 Distribution Amplifier over CAT5e/6 Outputs",
    description:
      "Distribute & Extend HDMI over Single CAT5e/6 to eight HD displays,This HDMI amplifier offers an ideal, cost effective means for extending digital video, multichannel audio, and control signals using IR from HDMI equipped devices. Each receiver works with the splitter as a full functional unit enabling full 1920 x 1080p/60 resolution.",
    keywords: "SP-HDCAT1X8,Splitters,HDMI,kanexpro,813982021501,",
  },

  SPHDCAT1X4: {
    title: "HDMI® 1x4 Distribution Amplifier over CAT5e/6 Outputs",
    description: "Distribute & Extend HDMI over Single CAT5e/6 to four HD displays,",
    keywords: "SP-HDCAT1X4,Splitters,HDMI,kanexpro,813982021143,",
  },

  SPHD201X44K: {
    title: "4K HDMI 1x4 Splitter with HDCP2.2",
    description: "1x4 HDMI DA w/ HDCP2.2, 4K Cinema resolutions & Smart EDID Control,",
    keywords: "SP-HD20-1X44K,Splitters,HDMI,kanexpro,813982020405,",
  },

  SPHD201X24K: {
    title: "4K HDMI 1x2 Splitter with HDCP2.2",
    description: "1x2 HDMI DA w/ HDCP2.2, 4K Cinema resolutions & Smart EDID Control,",
    keywords: "SP-HD20-1X24K,Splitters,HDMI,kanexpro,813982020399,",
  },

  SPHD1X44K: {
    title: "4K UHD HDMI 1x4 Port Splitter ",
    description:
      "1x4 HDMI Splitter with 4K Cinema resolutions & EDID Selector,The KanexPro 1x4 HDMI Splitter is a 4K@30Hz compliant distribution amplifier which lets you duplicate one HDMI source to four displays without losing signal integrity and resolution.",
    keywords:
      "SP-HD1X44K,Splitters,HDMI,kanexpro,813982020054,Splitter, HDMI, 1x4, 4K, 1080p, DA, Distribution Amp, Duplicate, Screens, Resolution, ",
  },

  SPHD1X24K: {
    title: "4K UHD HDMI 1x2 Port Splitter ",
    description:
      "1x2 HDMI Splitter with 4K Cinema resolutions,The KanexPro 1x2 HDMI Splitter is a 4K@30Hz compliant distribution amplifier which lets you duplicate one HDMI source to two displays without losing signal integrity and resolution.",
    keywords:
      "SP-HD1X24K,Splitters,HDMI,kanexpro,813982020047,Splitter, HDMI, 1x4, 4K, 1080p, DA, Distribution Amp, Duplicate, Screens, Resolution, ",
  },

  // HDMI Professional

  SP1X4SL18G: {
    title: "UltraSlim 4K HDMI® 1X4 Splitter w/ 4:4:4 Color Space & 18G",
    description: "Professional 1x4 HDMI DA with 4K/60 + HDR 10,",
    keywords: "SP-1X4SL18G,Splitters,HDMI Professional,kanexpro,813982021167,",
  },
  SP1X2SL18G: {
    title: "4K/60 HDMI 1X2 Splitter with Downscaling to HD 1080p/60",
    description: "Professional 1x2 HDMI DA with 4K/60 + HDR 10 and 1080p,",
    keywords: "SP-1X2SL18G,Splitters,HDMI Professional,kanexpro,813982021150,",
  },

  HDSP184K: {
    title: "4K UHD 1x8 HDMI Distribution Amplifier w/ HDCP2.2",
    description: "HDMI 1x8 DA with 4K Cinema resolutions & Smart EDID Control,",
    keywords: "HDSP184K,Splitters,HDMI Professional,kanexpro,813982020207,",
  },

  HDSP164K: {
    title: "4K UHD 1x16 HDMI Distribution Amplifier w/ HDCP2.2",
    description: "HDMI 1x16 DA with HDCP2.2, 4K Cinema resolutions & Smart EDID Control,",
    keywords: "HDSP164K,Splitters,HDMI Professional,kanexpro,813982020214,",
  },

  SPHDBT1X4: {
    title: "4K HDBaseT™ 1x4 Distribution Amplifier up to 230 feet (70m)",
    description: "Broadcast 4 Identical Outputs over 230 feet (70 meters) with UltraHD 4K ,",
    keywords: "SP-HDBT1X4,Splitters,HDMI Professional,kanexpro,814556017531,",
  },

  // Switchers

  // HDMI

  SW2X14KUSBC: {
    title: "2-Input Collaboration System for Huddle Rooms",
    description: "2x1 USB-C & HDMI Auto Switcher with Video Conferencing Support,",
    keywords: "SW-2X14KUSBC,Switchers,HDMI,kanexpro,813982021969,",
  },
  SW4X1SL18G: {
    title: "UltraSlim 4K HDMI® 4x1 Switcher with 4:4:4 Color Space & 18G",
    description: "Auto switch four HDMI inputs to one with IR & RS-232 control ,",
    keywords: "SW-4X1SL18G,Switchers,HDMI,kanexpro,813982021235,",
  },
  SWHD203X14K: {
    title: "4K/60Hz HDMI 3X1 Switcher ",
    description: "Perfect for multiple HDMI 4K UHD switching ,Auto switch three HDMI sources to one 4K display",
    keywords:
      "SW-HD20-3X14K,Switchers,HDMI,kanexpro,813982020412,4K HDMI 3 inputs and 1 output 3x1 Switcher Consoles Classrooms Corporate HDCP 2.2 1.4 multiple UHD Theater room Remote IR ",
  },
  SWHD205X14K: {
    title: "4K/60Hz HDMI 5X1 Switcher",
    description: "Perfect for multiple HDMI 4K UHD switching ,Auto switch five HDMI sources to one 4K display",
    keywords:
      "SW-HD20-5X14K,Switchers,HDMI,kanexpro,813982020429,4K HDMI 5 inputs and 1 output 5x1 Switcher Consoles Classrooms Corporate HDCP 2.2 1.4 multiple UHD Theater room Remote IR ",
  },

  SWHD3X14K: {
    title: "3x1 HDMI Switcher with 4K Support",
    description: "Perfect for Home Theater which requires multiple switching ,3x1 HDMI Switcher with 4K Support",
    keywords:
      "SW-HD3X14K,Switchers,HDMI,kanexpro,813982020009,HDMI, Switch, Switcher, 4K, UHD, HDCP, High Speed, 1080p, 720p, video, professional, gaming system, console, inputs, multiple",
  },

  SWHD5X14K: {
    title: "5x1 HDMI Switcher with 4K Support ",
    description: "Perfect for Home Theater which requires multiple switching ,",
    keywords: "SW-HD5X14K,Switchers,HDMI,kanexpro,813982020016,",
  },

  SWHD4X1AUD4K: {
    title: "4x1 HDMI Switcher with Digital Audio & 4K ",
    description: "Perfect for Home Theater which requires 4 HDMI switching ,",
    keywords: "SW-HD4X1AUD4K,Switchers,HDMI,kanexpro,813982020023,",
  },

  // KVM
  SWHDSC4X1KVM: {
    title: "KanexPro 4K Quad Multiview 4X1 KVM Switch ",
    description: "Seamless Switching HDMI 2.0 4K/60Hz HDR with USB 3.0,",
    keywords: "SW-HDSC4X1KVM,Switchers,KVM,kanexpro,813982022096,",
  },
  SW4X1KVMMV: {
    title: "KanexPro Multiview 4X1 KVM Switch ",
    description: "Seamless Switching HDMI 1080p/60Hz with USB 3.0,",
    keywords: "SW-4X1KVMMV,Switchers,KVM,kanexpro,813982022089,",
  },

  // Matrix
  "MMX-4X4CAT-150M": {
    title: "HDMI HDBaseT Matrix 4X4 CAT6 150M: Boost Your AV Signal Reach",
    description: "Explore how HDMI HDBaseT Matrix 4X4 over CAT6 extends AV up to 150M for seamless streaming and high-quality display connections."
  },

  HDMX42A18G: {
    title: "4x2 HDMI 2.0 Matrix Switcher with Audio outputs supporting 4K/60Hz",
    description: "Cross-Switch four HDMI sources to two 4K displays and connect to separate audio amplifiers,",
    keywords: "HDMX42A-18G,Switchers,Matrix,kanexpro,813982022560,",
  },
  HDMX44A18G: {
    title: "4x4 HDMI 2.0 Matrix Switcher with Audio outputs supporting 4K/60Hz",
    description:
      "Cross-Switch four HDMI sources to four 4K displays and connect to separate audio amplifiers,Cross-Switch four HDMI sources to four 4K displays and connect to separate audio amplifiers",
    keywords:
      "HDMX44A-18G,Switchers,Matrix,kanexpro,813982022546,HDMI Matrix Switcher 1080p 4K Telnet EDID RS-232 RS232 HDMI 2.0 DVI 1.0 60Hz HDR Switch Deep Color HD Audio Analog Audio Stereo ",
  },
  HDMX88A18G: {
    title: "8x8 HDMI 2.0 Matrix Switcher with Audio outputs supporting 4K/60Hz",
    description: "Cross-Switch eight HDMI sources to eight 4K displays and connect to separate audio amplifiers,",
    keywords: "HDMX88A-18G,Switchers,Matrix,kanexpro,813982022553,",
  },
  MXHDBT8X818G: {
    title: "4K/60 HDBaseT™ 8X8 Matrix Switcher with Audio Matrix",
    description: "Cross-switch 8 HDMI to 8 Displays over HDBaseT with PoC, Separate Audio & Control ,",
    keywords: "MX-HDBT8X818G,Switchers,Matrix,kanexpro,813982021990,",
  },
  HDMX4218G: {
    title: "4x2 HDMI®2.0, 4K/60 Matrix Switcher w/ HDR10 & EDID Management ",
    description:
      "Cross switch 4-HDMI sources to 2-HDMI displays w/4K/60Hz + HDR + Control,Cross-Switch four HDMI sources to four 4K displays and connect to separate audio amplifiers",
    keywords:
      "HDMX42-18G,Switchers,Matrix,kanexpro,813982021877,HDMI Matrix Switcher 1080p 4K Telnet EDID RS-232 RS232 HDMI 2.0 DVI 1.0 60Hz HDR Switch Deep Color HD Audio Analog Audio Stereo ",
  },
  SWHDMX44CE: {
    title: "4x4 HDMI 4K/60 Matrix Switcher",
    description: "Cross switch 4-HDMI sources to 4-HDMI displays with 4K/60Hz,",
    keywords: "SW-HDMX44CE,Switchers,Matrix,kanexpro,813982021631,",
  },
  HDMX4418G: {
    title: "4x4 HDMI 2.0 Matrix Switcher with 4K/60Hz",
    description: "Cross-Switch four HDMI sources to four 4K displays with HDCP2.2,",
    keywords: "HDMX44-18G,Switchers,Matrix,kanexpro,813982021389,",
  },
  HDMX8818G: {
    title: "Ultra-Fast 8x8 HDMI Matrix Switcher with 4K/60Hz",
    description: "Cross-Switch eight HDMI sources to eight 4K displays with HDCP2.2,",
    keywords: "HDMX88-18G,Switchers,Matrix,kanexpro,813982021396,",
  },

  // Matrix Modular

  FLEXMMX32: {
    title: "4K Flexible 32 Input / Output Seamless Matrix Switcher",
    description: "4K Glitch-free modular matrix with 32 PCIe slots and HDCP 2.2,",
    keywords: "FLEX-MMX32,Switchers,Matrix Modular,kanexpro,813982021327,",
  },
  FLEXMMX16: {
    title: "4K Flexible 16 Input / Output Seamless Matrix Switcher",
    description: "4K Glitch-free modular matrix with 16 PCIe slots and HDCP 2.2,",
    keywords: "FLEX-MMX16,Switchers,Matrix Modular,kanexpro,813982020801,",
  },
  HDMMX16164K: {
    title: "4K UHD 16x16 Modular Matrix Switcher",
    description: "Field-upgradeable, Modular Matrix Chassis with IR, Ethernet & RS-232 Control,",
    keywords: "HDMMX1616-4K,Switchers,Matrix Modular,kanexpro,813982020276,",
  },
  HDMMX32324K: {
    title: "4K UHD 32x32 Modular Matrix Switcher",
    description: "Field-upgradeable, Modular Matrix Chassis with IR, Ethernet & RS-232 Control,",
    keywords: "HDMMX3232-4K,Switchers,Matrix Modular,kanexpro,813982020283,",
  },
  HDMMX64644K: {
    title: "4K UHD 64x64 Modular Matrix Switcher",
    description: "Field-upgradeable, Modular Matrix Chassis with IR, Ethernet & RS-232 Control,",
    keywords: "HDMMX6464-4K,Switchers,Matrix Modular,kanexpro,813982020290,",
  },

  // Matrix Modules

  FLEXINVGA: {
    title: "Flexible One Input VGA card with Audio ",
    description: "Seamless one input VGA card with audio input,",
    keywords: "FLEX-IN-VGA,Switchers,Matrix Modules,kanexpro,813982020726,",
  },
  FLEXOUTVGA: {
    title: "Flexible One Output VGA card with Audio ",
    description: "Seamless one output VGA card with PCM audio,",
    keywords: "FLEX-OUT-VGA,Switchers,Matrix Modules,kanexpro,813982020702,",
  },
  FLEXINSDI: {
    title: "Flexible One Input SDI card with Loop out  ",
    description: "Seamless one input HD-SDI card with loop out ,",
    keywords: "FLEX-IN-SDI,Switchers,Matrix Modules,kanexpro,813982020733,",
  },
  FLEXINDVI: {
    title: "Flexible One Input DVI card with Audio ",
    description: "Seamless one input DVI card with embedded PCM audio,",
    keywords: "FLEX-IN-DVI,Switchers,Matrix Modules,kanexpro,813982020740,",
  },
  FLEXOUTDVI: {
    title: "Flexible One Output DVI card with Audio ",
    description: "Seamless one output DVI card with de-embedded PCM audio,",
    keywords: "FLEX-OUT-DVI,Switchers,Matrix Modules,kanexpro,813982020719,",
  },
  FLEXINHD4K: {
    title: "Flexible One Input 4K HDMI card with Audio ",
    description: "Seamless one input 4K HDMI card with embedded PCM audio,",
    keywords: "FLEX-IN-HD4K,Switchers,Matrix Modules,kanexpro,813982020757,",
  },
  FLEXOUTHD4K: {
    title: "Flexible One Output 4K HDMI card with Audio ",
    description: "Seamless one output 4K HDMI card with de-embedded PCM audio,",
    keywords: "FLEX-OUT-HD4K,Switchers,Matrix Modules,kanexpro,813982020764,",
  },
  FLEXINHDBT4K: {
    title: "Flexible One Input 4K HDBaseT card with Audio ",
    description: "Seamless one input HDBaseT card with embedded PCM audio,",
    keywords: "FLEX-IN-HDBT4K,Switchers,Matrix Modules,kanexpro,813982020771,",
  },
  FLEXOUTHDBT4K: {
    title: "Flexible One Output 4K HDBaseT card with Audio ",
    description: "Seamless one output HDBaseT card with de-embedded PCM audio,",
    keywords: "FLEX-OUT-HDBT4K,Switchers,Matrix Modules,kanexpro,813982020788,",
  },
  FLEXINHD: {
    title: "Flexible One Input HDMI 1080p card with Audio",
    description: "Seamless one input HDMI card with embedded PCM audio,",
    keywords: "FLEX-IN-HD,Switchers,Matrix Modules,kanexpro,813982020665,",
  },
  FLEXOUTHD: {
    title: "Flexible One Output HDMI 1080p card with Audio",
    description: "Seamless one output HDMI card with de-embedded PCM audio,",
    keywords: "FLEX-OUT-HD,Switchers,Matrix Modules,kanexpro,813982020672,",
  },
  FLEXINHDBT: {
    title: "Flexible One Input HDBaseT 1080p card with Audio",
    description: "Seamless one input HDBaseT card with embedded PCM audio,",
    keywords: "FLEX-IN-HDBT,Switchers,Matrix Modules,kanexpro,813982020689,",
  },
  FLEXOUTHDBT: {
    title: "Flexible One Output HDBaseT 1080p card with Audio",
    description: "Seamless one output HDBaseT card with de-embedded PCM audio,",
    keywords: "FLEX-OUT-HDBT,Switchers,Matrix Modules,kanexpro,813982020696,",
  },
  MODINCAT64K: {
    title: "4-Input HDBaseT card for Modular matrix with 4K ",
    description: "Add 4-Input HDBaseT Card w/ 4K UHD to an MMX Switcher,",
    keywords: "MOD-IN-CAT6-4K,Switchers,Matrix Modules,kanexpro,813982020306,",
  },
  MODOUTCAT64K: {
    title: "4-Output HDBaseT card for Modular matrix with 4K",
    description: "Add 4-Output HDBaseT Card w/ 4K UHD to an MMX Switcher,",
    keywords: "MOD-OUT-CAT6-4K,Switchers,Matrix Modules,kanexpro,813982020313,",
  },
  MODINHDTV4K: {
    title: "4-Input HDMI card for Modular matrix with 4K",
    description: "Add 4-Input HDMI Card w/ 4K UHD to an MMX Switcher,",
    keywords: "MOD-IN-HDTV-4K,Switchers,Matrix Modules,kanexpro,813982020320,",
  },
  MODOUTHDTV4K: {
    title: "4-Output HDMI card for Modular matrix with 4K",
    description: "Add 4-Output HDMI Card w/ 4K UHD on the output slot of MMX Switcher,",
    keywords: "MOD-OUT-HDTV-4K,Switchers,Matrix Modules,kanexpro,813982020337,",
  },
  MODINFIBER: {
    title: "4-Input Fiber Optic card for Modular matrix ",
    description: "Add 4K 4 Fiber Optic inputs to Modular Matrix Switcher ,",
    keywords: "MOD-IN-FIBER,Switchers,Matrix Modules,kanexpro,814556015209,",
  },
  MODOUTFIBER: {
    title: "4-Output Fiber card to Modular matrix with 4K",
    description: "Add Four Output Fiber cards to remote displays ,",
    keywords: "MOD-OUT-FIBER,Switchers,Matrix Modules,kanexpro,813982020351,",
  },
  MODOTVGA: {
    title: "4-Output VGA card for Modular matrix ",
    description: "Add 4 VGA outputs to Modular Matrix Switcher ,",
    keywords: "MOD-OT-VGA,Switchers,Matrix Modules,kanexpro,814556015704,",
  },
  MODOTDVI: {
    title: "4-Output DVI card for Modular matrix ",
    description: "Add 4 DVI outputs to Modular Matrix Switcher ,",
    keywords: "MOD-OT-DVI,Switchers,Matrix Modules,kanexpro,814556015247,",
  },
  MODINDVI: {
    title: "4-Input DVI card for Modular matrix ",
    description: "Add 4 DVI inputs to Modular Matrix Switcher ,",
    keywords: "MOD-IN-DVI,Switchers,Matrix Modules,kanexpro,814556015254,",
  },
  MODINVGA: {
    title: "4-Input VGA card with Audio for Modular matrix",
    description: "Add 4 VGA inputs with stereo audio to the MMX Switcher,",
    keywords: "MOD-IN-VGA,Switchers,Matrix Modules,kanexpro,814556015261,",
  },
  MODINSDI: {
    title: "4-Input SDI card for Modular matrix",
    description: "Add 4 SDI inputs to Modular Matrix Switcher ,",
    keywords: "MOD-IN-SDI,Switchers,Matrix Modules,kanexpro,814556015285,",
  },
  MODOTSDI: {
    title: "4-Output SDI card for Modular matrix",
    description: "Add 4 SDI outputs to Modular Matrix Switcher ,",
    keywords: "MOD-OT-SDI,Switchers,Matrix Modules,kanexpro,814556015179,",
  },
  //  MODINFIBER: {
  //    title: "4-Input Fiber Optic card for Modular matrix ",
  //    description: "Add 4K 4 Fiber Optic inputs to Modular Matrix Switcher ,",
  //    keywords: "MOD-IN-FIBER,Switchers,Matrix Modules,kanexpro,814556015209,",
  //  },
  MODOTFIBER: {
    title: "4-Output Fiber Optic card for Modular matrix ",
    description: "Add 4 Fiber Optic outputs to Modular Matrix Switcher ,",
    keywords: "MOD-OT-FIBER,Switchers,Matrix Modules,kanexpro,814556015216,",
  },

  // Scaler

  SWHDSC42D4K: {
    title: "KanexPro 4K Seamless Presentation Matrix Featuring Premium Extender and Receiver ",
    description: "Auto Switch & Scale HDMI and USB Type-C inputs to two 4K displays w/ Built-in Mic Audio & Control ,",
    keywords: "SW-HDSC42D4K,Switchers,Scaler,kanexpro,813982022034,",
  },
  HDSC31D4K: {
    title: "3-Input Huddle Room Switcher over HDBaseT 4K ",
    description: "Auto Switch & Scale Three inputs over CAT6 up to 230 feet (70m),",
    keywords: "HDSC31D-4K,Switchers,Scaler,kanexpro,813982020566,",
  },
  SWHDSC51HDBT: {
    title: "5-Input HDBaseT™ Seamless Presentation Switcher & Scaler ",
    description: "Fast Switch & Scale Five inputs on to 2 mirrored displays,",
    keywords: "SW-HDSC51HDBT ,Switchers,Scaler,kanexpro,813982021402 ,",
  },
  HDSC61D4K: {
    title: "6-Input Collaboration Switcher & Scaler",
    description: "Switch & Scale Six inputs to one 4K Display,",
    keywords: "HDSC61D-4K,Switchers,Scaler,kanexpro,813982020221,",
  },

  VWSP1X44K: {
    title: "1×4 HDMI 4K UHD Video Wall Processor with Bezel Correction ",
    description: "Create Video Walls from 2x2, 2x3 & 3x3 screens up to 9-Displays,",
    keywords: "VW-SP1X44K,Switchers,Scaler,kanexpro,813982021594,",
  },

  HDSC71D4K: {
    title: "7-Input 4K Presentation Scaler Switcher w/ Audio Breakaway ",
    description: "4K/60 System Switcher with Up / Down Scaler & Audio Breakaway ,",
    keywords: "HDSC71D-4K,Switchers,Scaler,kanexpro,813982020603,",
  },

  SWHDSC914K: {
    title: "9-Input 4K System Switcher & Scaler w/ 4K HDBaseT Input/ Output",
    description: "Auto Switch & Scale 9-inputs to two 4K displays w/ Built-in Mic audio & Control ,",
    keywords: "SW-HDSC914K,Switchers,Scaler,kanexpro,813982021563,",
  },

  HDVTSC724K: {
    title: "4K Video Tiler & Scaler Switcher w/ HDMI &  Click-to-Show Me controller",
    description: "4K Presentation System with Click-to-Show me controller and Scaling Processor ,",
    keywords: "HD-VTSC72-4K,Switchers,Scaler,kanexpro,813982020634,",
  },

  HDSC51HDBT: {
    title: "HDBaseT™ Presentation Switcher & Scaler - Discontinued ",
    description: "Switch & Scale Five inputs on to 2 mirrored displays,",
    keywords: "HDSC51HDBT,Switchers,Scaler,kanexpro,814556016626,",
  },
};
