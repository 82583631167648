import React, { useState, useEffect, useRef } from "react";
import { Helmet } from "react-helmet";
import "./product.scss";
import ProductDisplay from "./ProductDisplay";
import ProductDetails from "./product-details";
import Sidebar from "../products/Sidebar";
import { connect } from "react-redux";
import { getProductDetails, setLoadingTrue, emptyProductDetails } from "../../actions/products";
import { products } from "../common/partials/navData";
import { itemData } from "./itemData";
import LoaderPrimary from "../../ui/loader/LoaderPrimary";

const Product = (props) => {
  const {
    productsMenu,
    productDisplay,
    productDiagram,
    productPanel,
    productDownload,
    getProductDetails,
    productStocks,
    setLoadingTrue,
    loading,
    location,
    emptyProductDetails,
  } = props;

  const [activeBar, setActiveBar] = useState("overview");
  const inquireRef = useRef(null);

  const skuId = location.pathname.replace("/item/", "");
  const skuIdWithoutHyphen = skuId.includes("-") ? skuId.replace(/-/g, "") : skuId;

  useEffect(() => {
    getProductDetails(skuId);

    return () => {
      emptyProductDetails();
      setLoadingTrue();
    };
  }, []);

  const path =
    productDisplay[0] &&
    (productDisplay[0].kproparent.toLowerCase() === "cabling" ? "cables" : productDisplay[0].kproparent.toLowerCase());

  const kproChildPath =
    productDisplay[0] &&
    (productDisplay[0].kprochild.includes(" ")
      ? productDisplay[0].kprochild.replace(" ", "-").toLowerCase()
      : productDisplay[0].kprochild.toLowerCase());

  const subPath = productDisplay[0] && `${path}/${kproChildPath}`;

  if (productDisplay.length === 0 || loading) {
    return <LoaderPrimary />;
  }

  return (
    <>
      <Helmet>
        <title>{itemData[skuIdWithoutHyphen] ? itemData[skuIdWithoutHyphen].title : ""}</title>
        <meta
          name="description"
          content={
            itemData[skuIdWithoutHyphen]
              ? itemData[skuIdWithoutHyphen].description
              : "Professional Audio Video components"
          }
        ></meta>
        <meta
          name="keywords"
          content={itemData[skuIdWithoutHyphen] ? itemData[skuIdWithoutHyphen].keywords : "kanexpro"}
        ></meta>
      </Helmet>
      <div className="product-container">
        <div className="items">
          <div className="d-none d-lg-block d-xl-block">
            {/* <Sidebar data={products} /> */}
            <Sidebar path={`/${path}`} subPath={`/${subPath}`} data={products} />
          </div>
          <div className="product-display">
            <ProductDisplay
              data={productDisplay[0]}
              stock={productStocks[0]}
              activeBar={activeBar}
              setActiveBar={setActiveBar}
              inquireRef={inquireRef}
            />
            <ProductDetails
              data={productDisplay[0]}
              productDiagram={productDiagram}
              productPanel={productPanel}
              productDownload={productDownload}
              location={location}
              activeBar={activeBar}
              setActiveBar={setActiveBar}
              inquireRef={inquireRef}
            />
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    productDisplay: state.data.productDetails.refItemDetail ? state.data.productDetails.refItemDetail : [],
    productDiagram: state.data.productDetails.refDiagram ? state.data.productDetails.refDiagram : [],
    productPanel: state.data.productDetails.refPanel ? state.data.productDetails.refPanel : [],
    productDownload: state.data.productDetails.refDownloads ? state.data.productDetails.refDownloads : [],
    // productDiagram: state.data.refDiagram ? state.data.refDiagram : [],
    productStocks: state.data.productDetails.refStocks ? state.data.productDetails.refStocks : [],
    productsMenu: state.data.productsMenu ? state.data.productsMenu : [],
    loading: state.data.loading,
  };
};

export default connect(mapStateToProps, { getProductDetails, setLoadingTrue, emptyProductDetails })(Product);
