import React, { useState } from "react";
import CreditCard from "./CreditCard";
import "./checkout.scss";
import ListFreePrimary from "../../ui/list/ListFreePrimary";
import {
  cardNumberValidation,
  cardMonthValidation,
  cardYearValidation,
  cardCvvValidation,
} from "./creditCardValidation";

const Checkout = () => {
  const [cardData, setCardData] = useState({
    number: "",
    name: "",
    month: "",
    year: "",
    cvv: "",
  });

  const [errorMessage, setErrorMessage] = useState({
    numberError: "",
    monthError: "",
    yearError: "",
    cvvError: "",
  });

  const [rotateCard, setRotateCard] = useState(false);

  const onChange = (e) => {
    setErrorMessage({
      numberError: "",
      monthError: "",
      yearError: "",
      cvvError: "",
    });

    if (e.target.name === "number") {
      e.target.value.length <= 16 &&
        !isNaN(Number(e.target.value)) &&
        !e.target.value.includes(" ") &&
        setCardData({ ...cardData, [e.target.name]: e.target.value });
    }
    if (e.target.name === "name") {
      setCardData({ ...cardData, [e.target.name]: e.target.value });
    }
    if (e.target.name === "month") {
      e.target.value.length <= 2 &&
        e.target.value <= 12 &&
        !e.target.value.includes(" ") &&
        !isNaN(Number(e.target.value)) &&
        setCardData({ ...cardData, [e.target.name]: e.target.value });
    }
    if (e.target.name === "year") {
      e.target.value.length <= 4 &&
        e.target.value <= 3000 &&
        !e.target.value.includes(" ") &&
        !isNaN(Number(e.target.value)) &&
        setCardData({ ...cardData, [e.target.name]: e.target.value });
    }
    if (e.target.name === "cvv") {
      e.target.value.length <= 4 &&
        !e.target.value.includes(" ") &&
        !isNaN(Number(e.target.value)) &&
        setCardData({ ...cardData, [e.target.name]: e.target.value });
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (cardNumberValidation(cardData.number, "MasterCard")) {
      // do something
    } else {
      setErrorMessage({ ...errorMessage, numberError: "Invalid card number" });
      return;
    }

    if (cardMonthValidation(cardData.month)) {
      // do something
    } else {
      setErrorMessage({ ...errorMessage, monthError: "Invalid month" });
      return;
    }

    if (cardYearValidation(cardData.year)) {
      // do something
    } else {
      setErrorMessage({ ...errorMessage, yearError: "Invalid year" });
      return;
    }

    if (cardCvvValidation(cardData.cvv)) {
      // do something
    } else {
      setErrorMessage({ ...errorMessage, cvvError: "Invalid CVV" });
      return;
    }
  };
  return (
    <div className="checkout">
      <h1 className="display-4">Checkout</h1>
      <div className="row">
        <div className="col-lg-8">
          <ListFreePrimary customClass="background-grey mb-3">
            <form onSubmit={onSubmit}>
              <div className="row">
                <div className="col-lg-6">
                  <div className="form-group">
                    <label for="exampleInputCardNo">Card Number</label>
                    <input
                      type="text"
                      name="number"
                      value={cardData.number}
                      className="form-control"
                      id="exampleInputCardNo"
                      placeholder="Should be 16 digits"
                      onChange={onChange}
                    />
                    {errorMessage.numberError && <p className="invalid-data">{errorMessage.numberError}</p>}
                  </div>
                </div>
                <div className="col-lg-6">
                  <div class="form-group">
                    <label for="exampleInputName">Card Holder Name</label>
                    <input
                      type="text"
                      name="name"
                      value={cardData.name}
                      className="form-control"
                      id="exampleInputName"
                      placeholder="Name of the card holder"
                      onChange={onChange}
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="row">
                    <div className="col-lg-6">
                      <label for="exampleInputDate">Card Expiration Month</label>
                      <input
                        type="text"
                        name="month"
                        value={cardData.month}
                        className="form-control"
                        id="exampleInputDate"
                        placeholder="mm"
                        onChange={onChange}
                      />
                      {errorMessage.monthError && <p className="invalid-data">{errorMessage.monthError}</p>}
                    </div>
                    <div className="col-lg-6">
                      <label for="exampleInputDate">Card Expiration Year</label>
                      <input
                        type="text"
                        name="year"
                        value={cardData.year}
                        className="form-control"
                        id="exampleInputDate"
                        placeholder="yyyy"
                        onChange={onChange}
                      />
                      {errorMessage.yearError && <p className="invalid-data">{errorMessage.yearError}</p>}
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div class="form-group">
                    <label for="exampleInputCvv">CVV</label>
                    <input
                      type="password"
                      name="cvv"
                      value={cardData.cvv}
                      onFocus={() => setRotateCard(true)}
                      onBlur={() => setRotateCard(false)}
                      className="form-control"
                      id="exampleInputCvv"
                      placeholder="Card CVV no"
                      onChange={onChange}
                    />
                    {errorMessage.cvvError && <p className="invalid-data">{errorMessage.cvvError}</p>}
                  </div>
                </div>
              </div>

              <button type="submit" class="btn btn-secondary">
                Place Order
              </button>
            </form>
          </ListFreePrimary>
        </div>
        <div className="col-lg-4 d-flex flex-column align-items-center">
          <CreditCard value={cardData} rotateCard={rotateCard} />
        </div>
      </div>
    </div>
  );
};

export default Checkout;
