import React from "react";
import { Helmet } from "react-helmet";
import { sales } from "../../common/partials/navData";
import SidebarSecondary from "../../common/sidebar/SidebarSecondary";
import SaleInquiry from "./SaleInquiry";
import "./SaleInquiry.scss";

export default function Sale_Inquiry({ location }) {
  return (
    <>
      <Helmet>
        <title>Sale's Inquiries</title>
        <meta name="description" content="Professional Audio Video components"></meta>
        <meta name="keywords" content="kanexpro"></meta>
      </Helmet>
      <div className="d-flex">
        <div className="d-none d-lg-block d-xl-block">
          <SidebarSecondary path={location.pathname} data={sales} title="Sales" />
        </div>

        <div className="sales-inquiry">
          <h1 class="display-4">Sale's Inquiry</h1>
          <div className="info-container">
            <p>
              Thank you for your interest in our products and services. Please complete the form below if you are
              interested in opening an account where you can access up to date pricing, real time inventory, online
              ordering, order tracking and access to our API services. We welcome resellers, AV pros, government,
              healthcare etc.
            </p>
            <p>Our trained staff will contact you to discuss your interests and answer any questions you may have.</p>
          </div>
          <SaleInquiry />
        </div>
      </div>
    </>
  );
}
