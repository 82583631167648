import React from "react";
import { Helmet } from "react-helmet";
import { about } from "../common/partials/navData";
import SidebarSecondary from "../common/sidebar/SidebarSecondary";
import Media from "./Media";
import "./Media.css";

export default function NewsMedia({ location }) {
  return (
    <>
      <Helmet>
        <title>Media</title>
        <meta name="description" content="Professional Audio Video components"></meta>
        <meta name="keywords" content="kanexpro"></meta>
      </Helmet>
      <div className="d-flex">
        <div className="d-none d-lg-block d-xl-block">
          <SidebarSecondary path={location.pathname} data={about} title="About" />
        </div>

        <div className="news-media">
          <Media />
        </div>
      </div>
    </>
  );
}
