import React from 'react';
import AvSol from './AvSol';
import "./AvSol.css"

export default function Avsolution({location}){
    return(
        <>
   
   <div className="row">

    
     <AvSol/>
     </div>
   
   </>
    )}