import React, { useRef } from "react";
import { Helmet } from "react-helmet";
import ListFreePrimary from "../../../ui/list/ListFreePrimary";
import { account } from "../../common/partials/navData";
import SidebarSecondary from "../../common/sidebar/SidebarSecondary";
import "./invoices.scss";
import { Link } from "react-router-dom";
import InputSearchSecondary from "../../../ui/input/InputSearchSecondary";
import { connect } from "react-redux";

const Invoices = ({ location, history, auth }) => {
  if (!auth.isAuthenticated) {
    history.push("/");
  }

  const scrollRef = useRef();
  const scrollTable = (direction, tableScrollRef) => {
    if (direction === "left") {
      tableScrollRef.current.scrollLeft -= 100;
    }
    if (direction === "right") {
      tableScrollRef.current.scrollLeft += 100;
    }
  };

  return (
    <>
      <Helmet>
        <title>Invoices</title>
        <meta name="description" content="Professional Audio Video components"></meta>
        <meta name="keywords" content="kanexpro"></meta>
      </Helmet>
      <div className="d-flex">
        <div className="d-none d-lg-block d-xl-block">
          <SidebarSecondary path={location.pathname} data={account} title="Account" />
        </div>

        <div className="invoices">
          <h1 className="display-4">Order Details</h1>
          <p className="lead mb-1">
            <b>Order number: </b>#21194
          </p>
          <div className="bill-ship-container mb-3">
            <ListFreePrimary customClass="bill">
              <p className="lead fs-m">Bill to</p>
              <hr />

              <p>
                <b>Address: </b>Duis ut tincidunt metus. Duis cursus mollis posuere. Maecenas a dolor non nisl mollis
                luctus et in mauris.
              </p>
              <p>
                <b>City: </b>New York City
              </p>
              <p>
                <b>State: </b>NY
              </p>
            </ListFreePrimary>
            <ListFreePrimary customClass="ship">
              <p className="lead fs-m">Ship to</p>
              <hr />
              <p>
                <b>Address: </b>Duis ut tincidunt metus. Duis cursus mollis posuere. Maecenas a dolor non nisl mollis
                luctus et in mauris.
              </p>
              <p>
                <b>City: </b>New York City
              </p>
              <p>
                <b>State: </b>NY
              </p>
            </ListFreePrimary>
          </div>
          <ListFreePrimary>
            <p className="lead fs-m">Items</p>
            <hr />
            <InputSearchSecondary customClass="mb-2" />
            <span>
              <span className="scroll-btn" onClick={() => scrollTable("left", scrollRef)}>
                ❮
              </span>{" "}
              Scroll{" "}
              <span className="scroll-btn" onClick={() => scrollTable("right", scrollRef)}>
                ❯
              </span>
            </span>

            <div ref={scrollRef} className="scroll-x">
              <table className="table">
                <thead className="row-brown">
                  <tr>
                    <th scope="col">Sku</th>
                    <th scope="col">Descriptiion</th>
                    <th scope="col">Qty</th>
                    <th scope="col">Open</th>
                    <th scope="col">Ship From</th>
                    <th scope="col">Price</th>
                    <th scope="col">Total</th>
                  </tr>
                </thead>

                <tbody>
                  <tr>
                    <td>
                      <Link to="/account/order/orderId">Product</Link>
                    </td>
                    <td>Descriptiion</td>
                    <td>@mdo</td>
                    <td>@mdo</td>
                    <td>test</td>
                    <td>@mdo</td>
                    <td>@mdo</td>
                  </tr>

                  <tr>
                    <td>
                      <Link to="/account/order/orderId">Product</Link>
                    </td>
                    <td>Descriptiion</td>
                    <td>@mdo</td>
                    <td>test</td>
                    <td>@mdo</td>
                    <td>@mdo</td>
                    <td>@mdo</td>
                  </tr>
                  <tr>
                    <td>
                      <Link to="/account/order/orderId">Product</Link>
                    </td>
                    <td>Descriptiion</td>
                    <td>@mdo</td>
                    <td>@mdo</td>
                    <td>test</td>
                    <td>@mdo</td>
                    <td>@mdo</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </ListFreePrimary>
        </div>
      </div>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};
export default connect(mapStateToProps)(Invoices);
