import { combineReducers } from "redux";

// Import custom components
import productReducer from "./products";
import authReducer from "./auth";
import stylesReducer from "./styles";
import modalReducer from "./modal";
import wishlistReducer from "./wishlist";
import accountReducer from "./account";
import cartReducer from "./cart";

const rootReducer = combineReducers({
  data: productReducer,
  modal: modalReducer,
  wishlist: wishlistReducer,
  auth: authReducer,
  styles: stylesReducer,
  account: accountReducer,
  cart: cartReducer,
});

export default rootReducer;
