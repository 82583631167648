export const contactEmail = {
  support: {
    email: "support@kanexpro.com",
    subject: "sub",
  },
  dealer: {
    email: "sales@kanexpro.com",
    subject: "KanexPro Contact Inquiry",
  },
  sales: {
    email: "sales@kanexpro.com",
    subject: "KanexPro - New  & clienttype",
  },
  press: {
    email: "press@kanexpro.com",
    subject: "KanexPro Press Inquiries",
  },
  newsletter: {
    email: "press@kanexpro.com",
    subject: "KanexPro Press Inquiries",
  },
};
