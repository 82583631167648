import * as types from "../constants/action-types";
import axios from "../api/index";
import { toast } from "react-toastify";
import { emptyCart } from "./products";

export const loadUser = () => async (dispatch) => {
  const token = localStorage.getItem("token");
  if (token) {
    dispatch({
      type: types.USER_LOADED,
    });
  } else {
    dispatch({
      type: types.USER_ERROR,
      payload: "User not found",
    });
  }
};

export const userLogin = (email, password, history) => async (dispatch) => {
  const body = {
    Email: email,
    Password: password,
  };

  try {
    const res = await axios.post("/Login", body);
    dispatch({ type: types.LOGIN, payload: res.data });

    dispatch(getMyInfo(res.data.userId, res.data.token));

    toast.success("You are successfully logged in!", {
      position: "top-center",
      hideProgressBar: true,
      pauseOnHover: false,
      closeOnClick: false,
    });
    history.push("/account/info");
  } catch (error) {
    dispatch({
      type: types.USER_ERROR,
      payload: "Invalid  username or password",
    });
    toast.error("Invalid username or password", {
      position: "top-center",
      hideProgressBar: true,
      pauseOnHover: false,
      closeOnClick: false,
    });
  }
};

export const getMyInfo = (userId, token) => async (dispatch, getState) => {
  try {
    const res = await axios.post(
      "/api/Account/ParseProperties",
      {
        UserId: userId,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    dispatch({ type: types.USER_INFO, payload: res.data.ProfileSettings[0] });
  } catch (error) {
    dispatch({
      type: types.USER_ERROR,
      payload: "error",
    });
  }
};

export const userLogout = () => async (dispatch) => {
  dispatch(emptyCart());
  dispatch({ type: types.LOGOUT, payload: "" });
  toast.success("You are successfully logged out!", {
    position: "top-center",
    hideProgressBar: true,
    pauseOnHover: false,
    closeOnClick: false,
  });
};

export const changePassword = (oldPassword, newPassword, confirmPassword) => async (dispatch, getState) => {
  const accountId = getState().auth.userInfo.Account;
  const userToken = getState().auth.token;

  try {
    await axios.post(
      "/ChangePassword",
      {
        CurrentPassword: oldPassword,
        NewPassword: newPassword,
        ConfirmPassword: confirmPassword,
        UserId: accountId,
      },
      {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userToken}`,
      }
    );

    dispatch({ type: types.FORM_STATUS, payload: 200 });
    setTimeout(() => {
      dispatch(userLogout());
    }, 5000);
  } catch (error) {
    dispatch({
      type: types.FORM_STATUS,
      payload: error.response.status,
    });
  }
};

export const clearError = () => (dispatch) => {
  dispatch({ type: types.CLEAR_ERROR });
};

export const userImpLogin = (username, history) => async (dispatch) => {
  try {
    const res = await axios.get("/ImpersonateUser", { params: { username } });
    dispatch({ type: types.LOGIN, payload: res.data });

    dispatch(getMyInfo(res.data.userId, res.data.token));

    toast.success("You are successfully logged in!", {
      position: "top-center",
      hideProgressBar: true,
      pauseOnHover: false,
      closeOnClick: false,
    });

    history.push("/account/info");
  } catch (error) {
    dispatch({
      type: types.USER_ERROR,
      payload: "Invalid  username",
    });
    toast.error("Invalid username", {
      position: "top-center",
      hideProgressBar: true,
      pauseOnHover: false,
      closeOnClick: false,
    });
  }
};
