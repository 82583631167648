import React, { useEffect } from "react";
import DOMPurify from 'dompurify'
import { Link } from "react-router-dom";
import { getNewsdetails, setNewsLoadingTrue } from "../../../actions/products";
import { connect } from "react-redux";
import LoaderPrimary from "../../../ui/loader/LoaderPrimary";

const MediaDescrp = (props) => {
  const { newsdetails } = props;
  useEffect(() => {
    props.getNewsdetails(props.match.params.Id);
    return () => {
      props.setNewsLoadingTrue();
    };
  }, []);
  if (props.newsloading) {
    return <LoaderPrimary />;
  }
  return (
    <div>
      {newsdetails.map((newsdetails) => {
        return (
          <div className="jumbotron Mediamain">
            <h3 className="display-4">{newsdetails.title} </h3>
            {/* <p>{newsdetails.newscontent}</p> */}
            <p className="content" dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(newsdetails.newscontent)}}></p>
            <br></br>
            <p>
              Press Inquiries please <Link to="/press">click here</Link>
            </p>
          </div>
        );
      })}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    newsdetails: state.data.newsdetails.refArticleDetails ? state.data.newsdetails.refArticleDetails : [],
    newsloading: state.data.newsloading,
  };
};

export default connect(mapStateToProps, { getNewsdetails, setNewsLoadingTrue })(MediaDescrp);
