import React from "react";
import ButtonPrimary from "../button/ButtonPrimary";
import ButtonQuantity from "../button/ButtonQuantity";
import "./card-primary.scss";
import { Link } from "react-router-dom";
import LazyLoadImg from "../lazyLoadImg";

const CardPrimary = ({
  qty,
  amount,
  text,
  btnIcon,
  image,
  title,
  subtitle,
  mpn,
  link,
  a,
  stock,
  btnLink,
  onRemove,
  onDecrease,
  onIncrease,
  onChange,
  onBlur,
}) => {
  return (
    <div className="card-primary flex-md-row">
      {!link.includes(".pdf") ? (
        <Link to={link ? link : "#"}>
          <LazyLoadImg
            image={{
              src: image,
              alt: title,
              width: 250,
              height: 150,
              className: "card-img-left",
            }}
          />
        </Link>
      ) : (
        <a href={a ? a : "#"} target="_blank" rel="noreferrer">
          <LazyLoadImg
            image={{
              src: image,
              alt: title,
              width: 250,
              height: 150,
              className: "card-img-left",
            }}
          />
        </a>
      )}
      <div className="card-body w-100">
        {!link.includes(".pdf") ? (
          <Link to={link ? link : "#"}>
            <h5 className="card-title mb-1">{title ? title : "N/A"}</h5>
          </Link>
        ) : (
          <a href={a ? a : "#"} target="_blank" rel="noreferrer">
            <h5 className="card-title mb-1">{title ? title : "N/A"}</h5>
          </a>
        )}
        <p className="card-text mb-1">{subtitle ? subtitle : "N/A"}</p>
        {mpn && <p className="card-text mb-1">MPN: {mpn ? mpn : "N/A"}</p>}
        <div className="d-lg-flex d-md-flex justify-content-between align-items-center w-100">
          <div className="d-flex flex-wrap align-items-center">
            {/* <h1 className="display-4 price mb-1">
              $<span>2,000</span>
            </h1> */}
            {amount && <p className="mr-5 amount">$ {amount}</p>}
            {/* {qty && <p className="qty">Qty: {qty}</p>} */}
            {(qty || onDecrease) && (
              <ButtonQuantity
                customClass="mr-5"
                onDecrease={onDecrease}
                onIncrease={onIncrease}
                onChange={onChange}
                onBlur={onBlur}
                qty={qty}
              />
            )}
            {stock && <p className="in-stock-card">In stock : {stock}</p>}
          </div>
          {btnIcon && (
            <button onClick={onRemove} className="btn btn-primary">
              <i className={btnIcon}></i>
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default CardPrimary;
