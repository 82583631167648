import React, { useState, useEffect } from "react";
import sidebarAnimation, { slideDown, slideUp } from "./sidebarAnimation";
import "./sidebar.scss";
import { Link, NavLink } from "react-router-dom";

const Sidebar = ({ path, subPath, data }) => {
  const [searchText, setSearchText] = useState("");
  const [searchMenu, setSearchMenu] = useState([]);
  const [filteredData, setFilteredData] = useState(data);
  const [background, setBackground] = useState("");

  const menuOpenId = path ? path.substring(1) : null;

  useEffect(() => {
    sidebarAnimation(menuOpenId);
  }, []);

  // useEffect(() => {
  //   const dataCopy = JSON.parse(JSON.stringify(data));
  //   if (searchText !== "") {
  //     const newFilteredData = dataCopy
  //       .map((item) => ({
  //         ...item,
  //         subcategory: item.subcategory.filter((item) => item.name.toLowerCase().includes(searchText)),
  //       }))
  //       .filter((item) => item.subcategory.length > 0);

  //     console.log("new", newFilteredData);
  //     setFilteredData(newFilteredData);
  //   } else {
  //     setFilteredData(data);
  //   }
  // }, [searchText]);

  useEffect(() => {
    if (searchMenu.length === 0) {
      slideDown(menuOpenId);
    } else {
      searchMenu.forEach((item) => slideDown(item));
    }
  }, [searchMenu]);

  const subPathHighlight = subPath && subPath.slice(-1) === "/" ? subPath.slice(0, -1) : subPath; // value  -> /category/subcategory

  const renderMenuItems = () => {
    return filteredData.map((product, index) => (
      <li className="sidebar-dropdown" key={index}>
        <a>
          <i className="fas fa-chevron-down"></i>
          <Link
            className={`category-link ${path && path === product.path && "custom-active"} ${
              product.category.toLowerCase().includes(background.toLowerCase()) && background !== ""
                ? "custom-active font-weight-bold"
                : ""
            }`}
            to={product.path}
          >
            {product.category}
          </Link>
        </a>

        <div className="sidebar-submenu" id={product.path.substring(1)}>
          <ul>
            {product.subcategory &&
              product.subcategory.map((category, index) => (
                <Link to={category.path} key={index}>
                  <li>
                    <a
                      className={`${subPath && subPathHighlight === category.path && "custom-active"} ${
                        category.name.toLowerCase().includes(background.toLowerCase()) && background !== ""
                          ? "custom-active font-weight-bold"
                          : ""
                      }`}
                    >
                      {category.name}
                    </a>
                  </li>
                </Link>
              ))}
          </ul>
        </div>
      </li>
    ));
  };

  const onSearchMenu = (e, allowClick) => {
    if (e.key === "Enter" || allowClick) {
      setBackground(searchText);
      searchMenu.length !== 0 && searchMenu.forEach((item) => slideUp(item));

      let searchArray = [];
      searchText &&
        filteredData.forEach((product) => {
          if (
            product.category.toLowerCase().includes(searchText.toLowerCase()) ||
            searchText.toLowerCase().includes(product.category.toLowerCase())
          ) {
            searchArray.push(product.category.toLowerCase());
          }
          product.subcategory.forEach((item) => {
            if (
              item.name.toLowerCase().includes(searchText.toLowerCase()) ||
              searchText.toLowerCase().includes(item.name.toLowerCase())
            ) {
              searchArray.push(product.category.toLowerCase());
            }
          });
        });

      setSearchMenu(searchArray);
      searchArray = [];
    }
  };

  return (
    <div className="page-wrapper chiller-theme toggled">
      <nav id="sidebar" className="sidebar-wrapper">
        <div className="sidebar-content">
          <div className="sidebar-search">
            <div>
              <div className="input-group">
                <input
                  type="text"
                  className="form-control search-menu"
                  value={searchText}
                  placeholder="Search items..."
                  onChange={(e) => setSearchText(e.target.value)}
                  onKeyPress={(e) => onSearchMenu(e, false)}
                />
                <div className="input-group-append">
                  <span className="input-group-text" onClick={(e) => onSearchMenu(e, true)}>
                    <i className="fa fa-search" aria-hidden="true"></i>
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div className="sidebar-menu">
            <ul>
              <li className="header-menu">
                <span>General</span>
              </li>

              {renderMenuItems()}
              <li>
                <NavLink
                  to="/new"
                  activeStyle={{ color: "#ff671c" }}
                  // className={menuOpenId === "new" ? "custom-active" : ""}
                  style={{ color: "#004d71" }}
                >
                  <i className="fas fa-plug " style={{ color: "#76777a" }}></i>
                  <span>New Products</span>
                </NavLink>
              </li>
              {/* <li>
                <NavLink to="/avsolutions" style={{ color: "#004d71" }}>
                  <i className="fas fa-network-wired" style={{ color: "#76777a" }}></i>
                  <span>Network AV Solutions</span>
                </NavLink>
              </li> */}
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Sidebar;
