import React from "react";

import "./newsletter.scss";

const Newsletter = (props) => {
  const { onClose, onSubmit, value, onChange } = props;

  return (
    <div className="newsletter">
      <div className="bg-dark" onClick={onClose} />
      <i className="fas fa-times cross" onClick={onClose}></i>
      <form className="form" onSubmit={onSubmit}>
        <h1 className="display-center-5">Signup to our newsletter</h1>
        {/* <input type="text" placeholder="Enter your name" /> */}
        <input type="email" placeholder="Enter your email" value={value} onChange={onChange} required />
        <button className="btn btn-primary">Sign up</button>
      </form>
    </div>
  );
};

export default Newsletter;
