import React from "react";
import ListFreePrimary from "../../../ui/list/ListFreePrimary";
import FormData from "./FormData";

export default function Support() {
  return (
    <div className="TechMainContainer">
      <div class="">
        <div class="" style={{ marginBottom: "40px" }}>
          <div class="login d-flex align-items-center py-5">
            <div class="container">
              <div class="row">
                <div class="col-lg-12 col-xl-12 mx-auto text-center">
                  <h1 class="display-center-5">Fill out this form</h1>

                  <FormData />

                  {/* ---------------------------------------- 2nd end ----------------------------------------------------------------- */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
