import React, { useState, useEffect, useCallback } from "react";
import Hero from "../FlexMatrix/Hero.jpg";
import imgGovernment from "../FlexMatrix/imgGovernment.png"
import imgRestaurant from "../FlexMatrix/imgRestaurant.png"
import enterprise from "../FlexMatrix/enterprise.png"
import EmbeddedDocumentExample from '../FlexMatrix/EmbeddedDocumentExample.png'
import asburyalehouse from "../FlexMatrix/img-asbury-ale-house.png"
import products from '../FlexMatrix/Products.jpg'
import tataLogo from '../FlexMatrix/logo-taa.png'
import imgStep1 from '../FlexMatrix/img-step 1.png'
import imgStep2 from '../FlexMatrix/img-step 2.png'
import imgStep3 from '../FlexMatrix/img-step 3.png'
import imgStep4 from '../FlexMatrix/img-step 4.png'
import iconSpeed from '../FlexMatrix/icon-speed.svg'
import iconScaling from '../FlexMatrix/icon-scaling.svg'
import iconAudio from '../FlexMatrix/icon-audio.svg'
import iconCec from '../FlexMatrix/icon-cec.svg'
import iconMultiVew from '../FlexMatrix/icon-multi-vew.svg'
import iconVideoWall from '../FlexMatrix/icon-video-wall.svg'
import scrollbtn from '../FlexMatrix/button-scroll-down.svg'
import imgFlex from '../FlexMatrix/img-FLEX-MF24X60.png'
import howItWorks from "../FlexMatrix/howItWorks.png";
import Aos from "aos";
import "aos/dist/aos.css";
import axios from '../../../src/api/index.js';
import CrouselFour from "../FlexMatrix/crouselFour.js";
import { Helmet } from "react-helmet";
import Form from "../FlexMatrix/Form.jsx";
export default function Government() {
  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);
  const onButtonComponentClick = useCallback(() => {

    const anchor = document.getElementById("howItWorks");
    if (anchor) {
      anchor.scrollIntoView({ block: "start", behavior: "smooth" });
    }
  }, []);
  const onClickArrow = useCallback(() => {
    const anchor = document.getElementById("flexMatrix");
    if (anchor) {
      anchor.scrollIntoView({ block: "start", behavior: "smooth" });
    }
  }, []);
  const onLogoBarExpoClick = useCallback(() => {
    window.open("https://www.barandrestaurantexpo.com/");
  }, []);
  const onButtonDemoClick = useCallback(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
    const anchor = document.getElementById("demo");
    if (anchor) {
      anchor.scrollIntoView({ block: "start", behavior: "smooth" });
    }
  }, []);
  const onButtonLearnmoreContainerClick = useCallback(() => {
    window.open("https://kanexpro.com/casestudy");
  }, []);

  const onButtonLearnmoreContainer2Click = useCallback(() => {
    window.open("https://kanexpro.com/support");
  }, []);

  const onButtonLearnmoreContainer3Click = useCallback(() => {
    window.open("https://kanexpro.com/casestudy");
  }, []);
  const initData = { firstName_chr: '', lastName_chr: '', email_chr: '', phone_chr: '', msg_chr: '' }
  const [formData, setFormData] = useState(initData)
  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log('form data', name, value);
    if (name === 'phone_chr') {
      const onlyNums = value.replace(/[^0-9*#]/g, '');
      setFormData((state) => ({ ...state, [name]: onlyNums }))
    } else {
      setFormData((state) => ({ ...state, [name]: value }))
    }
  }
  console.log('form data', formData);
  const handleFormSubmit = async (e) => {
    e.preventDefault();
    console.log('Form submitted');
    try {
      const res = await axios.post("/api/EmailManagement/TechSupport", {
        name: `${formData.firstName_chr} ${formData.lastName_chr}`,
        email: formData.email_chr,
        phone: formData.phone_chr,
        comments: formData.msg_chr
      });
      console.log(res);
      setFormData(initData)
      // dispatch({ type: types.FORM_STATUS, payload: 200 })
    } catch (error) {
      console.log('check err', error);
      // dispatch({
      //   type: types.FORM_STATUS,
      //   payload: error.response.status,
      // });
    }
    // setFormData(initData)
  }
  return (
    <div className="">
      <Helmet>
        <title>Government</title>
        <meta name="description" content="Government"></meta>
        <meta name="keywords" content="kanexpro"></meta>
      </Helmet>
      <div className='image-container relative bg-gray-200 grid grid-cols-1 md:grid-cols-2 gap-y-32 p-8 governmentPage'>
        <div style={{ display: 'flex' }}>
          <div className="flex flex-col justify-center">
            <h1 style={{ color: "white", marginBottom: '20px' }}>Modular by Design, Seamless Integration</h1>
            <div className="HDiv2">Elevate Your AV Control</div>
            <div className="HDiv3">
              Streamline your AV setup with our modular switcher. Exceptional performance and reliability for corporate, educational, government, hospitality, and entertainment venues. See how it works with a custom demo.
            </div>
            <div className="flex items-center gap-x-16 mt-8">
              <button
                className="w-96 p-4 border rounded-full uppercase"
                style={{ height: '70px' }}
                onClick={onButtonComponentClick}
              >
                See how it works
              </button>
              <button onClick={onClickArrow}>
                <img src={scrollbtn} />
              </button>
            </div>
          </div>
        </div>


        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'bottom' }}>
          <Form />
        </div>
      </div>


      <div className="bg-white gap-8 p-16 sm:grid sm:grid-cols-1 md:grid-cols-2">
        <div className="md:pl-32 flex flex-col gap-y-8">
          <div>
            <h1 className="w-full sm:w-2/3">Why Choose KanexPro’s FLEX Matrix Switcher</h1>
            <div className="h-16 border-b-2" style={{ width: '9vw', borderColor: '#FF671E' }}></div>
            <h1 className='pt-16' style={{ color: '#c9e3ec' }}>“</h1>
            <p className="w-full sm:w-2/3">
              We needed to replace a sports bar’s ten year old 1080p HDBaseT matrix solution. We had no experience with AV over IP installations, and the 4K HDBaseT solutions were too expensive.
            </p>
            <p className="w-full sm:w-2/3">
              During an internet search, I came across KanexPro’s Flex matrix solutions, which seemed to be a perfect solution for our project. It had all the features needed for a sports bar, such as seamless switching and video wall functions, and the installation was very fast and easy, pretty much a plug-and-play. KanexPro’s Flex matrix will now be the go-to solution for our sports bar and restaurant projects.
            </p>
            <div>
              <div className="font-bold">John Smith</div>
              <div>Customer in Blank Industry</div>
            </div>
          </div>
        </div>
        <div className="md:pl-32 flex flex-col gap-y-8 md:border-l-2 pt-32">
          <div className="md:pt-48">
            <div>
              <div className="head3">TAA Compliant</div>
              <div className="w-full sm:w-2/3">
                Final products are all obtained and constructed within the
                US or designated countries to meet requirements for US government use.
              </div>
            </div>
            <div>
              <div className="head3">Reliability</div>
              <div className="w-full sm:w-2/3">
                Each FLEX Matrix product is manufactured to meet the highest standards of performance and reliability.
              </div>
            </div>
            <div>
              <div className="head3">Modularity</div>
              <div className="w-full sm:w-2/3">
                Future-proof your solution, the FLEX Matrix features options that enable you to not only meet the needs of today, but to grow with you to meet the requirements of tomorrow.
              </div>
            </div>
            <div>
              <div className="head3">Ease of ownership</div>
              <p className="w-full sm:w-2/3">
                Leverage FLEX Matrix’s reliability and simplified integration and in-house Control System.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="col-span-2 py-16 flex items-center justify-center">
        <button className="p-4 rounded-full text-white uppercase" style={{ background: '#FF671E', letterSpacing: '5px' }}
          onClick={onButtonDemoClick}>Get a Custom Demo</button>
      </div>

      <div className="bg-white">

      </div>

      <div id='flexMatrix' className="w-full bg-white grid grid-cols-1 gap-4 p-16 sm:grid sm:grid-cols-1 md:grid-cols-2">
        <div className="flex flex-col md:pl-32 gap-x-16">
          <p className="font-semibold text-6xl" style={{ letterSpacing: '2px' }}>What is the</p>
          <p className="font-semibold text-6xl text-black" style={{ letterSpacing: '2px' }}> FLEX matrix switcher</p>
          <div className="h-16 border-b-2" style={{ width: '9vw', borderColor: '#FF671E' }}></div>
          <div className="pt-32">
            <div className="mtrx1">FLEX-MF8X10|FLEX-MF16X20</div>
            <div className="mtrx1">FLEX-MF24X36|FLEX-MF24X60</div>
          </div>
        </div>
        <div className="flex flex-col gap-y-16">
          <div className="mtrx2">
            The KanexPro FLEX series matrix switcher offers four chassis sizes with up to 24 inputs and 60 outputs. You can connect all your devices, such as set-top boxes, cable boxes, streaming devices, digital signage, and other playback gadgets, and enjoy seamless and dependable video distribution.
          </div>
          <p className="mtrx2">
            The KanexPro FLEX series matrix switcher also features seamless switching speed, independent scaling output, video wall capabilities, quad view, audio extraction, and CEC display control, giving you more control and versatility in media distribution. This cost-effective solution provides an alternative to AV over IP, offering simplicity and power in one system.
          </p>
        </div>
      </div>

      <div className="outerDiv">
        <img src={products} width='100%' />
      </div>

      <div className="bg-white" style={{ display: 'flex', justifyContent: 'center', cursor: 'pointer' }} onClick={onButtonDemoClick}>
        <img src={howItWorks} />
      </div>

      <div className="grid grid-cols-3 gap-8 py-32 px-32" style={{ background: '#003E52' }}>
        <div className="col-span-3">
          <h2 className="keyFeatHead">Key features</h2>
          <div className="absolute top-210 left-142.2 w-32 h-1 bg-white"></div>
          <div className="pt-32 w-full sm:w-1/3">
            This cost-effective solution provides an alternative to AV over IP, offering simplicity and power in one system.
          </div>
        </div>
        <div className="col-span-3"></div>
        <div className="col-span-3 grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3" style={{ background: '#003E52' }}>
          <div className="keyFeatWrap">
            <img src={iconSpeed} />
            <div className="featName">Speed</div>
            <p style={{ color: '#C9E3EC', width: '270px' }}>Switching between diverse video feeds seamlessly</p>
          </div>
          <div className="keyFeatWrap">
            <img src={iconScaling} />
            <div className="featName">Scaling</div>
            <p style={{ color: '#C9E3EC', width: '270px' }}>Switching between diverse video feeds seamlessly</p>
          </div>
          <div className="keyFeatWrap">
            <img src={iconVideoWall} />
            <div className="featName">Video Walls</div>
            <p style={{ color: '#C9E3EC', width: '270px' }}>Video wall up to 12 displays (1x12, 2x6, 3x4, 4x3, 6x2, 12x1)</p>
          </div>
          <div className="keyFeatWrap">
            <img src={iconMultiVew} />
            <div className="featName">Multi view</div>
            <p style={{ color: '#C9E3EC', width: '270px' }}>Quad view allowing different channels on each screen</p>
          </div>
          <div className="keyFeatWrap">
            <img src={iconCec} />
            <div className="featName">CEC</div>
            <p style={{ color: '#C9E3EC', width: '270px' }}>Consumer Electronics Control using only one remote</p>
          </div>
          <div className="keyFeatWrap">
            <img src={iconAudio} />
            <div className="featName">Audio Extraction</div>
            <p style={{ color: '#C9E3EC', width: '270px' }}>Audio extraction enabled on all inputs</p>
          </div>
        </div>
      </div>

      {/* <LandingPageForDev /> */}
    </div>
  );
}
