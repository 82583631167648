import React, { useState } from "react";
import { Link } from "react-router-dom";

import MobileMainNav from "./partials/mobile-nav";
import "./styles.scss";

export default function MobileMenu(props) {
  const { adClass, history } = props;
  const [search, setSearch] = useState("");

  const onSearch = (e) => {
    e.preventDefault();

    if (search !== "") {
      history.push(`/search/${search}/1`);
    }
  };

  return (
    <div className={`mobile-menu-container ${adClass}`}>
      <div className="mobile-menu-wrapper">
        <span className="mobile-menu-close">
          <i className="icon-close"></i>
        </span>

        <form className="mobile-search" onSubmit={onSearch}>
          <label htmlFor="mobile-search" className="sr-only">
            Search
          </label>
          <input
            type="search"
            className="form-control"
            name="mobile-search"
            id="mobile-search"
            placeholder="Search in..."
            required
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
          <button className="btn btn-primary" type="submit">
            <i className="icon-search"></i>
          </button>
        </form>

        <nav className="mobile-nav">
          <MobileMainNav />
        </nav>

        <div className="social-icons">
          <a
            href="https://www.facebook.com/kanexpro"
            className="social-icon"
            target="_blank"
            rel="noopener noreferrer"
            title="Facebook"
          >
            <i className="icon-facebook-f"></i>
          </a>
          <a
            href="https://twitter.com/kanexpro"
            className="social-icon"
            target="_blank"
            rel="noopener noreferrer"
            title="Twitter"
          >
            <i className="icon-twitter"></i>
          </a>
          <a
            href="https://www.linkedin.com/company/kanexpro?trk=biz-companies-cym"
            className="social-icon"
            target="_blank"
            rel="noopener noreferrer"
            title="Linkedin"
          >
            <i className="icon-linkedin"></i>
          </a>
          <Link to="/contact" className="social-icon" target="_blank" rel="noopener noreferrer" title="Contact">
            <i className="fas fa-envelope"></i>
          </Link>
        </div>
      </div>
    </div>
  );
}
