import React from "react";
import "./input-search.scss";

const InputSearchSecondary = ({ customClass, value, onChange }) => {
  return (
    <div className={`sidebar-search ${customClass} input-search-secondary`}>
      <div>
        <div className="input-group">
          <input
            type="text"
            className="form-control search-menu"
            placeholder="Search items..."
            value={value}
            onChange={onChange}
          />
          <div className="input-group-append">
            <span className="input-group-text">
              <i className="fa fa-search" aria-hidden="true"></i>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InputSearchSecondary;
