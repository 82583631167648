import React, { useState,useEffect } from "react";
import {getNews} from "../../actions/products";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import NewsAccordion from "./NewsMedia Accordion/NewsAccordion";
import "./Media.css";
import { MediaData } from "./MediaData";
import Mypagination from "./Mypagination";


const  Media = (props) => {
  const { news} = props;

  useEffect(() => {
    props.getNews();

  }, [])

  const [showperpage,setShowperpage]=useState(4)

  const [pagination,setPagination]=useState({
    start:0,
    end:showperpage
  })
  const onPaginationChange =(start,end)=>{
    setPagination({start:start,end:end})
  }
  return (
    <div>
      {/* <div className="">
                <div className="firstDiv  justify-content-center">
                    <div className="col-lg-8 col-sm-12">
                        <h1 className="display-1">News</h1>
                        <p className="lead-1">Lorem ipsum dolor sit amet consectetur adipisicing elit. Praesentium inventore nemo numquam, expedita repudiandae dignissimos quisquam non explicabo sequi consequatur labore doloremque molestiae, nisi ipsam dolorum dicta blanditiis laboriosam quae autem fugiat voluptatum! Rerum, veniam? Blanditiis maiores consequuntur assumenda magni necessitatibus! Voluptatibus quos quidem pariatur porro dignissimos delectus fugit iusto.                        </p>
                    </div>
                </div>
            </div> */}

      <div className=" News ">
     
          <>
            <h1 className="display-4" style={{marginBottom:'1px'}}>News</h1>
           
             {news.slice(pagination.start,pagination.end).map((item,index)=>{
               const newspath = item.newslink.replace("?id=","")
               const newstime = item.newsdate.replace("T00:00:00","")
               return   <div className="Newsaccordion-primary-tabs"  style={{marginTop:'40px'}}>
               <div className="Newsaccordion-primary-tab">
                 <input className="Newsaccordion-primary-input" type="checkbox" checked="true" id={item.id} />
                 <label className="Newsaccordion-primary-tab-label" for={item.id}>
                 <Link className="NewsTitle"  to={newspath}> {newstime} : {item.title}</Link> 
                 </label>
                 <hr />
                 <div class="Newsaccordion-primary-tab-content">{item.teaser}</div>
               </div>
             </div>
             })}
        
              <Mypagination
               showperpage={showperpage} 
               onPaginationChange={ onPaginationChange }
               total ={news.length}
               />

           
          </>
        
      </div>
    </div>
  ); 
}

const mapStateToProps = (state) => {
  return {
    news: state.data.news.refNews ? state.data.news.refNews : [],
   

  };
};

export default connect(mapStateToProps, { getNews })(Media);