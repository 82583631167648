import React from "react";
import ButtonPrimary from "../../ui/button/ButtonPrimary";
import ListFreePrimary from "../../ui/list/ListFreePrimary";

const ShippingInformation = ({ customClass, userInfo }) => {
  return (
    <ListFreePrimary customClass={`billing-details background-grey ${customClass}`}>
      <p className="lead">Shipping Information</p>
      <hr />
      <p className="">
        <b>Company:</b> {userInfo.Company}
      </p>
      <div className="d-flex">
        <p className="flex-fill">
          <b>Name:</b> {userInfo.UserName}
        </p>
        <p className="flex-fill">
          <b>Contact:</b> {userInfo.Contact}
        </p>
      </div>

      <p>
        <b>Address:</b> {userInfo.Address}
      </p>
      <div className="d-flex mb-1">
        <p className="flex-fill">
          <b>City:</b> {userInfo.City}
        </p>
        <p className="flex-fill">
          <b>State:</b> {userInfo.State}
        </p>
      </div>
      <ButtonPrimary link="/account/info" text="Change Details" />
    </ListFreePrimary>
  );
};

export default ShippingInformation;
