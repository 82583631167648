import React from "react";
import CarouselPrimary from "../../ui/carousel/CarouselPrimary";
import CarouselTertiary from "../../ui/carousel/CarouselTertiary";
import partners1 from "./images/partners1.svg";
import partners2 from "./images/partners2.svg";
import partners3 from "./images/partners3.svg";
import partners4 from "./images/partners4.svg";
import partners5 from "./images/partners5.svg";
import partners6 from "./images/partners6.png";
import partners7 from "./images/partners7.jpg";
import partners8 from "./images/partners8.jpg";
import partners9 from "./images/partners9.jpg";
import partners10 from "./images/partners10.jpg";

const PartnersSection = () => {
  return (
    <div className="partners-section">
      <h3>Our Partners</h3>
      <CarouselTertiary>
        <img className="item" src={partners1} alt="partners" />
        <img className="item" src={partners2} alt="partners" />
        <img className="item" src={partners3} alt="partners" />
        {/* <img className="item" src={partners4} alt="partners" /> */}
        <img className="item" src={partners5} alt="partners" />
        <img className="item" src={partners6} alt="partners" />
        <img className="item" src={partners7} alt="partners" />
        <img className="item" src={partners8} alt="partners" />
        <img className="item" src={partners9} alt="partners" />
        <img className="item" src={partners10} alt="partners" />
      </CarouselTertiary>
    </div>
  );
};

export default PartnersSection;
