import React from "react";
import partners1 from "../../images/img-usb-c.png";
import partners2 from "../../images/img-displayport.png";
import partners3 from "../../images/img-HDMI.png";
import CarouselTertiary from "../../ui/carousel/CarouselTertiary";



const AocCrousel = () => {
    const data = [
        {
         
            name:"USBC Active Fiber Long Cable w/ Data AV Power Delivery",
            image:partners1
        },
        {
       
            name:"DisplayPort 2.0 40GB 8K active Fiber Long Cable",
            image:partners2
        },
        {
         
            name:"HDMI 8K Long Active Optical Cable",
            image:partners3
        },
        {
         
            name:"HDMI Long Active Optical Cable",
            image:partners1
        },
       
    ]
  return (
    <div className="partners-section" style={{gap:'10px'}}>
      <CarouselTertiary>
   
       {data.map((item,index)=>(
       
         <div style={{height:'100%',position:'relative'}}>
        <img className="item"style={{objectFit:'cover',height:'100%'}}src={item.image} alt="partners" />
       <div style={{position:'absolute',top:'0',left:'0',right:'0',bottom:'0',color:'white',display:'flex',justifyContent:'center',alignItems:'center'}}>
       <h6 style={{color:'white'}}>{item.name}</h6>
       </div>
        </div>
         
 
       ))}
  
    
     
     
      </CarouselTertiary>
    </div>
  );
};

export default AocCrousel;
