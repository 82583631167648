import React, { useEffect, useState } from "react";

const Mypagination = ({ showperpage, onPaginationChange, total }) => {
  const [currentPage, setCurrentPage] = useState(1); // Tracks the current active page
  const [numberOfButtons, setNumberOfButtons] = useState(
    Math.ceil(total / showperpage)
  ); // Calculates the number of pages

  // Update pagination range whenever the current page changes
  useEffect(() => {
    const start = showperpage * (currentPage - 1);
    const end = start + showperpage;
    onPaginationChange(start, end);
  }, [currentPage, showperpage]);

  // Handle "Previous" and "Next" button clicks
  const onButtonClick = (type) => {
    if (type === "previous") {
      setCurrentPage((prev) => Math.max(prev - 1, 1)); // Prevent going below 1
    } else if (type === "next") {
      setCurrentPage((prev) => Math.min(prev + 1, numberOfButtons)); // Prevent exceeding total pages
    }
  };

  return (
    <div className="d-flex flex-row justify-content-center align-items-center">
      <nav aria-label="Page navigation">
        <ul className="pagination">
          {/* Previous Button */}
          <li className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
            <button
              className="page-link"
              onClick={() => onButtonClick("previous")}
              disabled={currentPage === 1}
            >
              Previous
            </button>
          </li>

          {/* Page Number Buttons */}
          {Array.from({ length: numberOfButtons }, (_, index) => (
            <li
              key={index}
              className={`page-item ${currentPage === index + 1 ? "active" : ""
                }`}
            >
              <button
                className="page-link"
                onClick={() => setCurrentPage(index + 1)}
              >
                {index + 1}
              </button>
            </li>
          ))}

          {/* Next Button */}
          <li
            className={`page-item ${currentPage === numberOfButtons ? "disabled" : ""
              }`}
          >
            <button
              className="page-link"
              onClick={() => onButtonClick("next")}
              disabled={currentPage === numberOfButtons}
            >
              Next
            </button>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default Mypagination;
