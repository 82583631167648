import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { contact } from "../../common/partials/navData";
import SidebarSecondary from "../../common/sidebar/SidebarSecondary";
import Support from "./Support";
import "./Support.scss";


export default function TechSupport({ location }) {
  useEffect(() => {
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.nonce = "{place_your_nonce_value_here}";
    script.src = "https://desk.zoho.com/portal/api/web/inapp/975449000000373017?orgId=849587566";
    script.defer = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);
  return (
    <>
      <Helmet>
        <title>Support</title>
        <meta name="description" content="Professional Audio Video components"></meta>
        <meta name="keywords" content="kanexpro"></meta>
      </Helmet>
      <div className="d-flex">
        <div className="d-none d-lg-block d-xl-block">
          <SidebarSecondary path={location.pathname} data={contact} title="Contact" />
        </div>

        <div className="tech-support">
          <h1 class="display-4">Tech Support</h1>
          <div className="info-container">
            <h1 className="display-5">For Dealers, Re-sellers & Consultants</h1>
            <p>
              KanexPro takes personal pride in providing high-quality products and service realizing that, in today’s
              industry, a useful piece of equipment is a major asset for keeping our customers satisfied. Our
              support group stands ready to answer all your Pro A/V questions including diagnostics, repair, replacements
              and general product support.
            </p>

            <h1 className="display-5">For End-users</h1>
            <p>
              To ensure that all end-users are fully satisfied with our products, KanexPro urges all end-users to
              directly contact their dealers or re-sellers to get up-to-date information and product support.
            </p>

            <h1 className="display-5">Business Hours:</h1>
            <p className="mb-0">Monday – Friday: 6:00 am – 5:00 pm PST</p>
            <p>Weekends & Holidays – Closed.</p>
          </div>

          <Support />
        </div>
      </div>
    </>
  );
}
