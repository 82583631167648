import React, { useEffect } from "react";
import about2 from "./Aboutus_img/about2.jpg";
import Aos from "aos";
import "aos/dist/aos.css";
import ButtonPrimary from "../../ui/button/ButtonPrimary";
import banner from "./Aboutus_img/banner.svg";

const About = () => {
  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);
  return (
    <div className="info">
      <div className="section">
        <p>
          Leveraging our core strength in professional A/V products, KanexPro carries a complete selection of A/V
          connectivity needs. When planning digital installations you will find that we carry a broad line of A/V
          connectivity products enabling you to broadcast, extend, split, or multiply HD signal transmissions; simply
          and cost-effectively.
        </p>
        <p>
          With a complete assortment of A/V connectivity products, our product line includes HDMI matrix and VGA matrix
          switchers, HDMI and DVI extender solutions, HDMI splitters and amplifier solutions. In addition to the
          KanexPro Signal Division Products we offer a complete selection of High-resolution HDMI cables and adapters
          enabling flexible A/V choices.
        </p>
        <p>Our mission is always to create top-tier technology reliable and affordable.</p>
      </div>

      <div className="section">
        <span>KanexPro </span>
        <span>512 S Brea Blvd</span>
        <span>Brea, CA 92821</span>
      </div>

      <div className="section">
        <h1 className="display-5">Business Hours:</h1>
        <span>Monday – Friday: 6:00 am – 5:00 pm PST</span>
        <span>Weekends & Holidays – Closed.</span>
      </div>
    </div>
  );
};
export default About;
