import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { getShipmentsList } from "../../../actions/account";
import { Helmet } from "react-helmet";
import { account } from "../../common/partials/navData";
import SidebarSecondary from "../../common/sidebar/SidebarSecondary";
import ListFreePrimary from "../../../ui/list/ListFreePrimary";
import { Link } from "react-router-dom";

import "./shipments.scss";
import InputSearchSecondary from "../../../ui/input/InputSearchSecondary";
import LoaderPrimary from "../../../ui/loader/LoaderPrimary";

const Shipments = (props) => {
  const { location, history, auth } = props;
  if (!auth.isAuthenticated) {
    history.push("/");
  }

  const { getShipmentsList, shipmentsList } = props;

  const [search, setSearch] = useState("");
  const [list, setList] = useState([]);

  const onChange = (e) => {
    setSearch(e.target.value);
  };

  useEffect(() => {
    if (search === "") {
      setList(shipmentsList);
    } else {
      const filteredList = shipmentsList.filter((item) => item.PO.toLowerCase().includes(search.toLowerCase()));
      setList(filteredList);
    }
  }, [search]);

  useEffect(() => {
    getShipmentsList("C100578");
  }, []);

  useEffect(() => {
    setList(shipmentsList);
  }, [shipmentsList]);

  const scrollRef = useRef();
  const scrollTable = (direction, tableScrollRef) => {
    if (direction === "left") {
      tableScrollRef.current.scrollLeft -= 100;
    }
    if (direction === "right") {
      tableScrollRef.current.scrollLeft += 100;
    }
  };

  const renderShipments = () => {
    return list.map((item, index) => (
      <tr key={index}>
        <td>
          <Link to={`/account/order/${item.SO}`}>{item.SO}</Link>
        </td>
        <td>{item.PO}</td>
        <td>{item.ShipDate}</td>
        <td>{item.ShipVia}</td>
        <td>{item.TrackNo}</td>
      </tr>
    ));
  };

  if (shipmentsList.length === 0) {
    return <LoaderPrimary />;
  }

  return (
    <>
      <Helmet>
        <title>Shipments</title>
        <meta name="description" content="Professional Audio Video components"></meta>
        <meta name="keywords" content="kanexpro"></meta>
      </Helmet>
      <div className="d-flex">
        <div className="d-none d-lg-block d-xl-block">
          <SidebarSecondary path={location.pathname} data={account} title="Account" />
        </div>
        <div className="shipments">
          <h1 className="display-4 mb-3">Recent Shipments</h1>
          <ListFreePrimary customClass="">
            <InputSearchSecondary customClass="mb-2" onChange={onChange} value={search} />
            <span>
              <span className="scroll-btn" onClick={() => scrollTable("left", scrollRef)}>
                ❮
              </span>{" "}
              Scroll{" "}
              <span className="scroll-btn" onClick={() => scrollTable("right", scrollRef)}>
                ❯
              </span>
            </span>
            <div ref={scrollRef} className="scroll-x">
              <table className="table">
                <thead className="row-brown">
                  <tr>
                    <th scope="col">Order</th>
                    <th scope="col">PO</th>
                    <th scope="col">Ship date</th>
                    <th scope="col">ship via</th>
                    <th scope="col">Tracking</th>
                  </tr>
                </thead>

                <tbody>{renderShipments()}</tbody>
              </table>
            </div>
          </ListFreePrimary>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    shipmentsList: state.account.shipmentsList ? state.account.shipmentsList : [],
    auth: state.auth,
  };
};

export default connect(mapStateToProps, { getShipmentsList })(Shipments);
