import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { getSearchProducts, setLoadingTrue } from "../../actions/products";
import { products } from "../common/partials/navData";
import Sidebar from "./Sidebar";
import "./search-products.scss";
import CardPrimary from "../../ui/CardPrimary";
import PaginationSecondary from "../../ui/pagination/PaginationSecondary";
import LoaderPrimary from "../../ui/loader/LoaderPrimary";

const SearchProducts = (props) => {
  const { searchResults, loading, getSearchProducts, setLoadingTrue, match, history } = props;

  // Pagination
  const [currentPage, setCurrentPage] = useState(1);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
    const nextPage = pageNumber === 1 ? "" : pageNumber - 1;
    history.push(`/search/${match.params.term}/${nextPage}1`);
  };

  // useEffect(() => {
  //   const nextPage = currentPage === 1 ? "" : currentPage - 1;
  //   history.push(`/search/${match.params.term}/${nextPage}1`);
  // }, [currentPage]);

  useEffect(() => {
    setCurrentPage(1);
  }, [match.params.term]);

  useEffect(() => {
    getSearchProducts(match.params.term, match.params.page);

    return () => {
      setLoadingTrue();
    };
  }, [match.params]);

  if (loading) {
    return <LoaderPrimary />;
  }
  if (searchResults.length === 0) {
    return (
      <div className="d-flex flex-column justify-content-center align-items-center" style={{ height: "100vh" }}>
        <h3>Oops! No results found for {match.params.term}.</h3>
        <button className="btn  btn-primary" onClick={() => history.goBack()}>
          Go back
        </button>
      </div>
    );
  }

  return (
    <div className="d-flex">
      <div className="d-none d-lg-block d-xl-block">
        <Sidebar data={products} />
      </div>
      <div className="search-products">
        <h1 className="display-4">Search results</h1>
        {searchResults.map((item) => {
          const itemUrl = item.link.includes("?id=")
            ? item.link.replace("https://www.kanexpro.com/item/?id=", `${process.env.PUBLIC_URL}/item/`)
            : item.link.includes("catalog")
            ? item.link
            : item.link.replace("https://www.kanexpro.com/", `${process.env.PUBLIC_URL}/`);

          return (
            <CardPrimary
              key={item.cacheId}
              image={item.pagemap.cse_thumbnail ? item.pagemap.cse_thumbnail[0].src : ""}
              // title={item.htmlTitle}
              title={<div dangerouslySetInnerHTML={{ __html: item.htmlTitle }}></div>}
              // subtitle={item.htmlSnippet}
              subtitle={<div dangerouslySetInnerHTML={{ __html: item.htmlSnippet }}></div>}
              link={itemUrl}
              a={item.link}
            />
          );
        })}
        <PaginationSecondary totalProducts={10} productsPerPage={1} currentPage={currentPage} paginate={paginate} />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    searchResults: state.data.searchProducts.items ? state.data.searchProducts.items : [],
    loading: state.data.loading,
  };
};

export default connect(mapStateToProps, { getSearchProducts, setLoadingTrue })(SearchProducts);
