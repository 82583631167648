import React from "react";
import "./list-free-primary.scss";

const ListFreePrimary = ({ children, onClick, customClass }) => {
  return (
    <div className={`list-free-primary ${customClass}`} onClick={onClick}>
      {children}
    </div>
  );
};

export default ListFreePrimary;
