import React, { useState } from "react";
import CardPrimary from "../../../ui/CardPrimary";
import PaginationSecondary from "../../../ui/pagination/PaginationSecondary";

const Products = ({ data }) => {
  // const [products, setProducts] = useState([])
  const [currentPage, setCurrentPage] = useState(1);
  const [productsPerPage, setProductsPerPage] = useState(5);

  const indexOfLastProduct = currentPage * productsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
  const currentProducts = data.slice(indexOfFirstProduct, indexOfLastProduct);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <div className='products-container'>
      <h1 className="display-5">{data[0].kproparent}</h1>
      {currentProducts.map((item) => (
        <CardPrimary
          key={item.sku}
          image={item.thumbnail}
          title={item.title}
          subtitle={item.subtitle}
          mpn={item.sku}
          link={`/item/${item.sku}`}
        />
      ))}
      <PaginationSecondary
        productsPerPage={productsPerPage}
        totalProducts={data.length}
        paginate={paginate}
        currentPage={currentPage}
      />
    </div>
  );
};

export default Products;
