import React, { useEffect } from "react";
import Prop65 from "./Prop65.jpg";
import Aos from "aos";
import "aos/dist/aos.css";

export default function CaProp() {
  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);
  return (
    <div className="caprop65">
      <div className="">
        <div className="mb-6">
          <div className="  ">
            <br></br>
            <div className=" col-md-12 d-flex justify-content-around align-items-center flex-column">
              <p className="font-weight-bold">
                What is Prop 65 and why is it important that all of the products we manufacture are compliant with this
                law?
              </p>
              <br></br>
              <div>
                <img src={Prop65} alt="callifornia prop 65" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="info">
        <div className="section">
          <p>
            California Proposition 65 (also known as the Safe Drinking Water and Toxic Enforcement Act of 1986) refers
            to the California legislation that was intended by its authors to protect California citizens and the
            State’s drinking water sources from chemicals known to cause cancer, birth defects or other reproductive
            harm, and to inform citizens about exposures to such chemicals.
          </p>

          <p>
            Proposition 65 requires businesses to notify Californians about significant amounts of chemicals in the
            products they purchase, in their homes or workplaces, or that are released into the environment. By
            providing this information, Proposition 65 enables Californians to make informed decisions about protecting
            themselves from exposure to these chemicals. Proposition 65 also prohibits California businesses from
            knowingly discharging significant amounts of listed chemicals into sources of drinking water.
          </p>

          <p className="font-weight-bold">
            As required by the State of California: KanexPro products may have a warning label stating Warning: this
            product contains chemicals known to the state of California to cause cancer, birth defects, or other
            reproductive harm.
          </p>
        </div>
      </div>
    </div>
  );
}
