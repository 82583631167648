import React from "react";
import partners1 from "./enterprise.png";
import partners2 from "./imgGovernment.png";
import partners3 from "./imgRestaurant.png";
import partners4 from "../../images/HigherEducation.jpg";
import partners5 from "../../images/Entertainment.jpg";
import CarouselTertiary from "../../ui/carousel/CarouselTertiary";
import { Link } from "react-router-dom";


const CrouselFour = () => {
  const data = [
    {
      link: "enterprise",
      name: "Enterprise",
      image: partners1,
      title: "Flex Matrix | Enterprise"
    },
    {
      link: "government",
      name: "Government",
      image: partners2,
      title: "Flex Matrix | Government"
    },
    {
      link: "restaurant",
      name: "Hospitality",
      image: partners3,
      title: "Flex Matrix | Hospitality"
    },
    {
      link: "higherEducation",
      name: "Higher Education",
      image: partners4,
      title: "Flex Matrix | Higher Education"
    },
    {
      link: "entertainment",
      name: "Entertainment",
      image: partners5,
      title: "Flex Matrix | Entertainment"
    },
    {
      link: "financial",
      name: "Financial",
      image: partners1,
      title: "Flex Matrix | Financial"
    }
  ]
  return (
    <div className="partners-section" style={{ gap: '50px' }}>
      <CarouselTertiary>

        {data.map((item, index) => (
          <Link style={{ height: '100%', display: 'flex' }} to={`${process.env.PUBLIC_URL}/flexmatrix/` + `${item.link}`} key={index}>
            <div style={{ position: 'relative' }}>
              <img className="item" style={{ objectFit: 'cover', maxWidth: '305px', height: '100%' }} src={item.image} alt="partners" />
              <div style={{ position: 'absolute', top: '0', left: '0', right: '0', bottom: '0', color: 'white', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <h4 style={{ color: 'white' }}>{item.name}</h4>
              </div>
            </div>

          </Link>
        ))}




      </CarouselTertiary>
    </div>
  );
};

export default CrouselFour;
