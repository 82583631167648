import React from "react";
import "./breadcrumb-primary.scss";
import { Link } from "react-router-dom";

const BreadCrumbsPrimary = ({ home, linkHome, category, linkCategory, subCategory }) => {
  return (
    <ul className="breadcrumb">
      {home && (
        <li>
          <Link to={linkHome}>{home}</Link>
        </li>
      )}
      {category && <li>{linkCategory ? <Link to={linkCategory}>{category}</Link> : category}</li>}

      {subCategory && <li>{subCategory}</li>}
    </ul>
  );
};

export default BreadCrumbsPrimary;
