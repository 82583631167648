import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { getOrderOpenHistory, getOrderCloseHistory } from "../../../actions/account";
import { Helmet } from "react-helmet";
import { account } from "../../common/partials/navData";
import SidebarSecondary from "../../common/sidebar/SidebarSecondary";
import ListFreePrimary from "../../../ui/list/ListFreePrimary";
import { Link } from "react-router-dom";
import "./orders.scss";
import InputSearchSecondary from "../../../ui/input/InputSearchSecondary";
import PaginationSecondary from "../../../ui/pagination/PaginationSecondary";
import LoaderPrimary from "../../../ui/loader/LoaderPrimary";

const Orders = (props) => {
  const { location, history, auth } = props;

  if (!auth.isAuthenticated) {
    history.push("/");
  }

  const { getOrderOpenHistory, getOrderCloseHistory, orderHistoryOpen, orderHistoryClose } = props;

  const closeScrollRef = useRef();
  const openScrollRef = useRef();

  const [searchOpen, setSearchOpen] = useState("");
  const [listOpen, setListOpen] = useState([]);
  const [searchClose, setSearchClose] = useState("");
  const [listClose, setListClose] = useState([]);

  useEffect(() => {
    getOrderOpenHistory("C100578");
    getOrderCloseHistory("C100578");
  }, []);

  const onChangeOpen = (e) => {
    paginateOpen(1);
    setSearchOpen(e.target.value);
  };

  const onChangeClose = (e) => {
    paginateClose(1);
    setSearchClose(e.target.value);
  };

  useEffect(() => {
    setListOpen(orderHistoryOpen);
  }, [orderHistoryOpen]);

  useEffect(() => {
    setListClose(orderHistoryClose);
  }, [orderHistoryClose]);

  useEffect(() => {
    const filteredList = orderHistoryOpen.filter((item) => item.po.toLowerCase().includes(searchOpen.toLowerCase()));
    setListOpen(filteredList);
  }, [searchOpen]);

  useEffect(() => {
    const filteredList = orderHistoryClose.filter(
      (item) => item.po && item.po.toLowerCase().includes(searchClose.toLowerCase())
    );
    setListClose(filteredList);
  }, [searchClose]);

  const scrollTable = (direction, tableScrollRef) => {
    if (direction === "left") {
      tableScrollRef.current.scrollLeft -= 100;
    }
    if (direction === "right") {
      tableScrollRef.current.scrollLeft += 100;
    }
  };

  const renderOpen = () => {
    // const dataOpen = listOpen.length === 0 ? orderHistoryOpen : listOpen;
    return currentOpen.map((item) => (
      <tr>
        <td>
          <Link to={`/account/order/${item.DocNum}`}>{item.DocNum}</Link>
        </td>

        <td>{item.po}</td>
        <td>{item.Date}</td>
        <td>{item.Total}</td>
      </tr>
    ));
  };

  const renderClose = () => {
    // const dataClose = listClose.length === 0 ? orderHistoryClose : listClose;
    return currentClose.map((item) => (
      <tr>
        <td>
          <Link to={`/account/order/${item.DocNum}`}>{item.DocNum}</Link>
        </td>

        <td>{item.po}</td>
        <td>{item.Date}</td>
        <td>{item.Total}</td>
      </tr>
    ));
  };

  // Paginate Open
  const [currentPageOpen, setCurrentPageOpen] = useState(1);
  const [openPerPage, setOpenPerPage] = useState(20);

  const indexOfLastOpen = currentPageOpen * openPerPage;
  const indexOfFirstOpen = indexOfLastOpen - openPerPage;
  const currentOpen = listOpen.slice(indexOfFirstOpen, indexOfLastOpen);

  const paginateOpen = (pageNumber) => {
    setCurrentPageOpen(pageNumber);
  };

  // Paginate Close
  const [currentPageClose, setCurrentPageClose] = useState(1);
  const [closePerPage, setClosePerPage] = useState(20);

  const indexOfLastClose = currentPageClose * closePerPage;
  const indexOfFirstClose = indexOfLastClose - closePerPage;
  const currentClose = listClose.slice(indexOfFirstClose, indexOfLastClose);

  const paginateClose = (pageNumber) => {
    setCurrentPageClose(pageNumber);
  };

  if (orderHistoryOpen.length === 0 || orderHistoryClose.length === 0) {
    return <LoaderPrimary />;
  }

  return (
    <>
      <Helmet>
        <title>Orders</title>
        <meta name="description" content="Professional Audio Video components"></meta>
        <meta name="keywords" content="kanexpro"></meta>
      </Helmet>
      <div className="d-flex">
        <div className="d-none d-lg-block d-xl-block">
          <SidebarSecondary path={location.pathname} data={account} title="Account" />
        </div>
        <div className="orders">
          <h1 className="display-4 mb-3">Order History</h1>
          <div className="row">
            <div className="col-lg-6">
              <ListFreePrimary customClass="mb-3">
                <p className="lead fs-m">Open</p>
                <InputSearchSecondary customClass="mb-2" value={searchOpen} onChange={onChangeOpen} />
                <span>
                  <span className="scroll-btn" onClick={() => scrollTable("left", openScrollRef)}>
                    ❮
                  </span>{" "}
                  Scroll{" "}
                  <span className="scroll-btn" onClick={() => scrollTable("right", openScrollRef)}>
                    ❯
                  </span>
                </span>
                <div ref={openScrollRef} className="scroll-x mb-3">
                  <table className="table">
                    <thead className="row-brown">
                      <tr>
                        <th scope="col">Order</th>
                        <th scope="col">PO</th>
                        <th scope="col">Date</th>
                        <th scope="col">Total</th>
                      </tr>
                    </thead>

                    <tbody>{renderOpen()}</tbody>
                  </table>
                </div>
                <PaginationSecondary
                  productsPerPage={openPerPage}
                  totalProducts={listOpen.length}
                  paginate={paginateOpen}
                  currentPage={currentPageOpen}
                />
              </ListFreePrimary>
            </div>
            <div className="col-lg-6">
              <ListFreePrimary customClass="">
                <p className="lead fs-m">Close</p>

                <InputSearchSecondary customClass="mb-1" value={searchClose} onChange={onChangeClose} />
                <span>
                  <span className="scroll-btn" onClick={() => scrollTable("left", closeScrollRef)}>
                    ❮
                  </span>{" "}
                  Scroll{" "}
                  <span className="scroll-btn" onClick={() => scrollTable("right", closeScrollRef)}>
                    ❯
                  </span>
                </span>

                <div className="scroll-x mb-3" ref={closeScrollRef}>
                  <table className="table">
                    <thead className="row-brown">
                      <tr>
                        <th scope="col">Order</th>
                        <th scope="col">PO</th>
                        <th scope="col">Date</th>
                        <th scope="col">Total</th>
                      </tr>
                    </thead>

                    <tbody>{renderClose()}</tbody>
                  </table>
                </div>

                <PaginationSecondary
                  productsPerPage={closePerPage}
                  totalProducts={listClose.length}
                  paginate={paginateClose}
                  currentPage={currentPageClose}
                />
              </ListFreePrimary>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    orderHistoryOpen: state.account.orderHistoryOpen ? state.account.orderHistoryOpen : [],
    orderHistoryClose: state.account.orderHistoryClose ? state.account.orderHistoryClose : [],
    auth: state.auth,
  };
};

export default connect(mapStateToProps, { getOrderOpenHistory, getOrderCloseHistory })(Orders);
