import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import ButtonPrimary from "../../../ui/button/ButtonPrimary";
import ButtonSecondary from "../../../ui/button/ButtonSecondary";
import ListFreePrimary from "../../../ui/list/ListFreePrimary";
import {
  noSpaceOnChange,
  notEmptyValidation,
  phoneOnChange,
  phoneValidation,
} from "../../../validation/formValidation";
import { account } from "../../common/partials/navData";
import SidebarSecondary from "../../common/sidebar/SidebarSecondary";
import "./account-info.scss";
import { billingPhoneValidation, billingWebsiteValidation } from "./infoValidation";
import { connect } from "react-redux";

const AccountInfo = ({ history, location, userInfo, auth }) => {
  if (!auth.isAuthenticated) {
    history.push("/");
  }

  const [billingData, setBillingData] = useState({
    company: userInfo ? userInfo.Company : "",
    website: "",
    contact: userInfo ? userInfo.Contact : "",
    phone: userInfo ? userInfo.Phone : "",
    fax: userInfo ? userInfo.Fax : "",
    address: userInfo ? userInfo.Address : "",
    addressAlt: userInfo ? userInfo.Address2 : "",
    city: userInfo ? userInfo.City : "",
    state: userInfo ? userInfo.State : "",
    postal: userInfo ? userInfo.Zip : "",
    country: userInfo ? userInfo.Country : "",
  });

  const [shippingData, setShippingData] = useState({
    company: userInfo ? userInfo.ShipCompany : "",
    contact: userInfo ? userInfo.ShipContact : "",
    address: userInfo ? userInfo.ShipAddress : "",
    addressAlt: userInfo ? userInfo.ShipAddress2 : "",
    city: userInfo ? userInfo.ShipCity : "",
    state: userInfo ? userInfo.ShipState : "",
    postal: userInfo ? userInfo.ShipZip : "",
    country: userInfo ? userInfo.ShipCountry : "",
  });

  useEffect(() => {
    if (userInfo) {
      setBillingData({
        company: userInfo.Company,
        website: "",
        contact: userInfo.Contact,
        phone: userInfo.Phone,
        fax: userInfo.Fax,
        address: userInfo.Address,
        addressAlt: userInfo.Address2,
        city: userInfo.City,
        state: userInfo.State,
        postal: userInfo.Zip,
        country: userInfo.Country,
      });

      setShippingData({
        company: userInfo.ShipCompany,
        contact: userInfo.ShipContact,
        address: userInfo.ShipAddress,
        addressAlt: userInfo.ShipAddress2,
        city: userInfo.ShipCity,
        state: userInfo.ShipState,
        postal: userInfo.ShipZip,
        country: userInfo.ShipCountry,
      });
    }
  }, [userInfo]);

  const [billingError, setBillingError] = useState({
    companyError: "",
    websiteError: "",
    contactError: "",
    addressError: "",
    phoneError: "",
    faxError: "",
    cityError: "",
    stateError: "",
    postalError: "",
    countryError: "",
  });

  const [shippingError, setShippingError] = useState({
    contactError: "",
    addressError: "",
    cityError: "",
    stateError: "",
    postalError: "",
    countryError: "",
  });

  const [formError, setFormError] = useState("");

  const onChangeBilling = (e) => {
    setFormError("");
    if (e.target.name === "company") {
      setBillingError({
        ...billingError,
        companyError: "",
      });
      setBillingData({ ...billingData, [e.target.name]: e.target.value });
    } else if (e.target.name === "website") {
      setBillingError({
        ...billingError,
        websiteError: "",
      });
      noSpaceOnChange(e.target.value) && setBillingData({ ...billingData, [e.target.name]: e.target.value });
    } else if (e.target.name === "contact") {
      setBillingError({
        ...billingError,
        contactError: "",
      });
      phoneOnChange(e.target.value) && setBillingData({ ...billingData, [e.target.name]: e.target.value });
    } else if (e.target.name === "phone") {
      setBillingError({
        ...billingError,
        phoneError: "",
      });
      phoneOnChange(e.target.value) && setBillingData({ ...billingData, [e.target.name]: e.target.value });
    } else if (e.target.name === "address") {
      setBillingError({
        ...billingError,
        addressError: "",
      });
      setBillingData({ ...billingData, [e.target.name]: e.target.value });
    } else if (e.target.name === "city") {
      setBillingError({
        ...billingError,
        cityError: "",
      });
      setBillingData({ ...billingData, [e.target.name]: e.target.value });
    } else if (e.target.name === "state") {
      setBillingError({
        ...billingError,
        stateError: "",
      });
      setBillingData({ ...billingData, [e.target.name]: e.target.value });
    } else if (e.target.name === "postal") {
      setBillingError({
        ...billingError,
        postalError: "",
      });
      setBillingData({ ...billingData, [e.target.name]: e.target.value });
    } else if (e.target.name === "country") {
      setBillingError({
        ...billingError,
        countryError: "",
      });
      setBillingData({ ...billingData, [e.target.name]: e.target.value });
    } else {
      setBillingData({ ...billingData, [e.target.name]: e.target.value });
    }
  };

  const onChangeShipping = (e) => {
    setFormError("");
    if (e.target.name === "contact") {
      setShippingError({
        ...shippingError,
        contactError: "",
      });

      phoneOnChange(e.target.value) && setShippingData({ ...shippingData, [e.target.name]: e.target.value });
    } else if (e.target.name === "address") {
      setShippingError({
        ...shippingError,
        addressError: "",
      });
      setShippingData({ ...shippingData, [e.target.name]: e.target.value });
    } else if (e.target.name === "city") {
      setShippingError({
        ...shippingError,
        cityError: "",
      });
      setShippingData({ ...shippingData, [e.target.name]: e.target.value });
    } else if (e.target.name === "state") {
      setShippingError({
        ...shippingError,
        stateError: "",
      });
      setShippingData({ ...shippingData, [e.target.name]: e.target.value });
    } else if (e.target.name === "postal") {
      setShippingError({
        ...shippingError,
        postalError: "",
      });
      setShippingData({ ...shippingData, [e.target.name]: e.target.value });
    } else if (e.target.name === "country") {
      setShippingError({
        ...shippingError,
        countryError: "",
      });
      setShippingData({ ...shippingData, [e.target.name]: e.target.value });
    } else {
      setShippingData({ ...shippingData, [e.target.name]: e.target.value });
    }
  };

  const onBlurBilling = (e) => {
    if (e.target.name === "company" && !notEmptyValidation(billingData.company)) {
      setBillingError({ ...billingError, companyError: "Company is required" });
    }
    if (e.target.name === "website" && !billingWebsiteValidation(billingData.website)) {
      setBillingError({ ...billingError, websiteError: "Please enter a valid URL" });
    }
    if (e.target.name === "contact" && !phoneValidation(billingData.contact)) {
      setBillingError({ ...billingError, contactError: "Contact number must have 10 numbers" });
    }
    if (e.target.name === "phone" && !billingPhoneValidation(billingData.phone)) {
      setBillingError({ ...billingError, phoneError: "Phone number must have 10 numbers" });
    }
    if (e.target.name === "address" && !notEmptyValidation(billingData.address)) {
      setBillingError({ ...billingError, addressError: "Address is required" });
    }
    if (e.target.name === "city" && !notEmptyValidation(billingData.city)) {
      setBillingError({ ...billingError, cityError: "City is required" });
    }
    if (e.target.name === "state" && !notEmptyValidation(billingData.state)) {
      setBillingError({ ...billingError, stateError: "State is required" });
    }
    if (e.target.name === "postal" && !notEmptyValidation(billingData.postal)) {
      setBillingError({ ...billingError, postalError: "Postal is required" });
    }
    if (e.target.name === "country" && !notEmptyValidation(billingData.country)) {
      setBillingError({ ...billingError, countryError: "Country is required" });
    }
  };

  const onBlurShipping = (e) => {
    if (e.target.name === "contact" && !phoneValidation(shippingData.contact)) {
      setShippingError({ ...shippingError, contactError: "Contact number must have 10 numbers" });
    }
    if (e.target.name === "address" && !notEmptyValidation(shippingData.address)) {
      setShippingError({ ...shippingError, addressError: "Address is required" });
    }
    if (e.target.name === "city" && !notEmptyValidation(shippingData.city)) {
      setShippingError({ ...shippingError, cityError: "City is required" });
    }
    if (e.target.name === "state" && !notEmptyValidation(shippingData.state)) {
      setShippingError({ ...shippingError, stateError: "State is required" });
    }
    if (e.target.name === "postal" && !notEmptyValidation(shippingData.postal)) {
      setShippingError({ ...shippingError, postalError: "Postal is required" });
    }
    if (e.target.name === "country" && !notEmptyValidation(shippingData.country)) {
      setShippingError({ ...shippingError, countryError: "Country is required" });
    }
  };

  const onSubmitValidation = () => {
    if (
      !notEmptyValidation(billingData.company) ||
      !billingWebsiteValidation(billingData.website) ||
      !phoneValidation(billingData.contact) ||
      !billingPhoneValidation(billingData.phone) ||
      !notEmptyValidation(billingData.address) ||
      !notEmptyValidation(billingData.city) ||
      !notEmptyValidation(billingData.state) ||
      !notEmptyValidation(billingData.postal) ||
      !notEmptyValidation(billingData.country) ||
      !phoneValidation(shippingData.contact) ||
      !notEmptyValidation(shippingData.address) ||
      !notEmptyValidation(shippingData.city) ||
      !notEmptyValidation(shippingData.state) ||
      !notEmptyValidation(shippingData.postal) ||
      !notEmptyValidation(shippingData.country)
    ) {
      setFormError("Please fill all the required fields correctly!");
      return false;
    } else {
      return true;
    }
  };

  const onSubmitInfo = (e) => {
    e.preventDefault();
    if (!onSubmitValidation()) {
      return;
    }
  };

  return (
    <>
      <Helmet>
        <title>Account Information</title>
        <meta name="description" content="Professional Audio Video components"></meta>
        <meta name="keywords" content="kanexpro"></meta>
      </Helmet>
      <div className="d-flex">
        <div className="d-none d-lg-block d-xl-block">
          <SidebarSecondary path={location.pathname} data={account} title="Account" />
        </div>

        <div className="account-info">
          <h1 className="display-4 mb-3">User Details</h1>

          <form className="faq-form row" onSubmit={onSubmitInfo}>
            <div className="billing-information col-md-6">
              <ListFreePrimary customClass="mb-3">
                <h1 className="lead fs-s">Billing Information</h1>

                <div className="form-group">
                  <input
                    id="inputCompany"
                    placeholder="Your Company"
                    name="company"
                    value={billingData.company}
                    onChange={onChangeBilling}
                    onBlur={onBlurBilling}
                    required={true}
                  />
                  {billingError.companyError && <p className="invalid-data mt-1">{billingError.companyError}</p>}
                </div>
                <div className="form-group">
                  <input
                    id="inputWebsite"
                    placeholder="Ex - www.yourwebsite.com"
                    name="website"
                    value={billingData.website}
                    onChange={onChangeBilling}
                    onBlur={onBlurBilling}
                  />
                  {billingError.websiteError && <p className="invalid-data mt-1">{billingError.websiteError}</p>}
                </div>
                <div className="form-group">
                  <input
                    id="inputContact"
                    placeholder="Must have 10 numbers"
                    name="contact"
                    value={billingData.contact}
                    onChange={onChangeBilling}
                    onBlur={onBlurBilling}
                    required={true}
                  />
                  {billingError.contactError && <p className="invalid-data mt-1">{billingError.contactError}</p>}
                </div>
                <div className="form-group">
                  <input
                    id="inputPhone"
                    placeholder="Must have 10 numbers"
                    name="phone"
                    value={billingData.phone}
                    onChange={onChangeBilling}
                    onBlur={onBlurBilling}
                  />
                  {billingError.phoneError && <p className="invalid-data mt-1">{billingError.phoneError}</p>}
                </div>
                <div className="form-group">
                  <input
                    id="inputFax"
                    placeholder="Your Fax"
                    name="fax"
                    value={billingData.fax}
                    onChange={onChangeBilling}
                  />
                </div>
                <div className="form-group">
                  <input
                    id="inputAddress"
                    placeholder="Your Address"
                    name="address"
                    value={billingData.address}
                    onChange={onChangeBilling}
                    required={true}
                    onBlur={onBlurBilling}
                  />
                  {billingError.addressError && <p className="invalid-data mt-1">{billingError.addressError}</p>}
                </div>
                <div className="form-group">
                  <input
                    id="inputAddressAlt"
                    placeholder="Your alternative address"
                    name="addressAlt"
                    value={billingData.addressAlt}
                    onChange={onChangeBilling}
                    onBlur={onBlurBilling}
                  />
                </div>
                <div className="form-group">
                  <input
                    id="inputCity"
                    placeholder="Your City"
                    name="city"
                    value={billingData.city}
                    onChange={onChangeBilling}
                    required={true}
                    onBlur={onBlurBilling}
                  />
                  {billingError.cityError && <p className="invalid-data mt-1">{billingError.cityError}</p>}
                </div>
                <div className="form-group">
                  <input
                    id="inputState"
                    placeholder="Your State"
                    name="state"
                    value={billingData.state}
                    onChange={onChangeBilling}
                    required={true}
                    onBlur={onBlurBilling}
                  />
                  {billingError.stateError && <p className="invalid-data mt-1">{billingError.stateError}</p>}
                </div>
                <div className="form-group">
                  <input
                    iid="inputPostal"
                    placeholder="Your Postal"
                    name="postal"
                    value={billingData.postal}
                    onChange={onChangeBilling}
                    required={true}
                    onBlur={onBlurBilling}
                  />
                  {billingError.postalError && <p className="invalid-data mt-1">{billingError.postalError}</p>}
                </div>
                <div className="form-group">
                  <input
                    id="inputCountry"
                    placeholder="Your Country"
                    name="country"
                    value={billingData.country}
                    onChange={onChangeBilling}
                    required={true}
                    onBlur={onBlurBilling}
                  />
                  {billingError.countryError && <p className="invalid-data mt-1">{billingError.countryError}</p>}
                </div>
              </ListFreePrimary>
            </div>
            <div className="shippiing-information col-md-6">
              <ListFreePrimary customClass="background-blue">
                <h1 className="lead fs-s">Shipping Information</h1>
                <div className="form-group">
                  <input
                    id="inputCountry"
                    placeholder="Your Company"
                    name="company"
                    value={shippingData.company}
                    onChange={onChangeShipping}
                  />
                </div>
                <div className="form-group">
                  <input
                    id="inputContact"
                    placeholder="Must have 10 numbers"
                    name="contact"
                    value={shippingData.contact}
                    onChange={onChangeShipping}
                    onBlur={onBlurShipping}
                    required={true}
                  />
                  {shippingError.contactError && <p className="invalid-data mt-1">{shippingError.contactError}</p>}
                </div>
                <div className="form-group">
                  <input
                    id="inputAddress"
                    placeholder="Your Address"
                    name="address"
                    value={shippingData.address}
                    onChange={onChangeShipping}
                    onBlur={onBlurShipping}
                    required={true}
                  />
                  {shippingError.addressError && <p className="invalid-data mt-1">{shippingError.addressError}</p>}
                </div>
                <div className="form-group">
                  <input
                    id="inputAddressAlt"
                    placeholder="Your alternative Address"
                    name="addressAlt"
                    value={shippingData.addressAlt}
                    onChange={onChangeShipping}
                    onBlur={onBlurShipping}
                  />
                </div>
                <div className="form-group">
                  <input
                    id="inputCity"
                    placeholder="Your City"
                    name="city"
                    value={shippingData.city}
                    onChange={onChangeShipping}
                    onBlur={onBlurShipping}
                    required={true}
                  />
                  {shippingError.cityError && <p className="invalid-data mt-1">{shippingError.cityError}</p>}
                </div>
                <div className="form-group">
                  <input
                    id="inputState"
                    placeholder="Your State"
                    name="state"
                    value={shippingData.state}
                    onChange={onChangeShipping}
                    onBlur={onBlurShipping}
                    required={true}
                  />
                  {shippingError.stateError && <p className="invalid-data mt-1">{shippingError.stateError}</p>}
                </div>
                <div className="form-group">
                  <input
                    id="inputPostal"
                    placeholder="Your Postal"
                    name="postal"
                    value={shippingData.postal}
                    onChange={onChangeShipping}
                    onBlur={onBlurShipping}
                    required={true}
                  />
                  {shippingError.postalError && <p className="invalid-data mt-1">{shippingError.postalError}</p>}
                </div>
                <div className="form-group">
                  <input
                    id="inputCountry"
                    placeholder="Your Country"
                    name="country"
                    value={shippingData.country}
                    onChange={onChangeShipping}
                    onBlur={onBlurShipping}
                    required={true}
                  />
                  {shippingError.countryError && <p className="invalid-data mt-1">{shippingError.countryError}</p>}
                </div>
              </ListFreePrimary>
            </div>
            {formError && (
              <p className="invalid-data mt-1 tc">
                <i className="fas fa-exclamation-triangle mr-2"></i>
                {formError}
              </p>
            )}
            {/* <div className="d-flex w-100 justify-content-center mt-3 mb-3">
              <ButtonPrimary link="/secure/cart" text="Cancel" customClass="mr-3" />
              <ButtonSecondary text="Make Changes" customClass="ml-3" />
            </div> */}
          </form>
          <div style={{ textAlign: "center", fontWeight: "bold" }}>Contact KanexPro to change details.</div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    userInfo: state.auth.userInfo,
    auth: state.auth,
  };
};

export default connect(mapStateToProps)(AccountInfo);
