import React, { useState, useEffect } from "react";
import ListFreePrimary from "../../../ui/list/ListFreePrimary";
import { connect } from "react-redux";
import { postOpenItemComments, openItemsCommentStatus } from "../../../actions/account";

const Comment = ({ data, onClickBody, onClickCancel, commentStatus, postOpenItemComments, openItemsCommentStatus }) => {
  const [comment, setcomment] = useState(data.comment);
  const onSubmit = (e) => {
    e.preventDefault();
    postOpenItemComments(comment, data.docentry, data.linenum, data.product);
  };

  const stopPropagation = (e) => {
    e.stopPropagation();
  };

  useEffect(() => {
    return () => {
      openItemsCommentStatus(null);
    };
  }, []);

  return (
    <div className="comment" onClick={onClickBody}>
      <ListFreePrimary customClass="background-grey comment-form" onClick={stopPropagation}>
        <form className="faq-form" onSubmit={onSubmit}>
          <h1 className="display-4 fs-s text-center">{data.product}</h1>

          {commentStatus === 200 ? (
            <div className="comment-status">
              <i className="fas fa-check"></i> <p>Submitted successfully</p>
            </div>
          ) : (
            <div className="form-group">
              <textarea
                className="form-control"
                id="inputTextarea"
                rows="3"
                placeholder="Comment here..."
                value={comment}
                onChange={(e) => setcomment(e.target.value)}
              ></textarea>
            </div>
          )}

          <div className="d-flex justify-content-around mt-3">
            <button className="btn btn-primary" onClick={onClickCancel}>
              {commentStatus !== 200 ? "Cancel" : "Close"}
            </button>
            {commentStatus !== 200 && (
              <button size={1} type="submit" className="btn btn-secondary">
                Submit
              </button>
            )}
          </div>
        </form>
      </ListFreePrimary>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    commentStatus: state.account.openItemsComment,
  };
};

export default connect(mapStateToProps, { postOpenItemComments, openItemsCommentStatus })(Comment);
