import React, { useEffect } from "react";
import { connect } from "react-redux";
import { getPartners } from "../../actions/products";
import "../home/style.scss";
import { GoogleMap, LoadScript } from "@react-google-maps/api";

const Partner = (props) => {
  const { NorthAmerica, Asia, SouthAmerica, Emea } = props;
  const mapStyles = {
    height: "400px",
    width: "100%",
  };
  const defaultCenter = {
    lat: 34.0668153,
    lng: -117.6115045,
  };
  useEffect(() => {
    props.getPartners();
  }, []);
  return (
    <div>
      <h1 className="display-4">Partners</h1>
      <div className="map" style={{ height: "400px", marginBottom: "5rem" }}>
        <LoadScript googleMapsApiKey="AIzaSyCou-hTJprYFCJgqPuT2MWamG1MIM0tVXw">
          <GoogleMap mapContainerStyle={mapStyles} zoom={13} center={defaultCenter} />
        </LoadScript>
      </div>
      <div className="partners-section mt-2">
        <h5 className="mb-2 text-center">North America</h5>
        <ul className="partners-container">
          {NorthAmerica.map((item, index) => {
            return (
              <div className=" PartnerCard">
                <div className="front">
                  <div className="card-name">{item.name}</div>
                  <img src={"https://www.kanexpro.com/images/logos/" + item.logo} alt={item.name} />

                  <div className="card-city">{item.city}</div>
                </div>
                <div className="back">
                  <h3>{item.name}</h3>
                  <p>{item.address}</p>
                  <p>Phone: {item.tel}</p>
                  <p>
                    Email: <a href={`mailto:${item.email}`}>{item.email}</a>
                  </p>
                  <p>
                    Web:
                    <a href={item.url} rel="noreferrer" target="_blank">
                      {item.url}
                    </a>
                  </p>
                </div>
              </div>
            );
          })}
        </ul>
      </div>
      <div className="partners-section mt-2">
        <h5 className="mb-2 text-center">EMEA</h5>
        <div className="partners-container">
          {Emea.map((item, index) => {
            return (
              <div className=" PartnerCard">
                <div className="front">
                  <div className="card-name">{item.name}</div>
                  <img src={"https://www.kanexpro.com/images/logos/" + item.logo} alt={item.name} />

                  <div className="card-city">{item.city}</div>
                </div>
                <div className="back">
                  <h3>{item.name}</h3>
                  <p>{item.address}</p>
                  <p>phone: {item.tel}</p>
                  <p>
                    Email: <a href={`mailto:${item.email}`}>{item.email}</a>
                  </p>
                  <p>
                    Web:{" "}
                    <a href={item.url} rel="noreferrer" target="_blank">
                      {item.url}
                    </a>
                  </p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className="partners-section mt-2">
        <h5 className="mb-2 text-center">South America</h5>
        <div className="partners-container">
          {SouthAmerica.map((item, index) => {
            return (
              <div className=" PartnerCard">
                <div className="front">
                  <div className="card-name">{item.name}</div>
                  <img src={"https://www.kanexpro.com/images/logos/" + item.logo} alt={item.name} />

                  <div className="card-city">{item.city}</div>
                </div>
                <div className="back">
                  <h3>{item.name}</h3>
                  <p>{item.address}</p>
                  <p>Phone: {item.tel}</p>
                  <p>
                    Email: <a href={`mailto:${item.email}`}>{item.email}</a>
                  </p>
                  <p>
                    Web:{" "}
                    <a href={item.url} rel="noreferrer" target="_blank">
                      {item.url}
                    </a>
                  </p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className="partners-section mt-2">
        <h5 className="mb-2 text-center">Asia</h5>
        <div className="partners-container">
          {Asia.map((item, index) => {
            return (
              <div className=" PartnerCard">
                <div className="front">
                  <div className="card-name">{item.name}</div>
                  <img src={"https://www.kanexpro.com/images/logos/" + item.logo} alt={item.name} />

                  <div className="card-city">{item.city}</div>
                </div>
                <div className="back">
                  <h3>{item.name}</h3>
                  <p>{item.address}</p>
                  <p>Phone: {item.tel}</p>
                  <p>
                    Email: <a href={`mailto:${item.email}`}>{item.email}</a>
                  </p>
                  <p>
                    Web:{" "}
                    <a href={item.url} rel="noreferrer" target="_blank">
                      {item.url}
                    </a>
                  </p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    allPartners: state.data.partners.refAll ? state.data.partners.refAll : [],
    Emea: state.data.partners.refEmea ? state.data.partners.refEmea : [],
    NorthAmerica: state.data.partners.refNorthAmerica ? state.data.partners.refNorthAmerica : [],
    SouthAmerica: state.data.partners.refSouthAmerica ? state.data.partners.refSouthAmerica : [],
    Asia: state.data.partners.refAsia ? state.data.partners.refAsia : [],
    // MiddleEast : state.partners.refMiddleEast ? state.data.partners.refMiddleEast : []
  };
};

export default connect(mapStateToProps, { getPartners })(Partner);
