import React from 'react';
import { useState } from 'react';
import axios from '../../../src/api/index.js';


export default function Form() {
  const initData = { firstName_chr: '', lastName_chr: '', email_chr: '', phone_chr: '', msg_chr: '' };
  const [formData, setFormData] = useState(initData);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'phone_chr') {
      const onlyNums = value.replace(/[^0-9*#]/g, '');
      setFormData((state) => ({ ...state, [name]: onlyNums }));
    } else {
      setFormData((state) => ({ ...state, [name]: value }));
    }
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    try {
      const res = await axios.post("/api/EmailManagement/TechSupport", {
        name: `${formData.firstName_chr} ${formData.lastName_chr}`,
        email: formData.email_chr,
        phone: formData.phone_chr,
        comments: formData.msg_chr
      });
      setFormData(initData);
    } catch (error) {
      console.log('check err', error);
    }
  };

  return (
    <div className='Form-container'>
      <div className="formWrapper mt-8 md:mt-0" id="formMatrix">
        <form className='formText' onSubmit={handleFormSubmit}>
          <div className='firstNameTextInput'>
            <div className='formHead1 w-full text-center text-5xl text-black'>
              Who can we schedule this custom demo with?
            </div>
          </div>
          <div className='firstNameTextInput'>
            <div className='formHead2 w-full text-center text-5xl text-black'>
              One of our sales managers will reach out to you shortly
            </div>
          </div>
          <div className='formFieldsContainer'>
            <div className='rectangleBackground'>
              <div className='firstName'>
                <input
                  required
                  name='firstName_chr'
                  className='firstName1'
                  placeholder="First Name"
                  onChange={handleChange}
                  value={formData.firstName_chr || ''}
                  type="text"
                />
                <div className='frameContainer' />
              </div>
            </div>

            <div className='rectangleBackground2'>
              <div className='email'>
                <input
                  required
                  name='email_chr'
                  className='email1'
                  placeholder="Business Email"
                  type="email"
                  onChange={handleChange}
                  value={formData.email_chr || ''}

                />
                <div className='emailChild' />
              </div>
            </div>
            <div className='rectangleBackground3'>
              <div className='phone'>
                <input
                  name='phone_chr'
                  className='phone1'
                  placeholder="Phone"
                  type="tel"
                  maxLength={10}
                  onChange={handleChange}
                  value={formData.phone_chr || ''}
                />
                <div className='phoneChild' />
              </div>
            </div>
            <button type='submit' className='p-8 px-12 text-3xl text-white font-sans bg-orange rounded-full hover:bg-black transition-all '>
              Get a Custom Demo
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};