import React from "react";
import { about } from "../../common/partials/navData";
import SidebarSecondary from "../../common/sidebar/SidebarSecondary";
import BlogDescription from "./BlogDescription";
import "./BlogDescription.css";

export default function BlogDesc({ location ,match}) {
  return (
    <>
      <div className="d-flex">
        <div className="d-none d-lg-block d-xl-block">
          <SidebarSecondary path={location.pathname} data={about} title="About" />
        </div>

        <div className="blog-description w-100" >
          <BlogDescription match={match}/>
        </div>
      </div>
    </>
  );
}
