import React, { useState } from "react";
import "./image-view.scss";

const ImageView = ({ images, initialImg, onClose }) => {
  const removeEmptyImages = images.filter((img) => img !== "");
  let filterImages = [...new Set(removeEmptyImages)];

  let i = 0;
  const [imageDisplay, setImageDisplay] = useState(0);

  const changeImage = (change) => {
    if (change === "prev") {
      if (imageDisplay === 0) {
        setImageDisplay(filterImages.length - 1);
      } else {
        setImageDisplay(imageDisplay - 1);
      }
    }
    if (change === "next") {
      if (imageDisplay === filterImages.length - 1) {
        setImageDisplay(0);
      } else {
        setImageDisplay(imageDisplay + 1);
      }
    }
  };
  return (
    <div className="image-view">
      <div className="bg-dark" onClick={onClose} />
      <i className="fas fa-times cross" onClick={onClose}></i>
      {filterImages.length > 1 && (
        <i className="fas fa-chevron-left left-arrow" onClick={() => changeImage("prev")}></i>
      )}
      <img src={filterImages[imageDisplay]} alt="product view" />
      {filterImages.length > 1 && (
        <i className="fas fa-chevron-right right-arrow" onClick={() => changeImage("next")}></i>
      )}
    </div>
  );
};

export default ImageView;
