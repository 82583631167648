import React from "react";

const FormInput = ({
  type,
  title,
  placeholder,
  value,
  name,
  onChange,
  onBlur,
  required,
  error,
}) => {
  return (
    <div className="flex-column d-flex justify-content-center relative">
      <div className="flex">
        <p
          className="text-lg flex relative font-bold"
          style={{ textTransform: "uppercase" }}
        >
          {title}
          {required && (
            <p className="text-red-600 text-4xl absolute -right-5">*</p>
          )}
        </p>
      </div>
      <input
        className={`p-3 bg-gray-50 border-2 rounded-md ${error ? "border-red-500" : "border-gray-300"
          } focus:outline-none focus:ring-2 focus:ring-blue-500`}
        type={type}
        placeholder={placeholder}
        required={required}
        value={value}
        onChange={onChange}
        name={name}
        onBlur={onBlur}
      />
      {error && <p className="invalid-data mt-1 absolute top-20 text-red-500">{error}</p>}
    </div>
  );
};

export default FormInput;
