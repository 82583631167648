import React from "react";
import ListFreePrimary from "../../../ui/list/ListFreePrimary";

const Specs = ({ data }) => {
  return (
    <div className="container fade-in">
      <ListFreePrimary customClass="specs">
        {/* <div className="specs-container"> */}
        <h1 className="display-5 fs-m">Specifications</h1>

        <div dangerouslySetInnerHTML={{ __html: data.specs }} />
        {/* </div> */}
      </ListFreePrimary>
    </div>
  );
};

export default Specs;
