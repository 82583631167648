import $ from "jquery";

const sidebarAnimation = (path) => {
  $(function() {
    $(".sidebar-dropdown > a")
      .next(`#${path}`)
      .slideDown(200);
    $(`#${path}`)
      .parent(".sidebar-dropdown")
      .addClass("active");

    $(".sidebar-dropdown > a").on("click", function() {
      $(".sidebar-submenu").slideUp(200);
      if (
        $(this)
          .parent()
          .hasClass("active")
      ) {
        $(".sidebar-dropdown").removeClass("active");
        $(this)
          .parent()
          .removeClass("active");
      } else {
        $(".sidebar-dropdown").removeClass("active");

        $(this)
          .next(".sidebar-submenu")
          .slideDown(200);

        $(this)
          .parent()
          .addClass("active");
      }
    });
    // .one("click", function() {
    //   if ($(".sidebar-dropdown > a").has(`#${path}`)) {
    //     $(".sidebar-submenu").slideUp(200);
    //   }
    //   console.log(path);
    // });
  });
};

export const slideDown = (name) => {
  $(function() {
    $(".sidebar-dropdown > a")
      .next(`#${name}`)
      .slideDown(200);
    $(`#${name}`)
      .parent(".sidebar-dropdown")
      .addClass("active");
  });
};

export const slideUp = (name) => {
  $(function() {
    $(".sidebar-dropdown > a")
      .next(`#${name}`)
      .slideUp(200);
    $(`#${name}`)
      .parent(".sidebar-dropdown")
      .removeClass("active");
  });
};

export default sidebarAnimation;
