import React, { useState } from "react";
import "./input-password-primary.scss";

const InputPasswordPrimary = ({ id, placeholder, name, value, onChange, onBlur }) => {
  const [textVisible, setTextVisible] = useState(false);
  return (
    <div className="d-flex input-password-primary">
      <input
        type={textVisible ? "text" : "password"}
        className="form-control"
        id={id}
        placeholder={placeholder}
        name={name}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
      />
      <div className="eye-icon-container" onClick={() => setTextVisible(!textVisible)}>
        <i className={textVisible ? "fas fa-eye-slash" : "fas fa-eye"}></i>
      </div>
    </div>
  );
};

export default InputPasswordPrimary;
